import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import HiddenTag from '../../_components/HiddenTag';
import CancelledTag from '../../_components/CancelledTag';
import CompletedTag from '../../_components/CompletedTag';

function LoadingColumn() {
  return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">
        ...
      </div>
      <div className="text-sm text-gray-500">
        ...
      </div>
    </td>
  );
}

export default function Project(props) {
  const { interaction } = props;

  const connect = useConnect();

  const conversation = connect('conversation.byId', {
    id: interaction.data.conversationId
  });

  if (conversation.state === PayloadStates.FETCHING) {
    return (
      <LoadingColumn />
    );
  }

  const project = connect('project.byId', {
    id: conversation.data.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <LoadingColumn />
    );
  }

  const activity = connect('tag.byId', {
    id: project.data.activityId
  });

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="text-sm text-gray-900 line-clamp-1">
        {project.data.title || activity.data.name}
      </div>
      <div className="text-sm text-gray-500">
        {activity.data.name} <HiddenTag resource={project}/> <CancelledTag resource={project}/> <CompletedTag resource={project}/>
      </div>
    </td>
  );
}
