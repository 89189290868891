import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import getQueryUtils, { attributeKeys } from '../queryUtils';
import StringField from '../../../forms/_fields/StringField';
import useRouter from '../../../hooks/useRouter';

export default function Partner(props) {
  const { location, history } = useRouter();
  const { params: searchParams } = getQueryUtils(parse(location.search), attributeKeys);

  function onChangeSearch(name) {
    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, parse(location.search), {
        partner: name,
        page: 1
      }))
    });
  }

  return (
    <StringField
      label="Partner name"
      placeholder="Enter name..."
      value={searchParams.partner}
      onChange={onChangeSearch}
    />
  );
};
