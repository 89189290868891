import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import { ClockIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import MessageDeliveryStatus from '../../../components/MessageDeliveryStatus';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import Users from './Users';
import StringField from '../../../forms/_fields/StringField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      title={`Invite a new steward?`}
      subtitle={(
        <span>
          Enter the email address of the person you want to invite to be a steward.
        </span>
      )}
      showIcon={false}
      icon={ClockIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="mt-4 space-y-4">
          <FormField name="email" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <>
              <StringField
                label="Email"
                placeholder="Enter email..."
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
              </>
            )}
          </FormField>
        </div>
      )}
      footer={(
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <PrimaryButton onClick={onSubmit}>
            Next
          </PrimaryButton>
        </>
      )}
    />
  );
}
