import React  from 'react';
import TextColumn from '../TextColumn';

export default function Collaboration(props) {
  const { user } = props;

  const options = [];

  if (user.data.virtual) {
    options.push('Virtual');
  }

  if (user.data.inPerson) {
    options.push('In-person');
  }

  return (
    <TextColumn>
      {options.join(', ')}
    </TextColumn>
  );
}
