import React, { useState } from 'react';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import PageHeading from '../_components/PageHeading';
import Table from '../explore-partners/Table';
import SidePanel from '../explore-partners/SidePanel';
import ParentLayout from '../explore/Layout';
import useRouter from '../../hooks/useRouter';
import initialColumns from '../explore-partners/columns';
import Columns from '../explore-partners/Table/Columns';
import { useConnect } from '@lore/connect';
import useQuery from './useQuery';
import DownloadCsvDialog from '../../dialogs/csv/download';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();
  const connect = useConnect();

  const show = useDialog();

  function onClick(user) {
    show(
      <SidePanel
        user={user}
        primaryAction="complete_profile"
      />
    );
  }

  function onDownload(query) {
    show(
      <DownloadCsvDialog
        modelName="csvUser"
        query={query}
      />, { template: 'dialog' }
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns.map(function(column) {
    if (column.id === 'missing_fields') {
      column.selected = true;
    }

    return column;
  }));

  const selectedColumns = columns.filter(column => column.selected);

  const query = useQuery({
    location,
    PAGE_SIZE,
    connect
  });

  const csvQuery = useQuery({
    location,
    PAGE_SIZE,
    connect,
    returnRawQuery: true
  });

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Explore', 'Partners']}
          title="New Partners"
          description={(
            <span>
              These are partners who started a profile within the last week. CommunityShare recommends
              a quick call or email to thank them, answer any questions, and encourage them to find
              their first project or event. We also recommend you invite them to any projects or events
              that seem like a good fit. Partners are more likely to respond when they receive an
              invitation quickly after creating a profile.
            </span>
          )}
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        {/*<div className="px-2 py-4 sm:px-3 lg:px-4">*/}
        {/*  <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">*/}
        {/*    <Filters*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*    <SearchTags*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-end">
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            return query;
          }}
          csvQuery={function () {
            // const query = useQuery({
            //   location,
            //   PAGE_SIZE,
            //   connect,
            //   returnRawQuery: true
            // });

            onDownload(csvQuery);
          }}
          row={function (user) {
            return (
              <tr key={user.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(user)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      user={user}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
