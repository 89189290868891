import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import HiddenTag from '../../_components/HiddenTag';
import CancelledTag from '../../_components/CancelledTag';
import CompletedTag from '../../_components/CompletedTag';

export default function Summary(props) {
  const { project } = props;

  return (
    <Section
      title="Project Summary"
      subtitle={(
        <div className="mt-2 space-x-2">
          <HiddenTag resource={project}/>
          <CancelledTag resource={project}/>
          <CompletedTag resource={project}/>
        </div>
      )}
    >
      <TextField label="Guiding Question">
        {project.data.title || '-'}
      </TextField>
      <TextField label="Partner Role">
        {project.data.interactionDescription || '-'}
      </TextField>
      <TextField label="Goal">
        {project.data.desiredOutcome || '-'}
      </TextField>
      <TextField label="Demonstration of Learning">
        {project.data.demonstration || '-'}
      </TextField>
    </Section>
  );
}
