import React from 'react';
import Metrics from '../Metrics';
import ActivityByMonth from './ActivityByMonth';
import GrowthByMonth from './GrowthByMonth';
import OfferActivityYear from './OfferActivityYear';
import OfferActivityYearTreemap from './OfferActivityYear.treemap';
import OfferResponseYear from './OfferResponseYear';
import TotalYear from './TotalYear';
import OfferStatusYear from './OfferStatusYear';

export default function Offers(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Offers">
        <ActivityByMonth {...params} />
        {/*<GrowthByMonth {...params} />*/}
        {/*<OfferActivityYear {...params} />*/}
        <OfferActivityYearTreemap  {...params} />
        <OfferResponseYear {...params} />
        <OfferStatusYear {...params} />
      </Metrics>
      <Metrics>
        <TotalYear {...params} />
      </Metrics>
    </>
  );
}
