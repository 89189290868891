import React, { useState } from 'react';
import PageHeading from '../_components/PageHeading';
import Table from '../explore-partners/Table';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import ParentLayout from '../explore/Layout';
import useRouter from '../../hooks/useRouter';
import SearchTags from './SearchTags';
import Columns from '../explore-partners/Table/Columns';
import Filters from './Filters';
import SidePanel from './SidePanel';
import initialColumns from './columns';
import getQueryUtils, { attributeKeys } from './queryUtils';
import useAccount from '../../hooks/useAccount';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();

  const show = useDialog();
  const account = useAccount();

  function onClick(question) {
    show(
      <SidePanel
        question={question}
        primaryAction="respond_to_educator"
        secondaryAction={
          (
            question.data.projectId &&
            question.data.accountId === account.id
            // question.data.stage === 'Interested'
          ) ?
            'record_interaction' :
            ''
        }
      />
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Explore']}
          title="Questions"
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        <div className="px-2 py-4 sm:px-3 lg:px-4">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">
            <Filters />
            <SearchTags />
          </div>
        </div>
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-end">
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            const queryUtils = getQueryUtils(parse(location.search), attributeKeys);

            return getState('question.find', {
              where: {
                eager: {
                  $where: {
                    $and: [...queryUtils.where],
                    projectId: {
                      $not: null
                    }
                  }
                }
              },
              pagination: {
                page: parse(location.search).page || '1',
                pageSize: PAGE_SIZE,
                order: 'createdAt desc'
              }
            });
          }}
          row={function (question) {
            return (
              <tr key={question.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(question)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      question={question}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
