import React from 'react';
import Metrics from '../../dashboard-year/Metrics';
import SharedOffer from './SharedOffer';
import OfferPartners from './OfferPartners';
import Total from './Total';
import TotalComplete from './TotalComplete';
import QuestionPartners from './QuestionPartners';
import InvitedToProject from './InvitedToProject';
import InvitedToEvent from './InvitedToEvent';
import InvitedToProjectAndResponded from './InvitedToProjectAndResponded';
import InvitedToEventAndResponded from './InvitedToEventAndResponded';
import InitiatedQuestionPartners from './InitiatedQuestionPartners';
import CreatedInviteToProject from './CreatedInviteToProject';
import CreatedInviteToEvent from './CreatedInviteToEvent';
import EngagementByMonth from './EngagementByMonth';
import Contribution from './Contribution';

export default function Partners(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Partners">
        <Total {...params} />
        <TotalComplete {...params} />
        <SharedOffer {...params} />

        <InvitedToProject {...params} />
        <InvitedToEvent {...params} />
        <InvitedToProjectAndResponded {...params} />
        <InvitedToEventAndResponded {...params} />

        <CreatedInviteToProject {...params} />
        <CreatedInviteToEvent {...params} />

        <Contribution {...params} />
      </Metrics>
    </>
  );
}
