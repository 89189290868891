import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import { onChange, onChangeArray, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils, { attributeKeys } from '../queryUtils';
import PayloadStates from '../../../constants/PayloadStates';
import Filters from '../Filters';
import TagTypes from '../../../constants/TagTypes';
import TagSet from './_common/TagSet';

Filters.propTypes = {
  timestamp: PropTypes.string.isRequired
};

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const connect = useConnect();

  const { params: searchParams } = getQueryUtils(parse(location.search), attributeKeys, timestamp);

  return (
    <div className="space-y-3">
      <TagSet type={TagTypes.Activity} param="activities" timestamp={timestamp}/>
      <TagSet type={TagTypes.Age} param="ages" timestamp={timestamp}/>
      <TagSet type={TagTypes.Commitment} param="commitments" timestamp={timestamp}/>
      <TagSet type={TagTypes.Day} param="days" timestamp={timestamp} />
      <TagSet type={TagTypes.ExpertiseType} param="expertiseTypes" timestamp={timestamp} />
      <TagSet type={TagTypes.Group} param="groups" timestamp={timestamp} label="Youth" />
      <TagSet type={TagTypes.Guidance} param="guidances" timestamp={timestamp} label="Professional competency" />
      <TagSet type={TagTypes.InteractionType} param="interactionTypes" timestamp={timestamp} label="Interaction type" />
      <TagSet type={TagTypes.Participant} param="participants" timestamp={timestamp} />
      <TagSet type={TagTypes.Subject} param="subjects" timestamp={timestamp} />
      <TagSet type={TagTypes.Time} param="times" timestamp={timestamp} />
      <Tags
        type="Created"
        query={searchParams.createdDates}
        models={{
          state: PayloadStates.RESOLVED,
          data: [
            // ['lte_1_weeks', 'Within 1 week'],
            // ['lte_2_weeks', 'Within 2 weeks'],
            // ['lte_4_weeks', 'Within 1 month'],
            // ['lte_8_weeks', 'Within 2 months'],
            // ['lte_13_week', 'Within 3 months'],
            // ['lte_26_week', 'Within 6 months'],
            // ['lte_52_week', 'Within the year']
            [1, 'Within 1 week'],
            [2, 'Within 2 weeks'],
            [4, 'Within 1 month'],
            [8, 'Within 2 months'],
            [13, 'Within 3 months'],
            [26, 'Within 6 months'],
            [52, 'Within the year']
          ].map(function(value) {
            // const weeks = Number(value[0].split('_')[1]);

            return {
              // id: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
              id: value[0],
              data: {
                name: value[1]
              }
            }
          })
        }}
        onChange={onChangeArray('createdDates', { location, history })}
      />
      <Tags
        type="Published status"
        query={searchParams.published}
        models={mapArray([
          'Draft',
          'Published'
        ])}
        onChange={onChangeArray('published', { location, history })}
      />
      <Tags
        type="Stage"
        query={searchParams.stage}
        models={mapArray([
          'No invites',
          'Invited',
          'Interested',
          'Scheduling',
          'Attending'
        ])}
        onChange={onChangeArray('stage', { location, history })}
      />
      <Tag
        type="Title"
        query={searchParams.title}
        onChange={onChange('title', { location, history })}
      />
      <Tag
        type="Name"
        query={searchParams.name}
        onChange={onChange('name', { location, history })}
      />
      <Tag
        type="School"
        query={searchParams.school}
        onChange={onChange('school', { location, history })}
      />
      <Tags
        type="Assignee"
        query={searchParams.assignees}
        models={connect('user.find', {
          where: {
            eager: {
              $where: {
                isStaff: true
              }
            }
          },
          pagination: {
            pageSize: 100
          }
        })}
        onChange={onChangeArray('assignees', { location, history })}
        label={function(model) {
          if (model.data.firstName || model.data.lastName) {
            return `${model.data.firstName} ${model.data.lastName}`;
          }

          return model.data.email;
        }}
      />
      <Tag
        type="Search"
        query={searchParams.search}
        onChange={onChange('search', { location, history })}
      />
      <Tags
        type="Completion status"
        query={searchParams.status}
        models={mapArray([
          'Active',
          'Completed',
          'Cancelled'
        ])}
        onChange={onChangeArray('status', { location, history })}
      />
    </div>
  );
};
