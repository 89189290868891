import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import Summary from './Summary';
import Educator from './Educator';
import Audience from './Audience';
import Partner from './Partner';
import Resources from './Resources';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import Invitations from './Invitations';
import Bookmark from '../../_components/Bookmark';
import Evaluations from './Evaluations';

export default function SidePanel(props) {
  const { event } = props;

  const [tabs, setTabs] = useState([
    { id: 'details', name: 'Details', selected: true },
    { id: 'invitations', name: 'Invitations', selected: false },
    { id: 'evaluations', name: 'Evaluations', selected: false },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  return (
    <Panel title="Event" onClose={props.onClose}>
      <div>
        <Header
          bookmark={(
            <Bookmark
              type="event"
              resource={event}
            />
          )}
          src={activity.data.imageUrl}
          title={event.data.title || activity.data.name}
          subtitle={activity.data.name}
        >
          <Actions
            event={event}
            onClose={props.onClose}
          />
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'details' && (
          <div className="divide-y divide-gray-200">
            <Summary event={event} />
            <Educator event={event} />
            <Partner event={event} />
            <Audience event={event} />
            <Resources event={event} />
          </div>
        )}
        {selectedTab.id === 'invitations' && (
          <div className="mb-6x">
            <Invitations event={event} />
          </div>
        )}
        {selectedTab.id === 'evaluations' && (
          <div className="mb-6x">
            <Evaluations event={event} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <Notes event={event} />
          </div>
        )}
      </div>
    </Panel>
  )
}
