import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import moment from 'moment-timezone';
import { useUser } from '@lore/auth';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';
import HiddenTag from '../../_components/HiddenTag';
import CancelledTag from '../../_components/CancelledTag';
import CompletedTag from '../../_components/CompletedTag';

export default function Summary(props) {
  const { event } = props;

  const currentUser = useUser();

  const eventInteractionTypes = useEventTags(event, TagTypes.InteractionType);

  return (
    <Section
      title="Event Summary"
      subtitle={(
        <div className="mt-2 space-x-2">
          <HiddenTag resource={event}/>
          <CancelledTag resource={event}/>
          <CompletedTag resource={event}/>
        </div>
      )}
    >
      <TextField label="Event Title">
        {event.data.title || '-'}
      </TextField>
      <TextField label="Event Summary">
        {event.data.interactionDescription || '-'}
      </TextField>
      <TextField label="Date">
        {event.data.date && moment(event.data.date).format('MMM D, YYYY')}
      </TextField>
      <TextField label="Time (Start - End)">
        {moment(event.data.startTime).tz(currentUser.data.timezone).format('h:mm a')} - {moment(event.data.endTime).tz(currentUser.data.timezone).format('h:mm a')}
      </TextField>
      <TextField label="Location Type">
        {eventInteractionTypes.data.map(function(eventInteractionType) {
          return (
            <div key={eventInteractionType.id || eventInteractionType.cid}>
              {eventInteractionType._tag.data.name}
            </div>
          );
        })}
        {eventInteractionTypes.data.length === 0 && '-'}
      </TextField>
    </Section>
  );
}
