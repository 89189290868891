import React from 'react';
import { useConnect } from '@lore/connect';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function EducatorPhone(props) {
  const { claim } = props;

  const user = useConnect('user.byId', {
    id: claim.data.userId
  });

  return (
    <TextColumn>
      {user.data.phoneNumber}
    </TextColumn>
  );
}
