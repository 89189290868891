import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { PayloadStates } from '@lore/utils';
import { onChange, onChangeArray } from '../../../utils/searchTags';
import Tags from '../../../components/Tags';
import useRouter from '../../../hooks/useRouter';
import getQueryUtils, { attributeKeys } from '../queryUtils';

export default function Joined(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();
  const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);
  const searchParams = queryUtils.params;

  return (
    <Tags
      type="Joined"
      query={searchParams.createdDates}
      models={{
        state: PayloadStates.RESOLVED,
        data: [
          // ['lte_1_weeks', 'Within 1 week'],
          // ['lte_2_weeks', 'Within 2 weeks'],
          // ['lte_4_weeks', 'Within 1 month'],
          // ['lte_8_weeks', 'Within 2 months'],
          // ['lte_13_week', 'Within 3 months'],
          // ['lte_26_week', 'Within 6 months'],
          // ['lte_52_week', 'Within the year']
          [1, 'Within 1 week'],
          [2, 'Within 2 weeks'],
          [4, 'Within 1 month'],
          [8, 'Within 2 months'],
          [13, 'Within 3 months'],
          [26, 'Within 6 months'],
          [52, 'Within the year']
        ].map(function(value) {
          // const weeks = Number(value[0].split('_')[1]);

          return {
            // id: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
            id: value[0],
            data: {
              name: value[1]
            }
          }
        })
      }}
      onChange={onChangeArray('createdDates', { location, history })}
    />
  );
};
