import React from 'react';
import moment from 'moment-timezone';
import FeedItem from './FeedItem';
import { useConnect } from '@lore/connect';

export default function Event(props) {
  const { event } = props;

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  return (
    <FeedItem timestamp={moment(event.data.createdAt).fromNow()} last={props.last}>
      <span className="font-medium text-gray-900">
        {creator.data.firstName} {creator.data.lastName}
      </span> created an event titled <span className="font-medium text-gray-900">
        {event.data.title || '[missing title]'}
      </span>
    </FeedItem>
  );
}
