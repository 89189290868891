import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import UserIsAuthenticated from '../decorators/UserIsAuthenticated';
import Master from '../pages/Master';
import { Helmet } from 'react-helmet';

export default ({ component: Component, render, ...rest }) => {
  return (
    <Route {...rest} render={(props) => {
      return (
        <UserIsAuthenticated>
          <Helmet>
            <title>Admin: CommunityShare</title>
          </Helmet>
          <Master {...props}>
            {Component ? (
              <Component {...props} />
            ) : render ? render(props) : null}
          </Master>
        </UserIsAuthenticated>
      );
    }} />
  );
};
