import React from 'react';
import Metrics from '../../dashboard-year/Metrics';
import Total from './Total';

export default function Organizations(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Organizations associated/affiliated with Partners">
        <Total {...params} />
      </Metrics>
    </>
  );
}
