import React  from 'react';
import moment from 'moment-timezone';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';
import { useUser } from '@lore/auth';

export default function Date(props) {
  const { recommendation } = props;

  const currentUser = useUser();

  const startDate = moment(recommendation.data.createdAt).format('MMM Do, YYYY');
  const startTime = moment(recommendation.data.createdAt).tz(currentUser.data.timezone).format('h:mm A');

  return (
    <SupportingTextColumn
      title={startDate}
      subtitle={startTime}
    />
  );
}
