import React from 'react';
import { useDialog } from '@lore/dialogs';
import Actions from '../../_components/actions/Actions';
import PrimaryAction from '../../_components/actions/PrimaryAction';
import SecondaryAction from '../../_components/actions/SecondaryAction';
import ExtraActions from '../../_components/actions/ExtraActions';
import PlaceholderDialog from '../../../dialogs/Placeholder';
import UpdateInteractionDialog from '../../../dialogs/interaction/update';
import ConfirmInteractionDialog from '../../../dialogs/interaction/confirm';
import useAccount from '../../../hooks/useAccount';

export default function _Actions(props) {
  const {
    interaction,
    primaryAction = '',
    secondaryAction = '',
    extraActions = [],
    onClose = () => {}
  } = props;

  const show = useDialog();
  const account = useAccount();

  if (interaction.data.accountId !== account.id) {
    return (
      <Actions>
        <div className="bg-accent w-full py-3 px-4 rounded-md font-medium text-white">
          Interaction is outside your stewardship. Actions are disabled.
        </div>
      </Actions>
    );
  }

  function showPlaceholder(text) {
    show(
      <PlaceholderDialog>
        {text}
      </PlaceholderDialog>, { template: 'dialog' }
    );
  }

  const actionMap = {
    'edit_interaction': {
      label: 'Edit date and time',
      callback: function() {
        show(
          <UpdateInteractionDialog
            interaction={interaction}
          />, { template: 'modal' }
        );
      }
    },
    'confirm_interaction': {
      label: 'Change to attending',
      callback: function() {
        show(
          <ConfirmInteractionDialog
            interaction={interaction}
          />, { template: 'modal' }
        );
      }
    }
  }

  const actions = {
    primary: actionMap[primaryAction],
    secondary: actionMap[secondaryAction],
    extra: extraActions.map(function(extraAction) {
      return actionMap[extraAction];
    })
  }

  return (
    <Actions>
      {actions.primary && (
        <PrimaryAction onClick={actions.primary.callback}>
          {actions.primary.label}
        </PrimaryAction>
      )}
      {actions.secondary && (
        <SecondaryAction onClick={actions.secondary.callback}>
          {actions.secondary.label}
        </SecondaryAction>
      )}
      {actions.extra.length > 0 && (
        <ExtraActions
          actions={actions.extra.map(function(action) {
            return {
              name: action.label,
              onClick: action.callback
            }
          })}
        />
      )}
    </Actions>
  );
}
