import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Students(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Students"
      // subtitle="Additional information provided by educator."
    >
      <TextField label="How many students were impacted by this activity?">
        {evaluation.data.students || '-'}
      </TextField>
    </Section>
  );
}
