import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import _ from 'lodash';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import People from './People';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import { PayloadStates } from '@lore/utils';

export default function SidePanel(props) {
  const {
    recommendation,
    primaryAction = '',
    secondaryAction = '',
    extraActions = []
  } = props;

  const connect = useConnect();

  const [tabs, setTabs] = useState([
    { id: 'details', name: 'Details', selected: true }
    // { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  const project = connect('project.byId', {
    id: recommendation.data.projectId
  });

  const partner = connect('user.byId', {
    id: recommendation.data.partnerId
  });

  const creator = connect('user.byId', {
    id: recommendation.data.creatorId
  });

  if (
    project.state === PayloadStates.FETCHING ||
    partner.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING
  ) {
    return (
      <Panel title="Recommendation: Offer" onClose={props.onClose}>
        Loading...
      </Panel>
    )
  }

  const activity = connect('tag.byId', {
    id: project.data.activityId
  });

  return (
    <Panel title="Recommendation: Project" onClose={props.onClose}>
      <div>
        <Header
          src={activity.data.imageUrl}
          title={project.data.title || activity.data.name}
          subtitle={activity.data.name}
        >
          <Actions
            recommendation={recommendation}
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
            extraActions={extraActions}
          />
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'details' && (
          <div className="divide-y divide-gray-200">
            <People recommendation={recommendation} />
          </div>
        )}
        {/*{selectedTab.id === 'notes' && (*/}
        {/*  <div className="mb-6x">*/}
        {/*    <Notes recommendation={recommendation} />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </Panel>
  )
}
