import React from 'react';
import Id from '../_components/table/columns/user/Id';
import Name from '../_components/table/columns/user/Name';
import Organization from '../_components/table/columns/user/Organization';
import Joined from '../_components/table/columns/user/Joined';
import About from '../_components/table/columns/user/About';
import Motivation from '../_components/table/columns/user/Motivation';
import Gender from '../_components/table/columns/user/Gender';
import Ethnicity from '../_components/table/columns/user/Ethnicity';
import Website from '../_components/table/columns/user/Website';
import Twitter from '../_components/table/columns/user/Twitter';
import LinkedIn from '../_components/table/columns/user/LinkedIn';
import Subjects from '../_components/table/columns/user/Subjects';
import Practices from '../_components/table/columns/user/Practices';
import Grades from '../_components/table/columns/user/Grades';
import MissingFields from '../_components/table/columns/user/MissingFields';
import Interactions from '../_components/table/columns/user/Interactions';
import Phone from '../_components/table/columns/user/Phone';
import ZipCode from '../_components/table/columns/user/ZipCode';
import Distance from '../_components/table/columns/user/Distance';
import Collaboration from '../_components/table/columns/user/Collaboration';
import LastActive from '../_components/table/columns/user/LastActive';

export default [
  { id: 'id',             name: 'ID',                    row: Id,            selected: false },
  { id: 'name',           name: 'Name',                  row: Name,          selected: true },
  { id: 'organization',   name: 'Organization',          row: Organization,  selected: true },
  { id: 'collaboration',  name: 'Collaboration options', row: Collaboration, selected: false },
  { id: 'date_joined',    name: 'Date joined',           row: Joined,        selected: true },
  { id: 'phone',          name: 'Phone',                 row: Phone,         selected: false },
  { id: 'about',          name: 'About',                 row: About,         selected: false },
  { id: 'motivation',     name: 'Motivation',            row: Motivation,    selected: false },
  { id: 'subjects',       name: 'Subjects',              row: Subjects,      selected: false },
  { id: 'practices',      name: 'Practices',             row: Practices,     selected: false },
  { id: 'grades',         name: 'Grades',                row: Grades,        selected: false },
  { id: 'gender',         name: 'Gender',                row: Gender,        selected: false },
  { id: 'ethnicity',      name: 'Ethnicity',             row: Ethnicity,     selected: false },
  { id: 'website',        name: 'Website',               row: Website,       selected: false },
  { id: 'twitter',        name: 'Twitter',               row: Twitter,       selected: false },
  { id: 'linkedin',       name: 'LinkedIn',              row: LinkedIn,      selected: false },
  { id: 'missing_fields', name: 'Missing fields',        row: MissingFields, selected: false },
  // { id: 'interactions',   name: 'Interactions',          row: Interactions,  selected: false },
  { id: 'zip_code',       name: 'ZIP code',              row: ZipCode,       selected: false },
  { id: 'distance',       name: 'Distance',              row: Distance,      selected: false },
  { id: 'last_active',    name: 'Last Active',           row: LastActive,    selected: false }
];
