import React, { useState } from 'react';
import _ from 'lodash';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import Chart from '../Chart';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import REQUIRED_FIELDS from '../../explore-offers/REQUIRED_FIELDS';

export default function OfferActivityYear(props) {
  const { account, schoolStart, schoolEnd, subtitle } = props;

  const connect = useConnect();

  const activities = useTags(TagTypes.Activity);

  if (activities.state === PayloadStates.FETCHING) {
    return (
      <Chart
        // name="Offers by Activity"
      >
        <Loader />
      </Chart>
    );
  }

  const requests = _.sortBy(activities.data, at => at.data.name).map(function(activity) {
    return {
      label: activity.data.name,
      offers: connect('offer.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              'activity.id': activity.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              accountId: account.id
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.offers.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <Chart
        // name="Offers by Activity"
      >
        <Loader />
      </Chart>
    );
  }

  const series = requests.map(r => r.offers.meta.totalCount);
  const labels = requests.map(r => r.label);

  const data = {
    series: series,
    options: {
      title: {
        text: 'Offers by Activity',
        align: 'center'
      },
      subtitle: {
        text: subtitle || 'For school year',
        align: 'center',
        offsetY: 20
      },
      labels: labels,
      chart: {
        type: 'donut',
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        offsetY: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true
              }
            }
          }
        }
      }
      // responsive: [{
      //   breakpoint: 480,
      //   options: {
      //     chart: {
      //       width: 200
      //     },
      //     legend: {
      //       position: 'bottom'
      //     }
      //   }
      // }]
    }
  };

  return (
    <Chart
      // name="Offers by Activity"
      className="col-span-1 pb-2"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="donut"
        height={350}
      />
    </Chart>
  );
}
