import React from 'react';
import { OfficeBuildingIcon, UsersIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';

export default function TotalYear(props) {
  const { account, schoolStart, schoolEnd } = props;

  const totalUsers = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          isEducator: false
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const newUsers = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          isEducator: false,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const totalCount = totalUsers.meta ? totalUsers.meta.totalCount : '...';
  const newCount = newUsers.meta ? newUsers.meta.totalCount : '...';

  return (
    <Metric
      name="Total (+New)"
      stat={`${totalCount} (+${newCount})`}
      icon={UsersIcon}
    />
  );
}
