import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import Organizations from '../../_components/table/columns/user/Organization';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';

export default function Organization(props) {
  const { event } = props;

  const educator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  if (educator.state === PayloadStates.FETCHING) {
    return (
      <SupportingTextColumn loading={true} />
    );
  }

  return (
    <Organizations user={educator} />
  );
}
