import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { getYear } from '../dashboard/navigationLinks';
import Redirect from '../../components/Redirect';
import PageHeading from '../_components/PageHeading';
import ParentLayout from '../dashboard/Layout';
import useAccount from '../../hooks/useAccount';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const year = getYear(moment());
  const account = useAccount();

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Dashboard']}
          // title="School Year"
        >
        </PageHeading>
        <Redirect to={`/c/${account.data.subdomain}/dashboard/year/${year}`} />
      </main>
    </ParentLayout>
  );
}
