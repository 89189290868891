import React from 'react';
import Id from './Columns/Id';
import Educator from './Columns/Educator';
import EducatorPhone from './Columns/EducatorPhone';
import Event from './Columns/Event';
import Date from './Columns/Date';
import Status from './Columns/Status';
import Served from './Columns/Served';
import Statements from './Columns/Statements';
import Recommend from './Columns/Recommend';
import Support from './Columns/Support';

export default [
  { id: 'id',                  name: 'ID',                   row: Id,                 selected: false },
  { id: 'user',                name: 'Educator',             row: Educator,           selected: true },
  { id: 'educator_phone',      name: 'Educator phone',       row: EducatorPhone,      selected: false },
  { id: 'event',               name: 'Event',                row: Event,              selected: true },
  { id: 'date',                name: 'Date',                 row: Date,               selected: true },
  { id: 'status',              name: 'Status',               row: Status,             selected: true },
  // { id: 'training',            name: 'Training',             row: Training,           selected: false },
  { id: 'served',              name: 'Students served',        row: Served,             selected: true },
  { id: 'statements_educator', name: 'Statements',           row: Statements,         selected: false },
  // { id: 'experience',          name: 'Experience',           row: Experience,         selected: false },
  { id: 'recommend',           name: 'Recommend',            row: Recommend,          selected: true },
  // { id: 'support',             name: 'Support',              row: Support,            selected: false },
];
