import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import Section from '../../_components/sidePanel/Section';
import TextField from '../../_components/sidePanel/TextField';
import { PayloadStates } from '@lore/utils';
import Loader from '../../_components/Loader';
import PrimaryAction from '../../_components/actions/PrimaryAction';
import CreateInteractionDialog from '../../../dialogs/interaction/create';
import { useDialog } from '@lore/dialogs';
import InteractionSidePanel from '../../explore-interactions/SidePanel';
import useAccount from '../../../hooks/useAccount';

export default function Conversation(props) {
  const { conversation } = props;

  const connect = useConnect();
  const show = useDialog();
  const account = useAccount();

  const [timestamp] = useState(moment().toISOString());

  const interactions = connect('interaction.find', {
    where: {
      eager: {
        $where: {
          conversationId: conversation.id
        }
      }
    },
    include: function(model) {
      return (
        model.id &&
        model.data.conversationId === conversation.id
      );
    }
  });

  function onCreateInteraction() {
    show(
      <CreateInteractionDialog
        conversation={conversation}
      />, { template: 'modal' }
    );
  }

  function onClickInteraction() {
    show(
      <InteractionSidePanel
        interaction={interactions.data[0]}
      />
    );
  }

  if (interactions.state === PayloadStates.FETCHING) {
    return (
      <Section
        title="Interaction"
        subtitle="View or record an interaction related to this conversation."
      >
        <Loader />
      </Section>
    );
  }

  if (conversation.data.accountId !== account.id) {
    return (
      <Section
        title="Interaction"
        subtitle="View the scheduled interaction related to this conversation."
      >
        <div className="bg-accent w-full py-3 px-4 rounded-md font-medium text-white">
          <div className="mb-4x">
            Associated project is outside your stewardship. Actions are disabled.
          </div>
        </div>
      </Section>
    );
  }

  if (!interactions.data[0]) {
    return (
      <Section
        title="Interaction"
        subtitle="View the scheduled interaction related to this conversation."
      >
        <PrimaryAction onClick={onCreateInteraction}>
          Record an interaction
        </PrimaryAction>
      </Section>
    );
  }

  return (
    <Section
      title="Interaction"
      subtitle="View or record an interaction related to this conversation."
    >
      <TextField label="">
        <span onClick={onClickInteraction} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          View recorded interaction
        </span>
      </TextField>
    </Section>
  );
}
