import React from 'react';
import { useConnect } from '@lore/connect';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Organizations(props) {
  const { offer } = props;

  const connect = useConnect();

  const roles = connect('role.find', {
    where: {
      eager: {
        $where: {
          userId: offer.data.creatorId
        }
      }
    }
  });

  if (roles.data.length === 0) {
    return null;
  }

  return (
    <Section
      title="Organizations"
      subtitle="School affiliations."
    >
      {roles.data.map(function(role) {
        const organization = connect('organization.byId', {
          id: role.data.organizationId
        });

        return (
          <TextField
            invert={true}
            key={role.id}
            label={organization.data.name || '...'}
          >
            {role.data.title}
          </TextField>
        )
      })}
    </Section>
  )
}
