import React from 'react';
import { parse, stringify } from 'query-string';
import { MISSING_PARTNER_FIELDS } from '../../utils/user/MISSING_FIELDS';
import useStewardshipFilter from '../explore-partners/useStewardshipFilter';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect, returnRawQuery = false } = props;

  const stewardshipFilter = useStewardshipFilter();

  const query = {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfPartnerProjectEvaluationsCompleted',
            relation: 'partnerProjectEvaluations',
            $where: {
              completed: true
            }
          },
          {
            type: 'count',
            alias: 'numberOfPartnerEventEvaluationsCompleted',
            relation: 'partnerEventEvaluations',
            $where: {
              completed: true
            }
          }
        ],
        $where: {
          isEducator: false,
          $and: {
            $or: [
              {
                numberOfPartnerProjectEvaluationsCompleted: {
                  $gte: 3
                }
              },
              {
                $and: {
                  numberOfPartnerProjectEvaluationsCompleted: {
                    $gte: 2
                  },
                  numberOfPartnerEventEvaluationsCompleted: {
                    $gte: 1
                  }
                }
              },
              {
                $and: {
                  numberOfPartnerProjectEvaluationsCompleted: {
                    $gte: 1
                  },
                  numberOfPartnerEventEvaluationsCompleted: {
                    $gte: 2
                  }
                }
              },
              {
                numberOfPartnerEventEvaluationsCompleted: {
                  $gte: 3
                }
              }
            ]
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  };

  if (returnRawQuery) {
    return query;
  }

  return connect('user.find', query);
}
