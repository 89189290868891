import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import InviteProjectDialog from '../../../../dialogs/user/invite.project';

export default function InviteProject(props) {
  const { user } = props;

  const show = useDialog();

  function onClick() {
    show(
      <InviteProjectDialog
        user={user}
      />, { template: 'dialog' }
    );
  }

  return (
    <PrimaryAction onClick={onClick}>
      Invite to Project
    </PrimaryAction>
  );
}
