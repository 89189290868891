import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import useStewardshipFilter from '../useStewardshipFilter';
import useAccount from '../../../hooks/useAccount';

AssigneeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function AssigneeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();
  const account = useAccount();
  const stewardshipFilter = useStewardshipFilter();

  const members = connect('member.find', {
    where: {
      eager: {
        $where: {
          isStaff: true,
          accountId: account.id,
          isHidden: false
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  const usersData = _.uniqBy(members.data, function(member) {
    return member.data.userId;
  }).map(function(member) {
    return connect('user.byId', {
      id: member.data.userId
    });
  });

  const models = {
    state: members.state,
    data: _.sortBy(usersData, function(user) {
      return user.data.firstName;
    })
  }

  const relatedModels = function(user) {
    return connect('event.find', {
      where: {
        eager: {
          $where: {
            assigneeId: user.id,
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    });
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Assignee"
      // dropdownLabel1="It should be focused on serving learners in"
      // dropdownLabel2="of the following groups:"
      queryUtils={getQueryUtils}
      filterName="assignee"
      queryParamName="assignees"
      tags={models}
      related={relatedModels}
      display={function(model) {
        if (model.data.firstName || model.data.lastName) {
          return `${model.data.firstName} ${model.data.lastName}`;
        }

        return model.data.email;
      }}
    />
  );
};
