import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';
import { PayloadStates } from '@lore/utils';
import HiddenTag from '../../_components/HiddenTag';
import CancelledTag from '../../_components/CancelledTag';
import CompletedTag from '../../_components/CompletedTag';

export default function Summary(props) {
  const { offer } = props;

  const offerSubjects = useOfferTags(offer, TagTypes.Subject);
  const offerGuidances = useOfferTags(offer, TagTypes.Guidance);

  return (
    <Section
      title="Offer Summary"
      subtitle={(
        <div className="mt-2 space-x-2">
          <HiddenTag resource={offer}/>
          <CancelledTag resource={offer}/>
          <CompletedTag resource={offer}/>
        </div>
      )}
    >
      <TextField label="Title">
        {offer.data.title || '-'}
      </TextField>
      <TextField label="Description">
        {offer.data.description || '-'}
      </TextField>
      <TextField label="Objectives">
        {offer.data.objective || '-'}
      </TextField>
      <TextField label="Subject Areas">
        {offerSubjects.data.map(function(offerSubject) {
          return (
            <div key={offerSubject.id || offerSubject.cid}>
              {offerSubject._tag.data.name}
            </div>
          );
        })}
        {offerSubjects.data.length === 0 && '-'}
      </TextField>
      <TextField label="Competencies Covered">
        {offerGuidances.data.map(function(offerGuidance) {
          return (
            <div key={offerGuidance.id || offerGuidance.cid}>
              {offerGuidance._tag.data.name}
            </div>
          );
        })}
        {offerGuidances.data.length === 0 && '-'}
      </TextField>
    </Section>
  );
}
