import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';
import useRouter from '../../../hooks/useRouter';

export default function Response(props) {
  const { location, history } = useRouter();

  const connect = useConnect();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      'Pending',
      'Connect',
      'Decline'
    ].map(function(value, index) {
      return {
        id: index + 1,
        data: {
          name: value
        }
      }
    })
  };

  const users = function(model) {
    return connect('question.find', {
      where: {
        eager: {
          $where: {
            response: model.data.name,
            projectId: {
              $not: null
            }
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Response"
      // dropdownLabel="The stage will be:"
      // dropdownLabel1="It should match"
      // dropdownLabel2="of the following activities:"
      queryUtils={getQueryUtils}
      filterName="response"
      queryParamName="responses"
      tags={models}
      related={users}
    />
  );
};
