import React  from 'react';
import User from './User';
import { useConnect } from '@lore/connect';
import PayloadStates from '../../../constants/PayloadStates';

export default function Educator(props) {
  const { question } = props;

  const connect = useConnect();

  const project = connect('project.byId', {
    id: question.data.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <User user={{
        state: PayloadStates.FETCHING
      }} />
    );
  }

  const user = connect('user.byId', {
    id: project.data.creatorId
  });

  return (
    <User user={user} />
  );
}
