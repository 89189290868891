import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import { PayloadStates } from '@lore/utils';
import EducatorSidePanel from '../../explore-educators/SidePanel';
import PartnerSidePanel from '../../explore-partners/SidePanel';
import OfferSidePanel from '../../explore-offers/SidePanel';
import moment from 'moment-timezone';
import marked from 'marked';

function Loader() {
  return (
    <Section>
      <TextField label="Educator">
        ...
      </TextField>
      <TextField label="Offer">
        ...
      </TextField>
      <TextField label="Recommended By">
        ...
      </TextField>
      <TextField label="Date">
        ...
      </TextField>
      <TextField label="Message">
        ...
      </TextField>
    </Section>
  );
}

export default function People(props) {
  const { recommendation } = props;

  const connect = useConnect();
  const show = useDialog();

  const offer = connect('offer.byId', {
    id: recommendation.data.offerId
  });

  const educator = connect('user.byId', {
    id: recommendation.data.educatorId
  });

  const creator = connect('user.byId', {
    id: recommendation.data.creatorId
  });

  if (
    offer.state === PayloadStates.FETCHING ||
    educator.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const activity = connect('tag.byId', {
    id: offer.data.activityId
  });

  function onClickEducator() {
    show(
      <EducatorSidePanel user={educator} />
    );
  }

  function onClickCreator() {
    if (creator.data.isEducator) {
      show(
        <EducatorSidePanel user={creator} />
      );
    } else {
      show(
        <PartnerSidePanel user={creator} />
      );
    }
  }

  function onClickOffer() {
    show(
      <OfferSidePanel offer={offer} />
    );
  }

  return (
    <Section
      // title="Educator information"
      // subtitle="Name and contact info."
    >
      <TextField label="Educator">
        <span onClick={onClickEducator} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {educator.data.firstName} {educator.data.lastName}
        </span>
      </TextField>
      <TextField label="Offer">
        <span onClick={onClickOffer} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {offer.data.title || activity.data.name}
        </span>
      </TextField>
      <TextField label="Recommended By">
        <span onClick={onClickCreator} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {creator.data.firstName} {creator.data.lastName}
        </span>
      </TextField>
      <TextField label="Date">
        {moment(recommendation.data.dateCreated).format('MMM Do, YYYY')}
      </TextField>
      <TextField label="Message">
        <p
          className="space-y-2"
          dangerouslySetInnerHTML={{__html: marked(recommendation.data.message || '')}}
        />
      </TextField>
    </Section>
  );
}
