import React  from 'react';
import TextColumn from '../../_components/table/columns/TextColumn';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import moment from 'moment-timezone';
import useMissingFields from '../useMissingFields';
import { PayloadStates } from '@lore/utils';

export default function Status(props) {
  const { project } = props;

  const { missingFields } = useMissingFields(project);

  if (missingFields.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  const timestamp = moment().toISOString();

  const inFuture = (
    project.data.specificDate === 'Yes' &&
    project.data.date &&
    moment().diff(project.data.date) < 0
  ) || (
    project.data.specificDate === 'No' &&
    project.data.endDate &&
    moment().diff(project.data.endDate) < 0
  );

  const isValid = missingFields.data.length === 0;

  if (project.data.cancelled) {
    return (
      <TextColumn>
        Cancelled
      </TextColumn>
    );
  }

  if (project.data.completed) {
    return (
      <TextColumn>
        Completed
      </TextColumn>
    );
  }

  if (!isValid) {
    return (
      <TextColumn>
        Incomplete
      </TextColumn>
    );
  }

  if (inFuture) {
    return (
      <TextColumn>
        Active
      </TextColumn>
    );
  }

  if (!inFuture) {
    return (
      <TextColumn>
        Expired
      </TextColumn>
    );
  }

  return (
    <TextColumn>

    </TextColumn>
  );
}
