import React from 'react';

export default function Actions(props) {
  const { children } = props;

  return (
    <div className="mt-5 grid grid-cols-2 gap-4">
      {children}
    </div>
  );

  return (
    <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
      {children}
    </div>
  );
}
