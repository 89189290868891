import React from 'react';
import PropTypes from 'prop-types';
import Checkmark from '../../components/_icons/Checkmark';

CheckboxField.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.node,
  description: PropTypes.node,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

CheckboxField.defaultProps = {
  checked: false,
  disabled: false
};

export default function CheckboxField(props) {
  const {
    checked,
    label,
    description,
    error,
    disabled,
    // onChange
    className
  } = props;

  // position: relative;
  // display: block;
  // min-height: 1.5rem;
  // padding-left: 1.5rem;

  function onChange() {
    props.onChange({
      target: {
        checked: !checked
      }
    });
  }

  return (
    <div className={className || ''}>
      <div className="relative flex items-start">
        <div className="flex items-center h-5 cursor-pointer">
          <input
            type="checkbox"
            className="focus:ring-orange-500 h-4 w-4 text-accent border-accent rounded cursor-pointer"
            checked={checked}
            onChange={props.onChange}
          />
        </div>
        <div className="ml-3 text-sm cursor-pointer" onClick={onChange}>
          <label className="block font-medium text-gray-700 cursor-pointer">
            {label}
          </label>
          {description && (
            <p className="text-gray-500">
              {description}
            </p>
          )}
        </div>
      </div>
      {error ? (
        <div className="invalid-feedback">
          {error}
        </div>
      ) : null}
    </div>
  );
}
