import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import useStewardshipFilter from '../explore-projects/useStewardshipFilter';
import REQUIRED_FIELDS from '../explore-projects/REQUIRED_FIELDS';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  return connect('project.find', {
    where: {
      eager: {
        $where: {
          $and: [
            ...REQUIRED_FIELDS.$and,
            {
              $or: stewardshipFilter
            }
          ],
          $or: [
            {
              specificDate: 'Yes',
              date: {
                $lte: moment(timestamp).toISOString(),
                // $gte: moment(timestamp).subtract(4, 'weeks').toISOString()
              }
            },
            {
              specificDate: 'No',
              endDate: {
                $lte: moment(timestamp).toISOString(),
                // $gte: moment(timestamp).subtract(4, 'weeks').toISOString()
              }
            }
          ],
          cancelled: false,
          completed: false
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    },
    exclude: function(model) {
      return model.state === PayloadStates.DELETED;
    }
  });
}
