import React from 'react';
import { useConnect } from '@lore/connect';
import useRouter from '../../hooks/useRouter';

// Partners
import starredPartners from '../explore-partners-starred/useQuery';
import incompletePartners from '../explore-partners-incomplete/useQuery';
import questionsPartners from '../explore-partners-questions/useQuery';
import invitationsPartners from '../explore-partners-invitations/useQuery';
import unresponsivePartners from '../explore-partners-unresponsive/useQuery';
import evaluationsPartners from '../explore-partner-evaluations-pending/useQuery';
import newPartners from '../explore-partners-new/useQuery';
import noInteractionsPartners from '../explore-partners-no-interactions/useQuery';
import multipleEvaluationsPartners from '../explore-partners-multiple-evaluations/useQuery';
import multipleHoursPartners from '../explore-partners-multiple-hours/useQuery';
import multipleStudentsPartners from '../explore-partners-multiple-students/useQuery';
import useAccount from '../../hooks/useAccount';

export default function getNavigationLinks() {
  const { location } = useRouter();
  const connect = useConnect();
  const account = useAccount();

  function getCount(query) {
    const result = query({ location, connect });
    return result.meta ? result.meta.totalCount : '...';
  }

  const root = `/c/${account.data.subdomain}`;

  return [
    { name: 'Starred', href: `${root}/explore/partners/starred`, count: getCount(starredPartners) },
    { name: 'New', href: `${root}/explore/partners/new`, count: getCount(newPartners), highlight: true },
    { name: 'Incomplete Profile', href: `${root}/explore/partners/incomplete`, count: getCount(incompletePartners) },
    { name: 'No Interactions', href: `${root}/explore/partners/no-interactions`, count: getCount(noInteractionsPartners) },
    { name: '3+ Evaluations', href: `${root}/explore/partners/multiple-evaluations`, count: getCount(multipleEvaluationsPartners) },
    { name: '25+ Hours Served', href: `${root}/explore/partners/multiple-hours`, count: getCount(multipleHoursPartners) },
    { name: '50+ Students Served', href: `${root}/explore/partners/multiple-students`, count: getCount(multipleStudentsPartners) },
    // { name: 'Pending Evaluations', href: `${root}/explore/partners/evaluations`, count: getCount(evaluationsPartners) },
    // { name: 'Unresponsive', href: `${root}/explore/partners/unresponsive`, count: getCount(unresponsivePartners) },
    { name: 'All', href: `${root}/explore/partners` }
  ];
}
