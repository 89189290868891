import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import InactiveUserDialog from '../../../../dialogs/user/inactive';

export default function Inactive(props) {
  const { user } = props;

  const show = useDialog();

  function onClick() {
    show(
      <InactiveUserDialog
        user={user}
      />, { template: 'dialog' }
    );
  }

  return (
    <SecondaryAction onClick={onClick} danger={user.data.inactive}>
      {user.data.inactive ? 'Reactivate profile' : 'Disable profile'}
    </SecondaryAction>
  );
}
