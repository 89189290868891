import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';

export default function Hours(props) {
  const { user } = props;

  const connect = useConnect();

  const evaluations = connect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  })

  if (evaluations.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  const total = _.reduce(evaluations.data, function(result, evaluation) {
    return result + Number(evaluation.data.contributionValue || 0);
  }, 0);

  return (
    <TextColumn>
      ${total}
    </TextColumn>
  );
}
