import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';
import { REQUIRED_EDUCATOR_FIELDS } from '../../../utils/user/REQUIRED_FIELDS';

export default function TotalYearComplete(props) {
  const { account, schoolStart, schoolEnd } = props;

  const totalUsers = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          isEducator: true,
          $and: {
            ...REQUIRED_EDUCATOR_FIELDS
          }
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const newUsers = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          isEducator: true,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          },
          $and: {
            ...REQUIRED_EDUCATOR_FIELDS
          }
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const totalCount = totalUsers.meta ? totalUsers.meta.totalCount : '...';
  const newCount = newUsers.meta ? newUsers.meta.totalCount : '...';

  return (
    <Metric
      name="Total Complete (+ New)"
      stat={`${totalCount} (+${newCount})`}
      icon={UsersIcon}
    />
  );
}
