import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useStewardshipFilter from '../../explore-partners/useStewardshipFilter';
import Chart from '../Chart';

export default function EngagementByMonth(props) {
  const { account, timestamps, empty, schoolStart } = props;

  const stewardshipFilter = useStewardshipFilter();

  const [currentTimestamp] = useState(moment().toISOString());

  const connect = useConnect();

  const partners = timestamps.map(function(timestamp) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          connect: empty(),
          yes: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        // connect: connect('question.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         createdAt: {
        //           $gte: timestamp.start,
        //           $lte: timestamp.end
        //         },
        //         response: 'Connect',
        //         $or: stewardshipFilter
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        connect: connect('user.find', {
          where: {
            eager: {
              $aggregations: _.remove([
                {
                  type: 'count',
                  alias: 'numberOfQuestions',
                  relation: 'questions',
                  $where: {
                    createdAt: {
                      $gte: timestamp.start,
                      $lte: timestamp.end
                    },
                    response: 'Connect'
                  }
                }
              ]),
              $where: {
                numberOfQuestions: {
                  $gte: 1
                },
                isEducator: false,
                $or: stewardshipFilter
              }
            },
          },
          pagination: {
            pageSize: 1
          }
        }),
        // yes: connect('invitation.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         createdAt: {
        //           $gte: timestamp.start,
        //           $lte: timestamp.end
        //         },
        //         response: 'Yes',
        //         $or: stewardshipFilter
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        yes: connect('user.find', {
          where: {
            eager: {
              $aggregations: _.remove([
                {
                  type: 'count',
                  alias: 'numberOfInvitations',
                  relation: 'invitations',
                  $where: {
                    createdAt: {
                      $gte: timestamp.start,
                      $lte: timestamp.end
                    },
                    response: 'Yes'
                  }
                }
              ]),
              $where: {
                numberOfInvitations: {
                  $gte: 1
                },
                isEducator: false,
                $or: stewardshipFilter
              }
            },
          },
          pagination: {
            pageSize: 1
          }
        }),
      }
    }
  });

  const loading = _.reduce(partners, function(result, partner) {
    return (
      result ||
      partner.results.connect.state === PayloadStates.FETCHING ||
      partner.results.yes.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <Chart name="Partner Engagement" className="col-span-3">
        <Loader />
      </Chart>
    );
  }

  const data = {
    series: [
      {
        name: 'Connected about Project',
        data: partners.map(e => e.results.connect.meta.totalCount)
      },
      {
        name: 'Committed to Event',
        data: partners.map(e => e.results.yes.meta.totalCount)
      }
    ],
    options: {
      title: {
        text: 'Partner Engagement',
        align: 'center'
      },
      subtitle: {
        text: 'Over school year',
        align: 'center'
      },
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: partners.map(e => e.label)
      },
      yaxis: {
        title: {
          text: 'Count'
        }
      },
      fill: {
        opacity: 1
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands"
      //     }
      //   }
      // }
    }
  };

  return (
    <Chart
      // name="Partner growth"
      className="col-span-3"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={350}
      />
    </Chart>
  );
}
