import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import useStewardshipFilter from '../useStewardshipFilter';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';

export default function ActivityFilter(props) {
  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const tags = useTags(TagTypes.Activity);

  const relatedModels = function(tag) {
    return connect('event.find', {
      where: {
        eager: {
          $where: {
            activityId: tag.id,
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    });
  }

  return (
    <TagsFilter
      label="Activity"
      // dropdownLabel1="It should be focused on serving learners in"
      // dropdownLabel2="of the following groups:"
      queryUtils={getQueryUtils}
      filterName="activity"
      queryParamName="activities"
      tags={tags}
      related={relatedModels}
    />
  );
};
