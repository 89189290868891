import React from 'react';
import Metrics from '../../dashboard-year/Metrics';
import EventActivity from './EventActivity';
import EventActivityTreemap from './EventActivity.treemap';
import EventStatus from './EventStatus';
import Total from './Total';
import Students from './Students';

export default function Events(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Events">
        {/*<EventActivity {...params} subtitle="For all school years" />*/}
        <EventActivityTreemap {...params} subtitle="For all school years" />
        <EventStatus {...params} subtitle="For all school years" />
      </Metrics>
      <Metrics>
        <Total {...params} />
        <Students {...params} />
      </Metrics>
    </>
  );
}
