import React  from 'react';
import moment from 'moment-timezone';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';
import { useUser } from '@lore/auth';

export default function DateWindow(props) {
  const { event } = props;

  const currentUser = useUser();

  function getDate(timestamp) {
    if (timestamp) {
      return moment(timestamp).format('MMM D, YYYY');
    }
  }

  function getTime(timestamp) {
    if (timestamp) {
      return moment(timestamp).tz(currentUser.data.timezone).format('h:mma z')
    }
  }

  // const date = getDate(event.data.date);
  //
  // const startTime = event.data.startTime ?
  //   moment(event.data.startTime).tz(currentUser.data.timezone).format('h:mma tz') :
  //   undefined;
  //
  // const endTime = event.data.endTime ?
  //   moment(event.data.endTime).tz(currentUser.data.timezone).format('h:mma tz') :
  //   undefined;

  const date = getDate(event.data.date);

  const time = _.remove([
    getTime(event.data.startTime),
    getTime(event.data.endTime)
  ]).join(' - ');

  return (
    <SupportingTextColumn
      title={date}
      subtitle={time}
    />
  );

  if (
    event.data.date &&
    event.data.startTime &&
    event.data.endTime
  ) {
    return (
      <SupportingTextColumn
        title={getDate(event.data.date)}
        subtitle={time}
      />
    );
  }

  if (
    event.data.date && (
      !event.data.startTime ||
      !event.data.endTime
    )
  ) {
    return (
      <SupportingTextColumn
        title={getDate(event.data.date)}
      />
    );
  }

  if (
    !event.data.date &&
    !event.data.startTime &&
    !event.data.endTime
  ) {
    return (
      <SupportingTextColumn />
    );
  }

  if (
    event.data.date &&
    event.data.startTime &&
    event.data.endTime
  ) {
    return (
      <SupportingTextColumn
        title={getDate(event.data.date)}
        subtitle={`${getTime(event.data.startTime)} - ${getTime(event.data.endTime)}`}
      />
    );
  }

}
