import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Reflections(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Reflections"
      // subtitle="Please rate any changes you observed with your learner(s) as a result of the interaction."
    >
      <TextField label="In a few sentences explain why you decided to do this event and what you actually did with your students and partner(s).">
        {evaluation.data.description || '-'}
      </TextField>
      <TextField label="How were you, your students and/or your partners changed by this event?">
        {evaluation.data.changes || '-'}
      </TextField>
    </Section>
  );
}
