import React from 'react';
import PageHeading from '../_components/PageHeading';
import ParentLayout from '../dashboard/Layout';
import useAccount from '../../hooks/useAccount';
import Students from './Students';
import Educators from './Educators';
import Partners from './Partners';
import Projects from './Projects';
import Events from './Events';
import Offers from './Offers';
import Schools from './Schools';
import Organizations from './Organizations';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const account = useAccount();

  const params = {
    account
  };

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Dashboard']}
          title="All School Years"
        >
        </PageHeading>
        <div className="px-2 py-4 sm:px-3 lg:px-4">
          <Students params={params} />
          <Educators params={params} />
          <Partners params={params} />
          <Projects params={params} />
          <Events params={params} />
          <Offers params={params} />
          <Schools params={params} />
          <Organizations params={params} />

          {/*<Metrics title="Evaluations">*/}
          {/*  <EducatorEvaluations account={account} />*/}
          {/*  <PartnerEvaluations account={account} />*/}
          {/*  <StudentsServed account={account} />*/}
          {/*  <RecommendPlatform account={account} />*/}
          {/*</Metrics>*/}
        </div>
      </main>
    </ParentLayout>
  );
}
