import React from 'react';
import Id from './Table/Id';
import Offer from './Table/Offer';
import Partner from './Table/Partner';
import Organization from './Table/Organization';
import DateWindow from './Table/DateWindow';
import Educators from './Table/Educators';

export default [
  { id: 'id',           name: 'ID',           row: Id,           selected: false },
  { id: 'title',        name: 'Title',        row: Offer,        selected: true },
  { id: 'partner',      name: 'Partner',      row: Partner,      selected: true },
  { id: 'organization', name: 'Organization', row: Organization, selected: true },
  { id: 'date_window',  name: 'Date window',  row: DateWindow,   selected: true },
  { id: 'educators',    name: 'Educators',    row: Educators,    selected: true }
];
