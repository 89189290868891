import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from '@heroicons/react/outline';
import { PayloadStates } from '@lore/utils';
import StringField from '../../../forms/_fields/StringField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import FormField from '../../../forms/_common/FormField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    user
  } = props;

  if (data.inactive) {
    return (
      <FormTemplate
        title="Reactive user profile"
        subtitle={(
          <>
          <span className="block mb-4">
            You are about to reactive the profile
            for <strong>{user.data.firstName} {user.data.lastName}</strong>.
          </span>
            <span className="block mb-4">
            Doing so will allow them to log in, will show their profile
            in search results, and will allows others to message them.
          </span>
            <span className="block mb-4">
            If you are sure you want to do this, type <strong>ACTIVATE</strong> into the field below.
          </span>
          </>
        )}
        icon={ClockIcon}
        showIcon={false}
        onClose={onCancel}
        alert={alert}
        body={(
          <>
            <FormField name="confirmation" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <StringField
                  label="Confirmation"
                  placeholder="Type ACTIVATE..."
                  // description="Some text about a thing I like"
                  value={field.value}
                  error={field.error}
                  onChange={field.onChange}
                />
              )}
            </FormField>
          </>
        )}
        footer={(
          <>
            <OutlineButton onClick={onCancel}>
              Cancel
            </OutlineButton>
            <PrimaryButton onClick={onSubmit}>
              Reactivate profile
            </PrimaryButton>
          </>
        )}
      />
    );
  }

  return (
    <FormTemplate
      title="Disable user profile"
      subtitle={(
        <>
          <span className="block mb-4">
            You are about to disable the profile
            for <strong>{user.data.firstName} {user.data.lastName}</strong>.
          </span>
          <span className="block mb-4">
            Doing so will prevent them from being able to log in, will hide their profile
            in search results, and will prevent others from messaging them.
          </span>
          <span className="block mb-4">
            If you are sure you want to do this, type <strong>DISABLE</strong> into the field below.
          </span>
        </>
      )}
      icon={ClockIcon}
      showIcon={false}
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          <FormField name="confirmation" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Confirmation"
                placeholder="Type DISABLE..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <PrimaryButton onClick={onSubmit}>
            Disable profile
          </PrimaryButton>
        </>
      )}
    />
  );
}
