import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { MenuAlt1Icon } from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'
import classNames from '../../utils/classNames';
import avatar from '../../../assets/images/avatar.svg';
import logo from '../../../assets/images/logo.png';
import { useUser } from '@lore/auth';
import Network from './Network';

export default function SearchHeader(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const user = useUser();

  return (
    <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden bg-primary">
      <button
        className="px-4 border-r border-gray-200 text-gray-500x text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary lg:hidden"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 flex justify-between px-4">
        <div className="flex-1 flex items-center space-x-4">
          <img src={logo} className="h-10" />
          <Network searchHeader={true} />
          {/*<form className="w-full flex md:ml-0" action="#" method="GET">*/}
          {/*  <label htmlFor="search_field" className="sr-only">*/}
          {/*    Search*/}
          {/*  </label>*/}
          {/*  <div className="relative w-full text-gray-400 focus-within:text-gray-600">*/}
          {/*    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">*/}
          {/*      <SearchIcon className="h-5 w-5" aria-hidden="true" />*/}
          {/*    </div>*/}
          {/*    <input*/}
          {/*      id="search_field"*/}
          {/*      name="search_field"*/}
          {/*      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm"*/}
          {/*      placeholder="Search"*/}
          {/*      type="search"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</form>*/}
        </div>
      </div>
    </div>
  )
}
