import React, { useState } from 'react';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import PageHeading from '../_components/PageHeading';
import Table from '../explore-partners/Table';
import SidePanel from '../explore-projects/SidePanel';
import ParentLayout from '../explore/Layout';
import useRouter from '../../hooks/useRouter';
import defaultColumns from '../explore-projects/columns';
import Columns from '../explore-partners/Table/Columns';
import useQuery from './useQuery';
import setSelectedColumns from '../../utils/setSelectedColumns';

const initialColumns = setSelectedColumns(defaultColumns, [
  'title',
  'educator',
  'date_window',
  'partners',
  'request'
]);

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();

  const connect = useConnect();
  const show = useDialog();

  function onClick(project) {
    show(
      <SidePanel
        project={project}
        primaryAction="complete_project"
        extraActions={[
          'publish_project',
          'delete_project'
        ]}
      />
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Explore', 'Projects']}
          title="Requested Funding"
          description={(
            <span>
              These are projects where an educator indicated they'd like to be considered for project
              funding. CommunityShare recommends sending the educator any additional information they
              need to be eligible for your community's project grants (additional questions, grant
              agreements, etc.) You can make notes visible only to your team about the status or
              any outreach you have done.
            </span>
          )}
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        {/*<div className="px-2 py-4 sm:px-3 lg:px-4">*/}
        {/*  <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">*/}
        {/*    <Filters*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*    <SearchTags*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-end">
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            return useQuery({
              location,
              PAGE_SIZE,
              connect
            });
          }}
          row={function (project) {
            return (
              <tr key={project.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(project)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      project={project}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
