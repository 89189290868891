import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';
import useStewardshipFilter from '../useStewardshipFilter';

const options = [
  'Male',
  'Female',
  'Agender',
  'Gender Fluid',
  'Gender Non-conforming',
  'Genderqueer',
  'Non-binary',
  'Questioning',
  'Self Identify Another Option',
  'Prefer Not to Say'
];

export default function Gender(props) {
  const { timestamp } = props;

  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const models = {
    state: PayloadStates.RESOLVED,
    data: options.map(function(value, index) {
      // const weeks = Number(value[0].split('_')[1]);
      return {
        id: index + 1,
        data: {
          name: value
        }
      }
    })
  };

  const related = function(model) {
    // const weeks = Number(model.id.split('_')[1]);
    const weeks = model.id;

    return connect('user.find', {
      where: {
        eager: {
          $where: {
            gender: model.data.name,
            isEducator: false,
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      label="Gender"
      // dropdownLabel1="They should teach"
      // dropdownLabel2="of the following subjects:"
      queryUtils={getQueryUtils}
      filterName="gender"
      queryParamName="genders"
      tags={models}
      related={related}
      timestamp={timestamp}
    />
  );
};
