import React, { useState } from 'react';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Chart from '../Chart';
import REQUIRED_FIELDS from '../../explore-offers/REQUIRED_FIELDS';
import REQUIRED_FIELDS_PUBLIC from '../../explore-offers/REQUIRED_FIELDS_PUBLIC';
import _ from 'lodash';
import moment from 'moment-timezone';

export default function OfferStatusYear(props) {
  const { account, schoolStart, schoolEnd, subtitle } = props;

  const connect = useConnect();
  const [timestamp] = useState(moment().toISOString());

  const requests = [
    {
      label: 'Active',
      offers: connect('offer.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS_PUBLIC.$and,
              accountId: account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    },
    // {
    //   label: 'Completed',
    //   offers: connect('offer.find', {
    //     where: {
    //       eager: {
    //         $where: {
    //           $and: REQUIRED_FIELDS.$and,
    //           accountId: account.id,
    //           createdAt: {
    //             $lte: schoolEnd,
    //             $gte: schoolStart
    //           },
    //           completed: true
    //         }
    //       }
    //     },
    //     pagination: {
    //       pageSize: 1
    //     }
    //   })
    // },
    {
      label: 'Canceled',
      offers: connect('offer.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              accountId: account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              cancelled: true
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    },
    {
      label: 'Expired',
      offers: connect('offer.find', {
        where: {
          eager: {
            $where: {
              $and: REQUIRED_FIELDS.$and,
              accountId: account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              },
              completed: false,
              cancelled: false,
              endDate: {
                $lt: timestamp
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    }
  ];

  const loading = _.reduce(requests, function(result, request) {
    return result || request.offers.state === PayloadStates.FETCHING;
  }, false);

  if (loading) {
    return (
      <Chart name="Offer Status">
        <Loader />
      </Chart>
    );
  }

  const series = requests.map(r => r.offers.meta.totalCount);
  const labels = requests.map(r => r.label);

  const data = {
    series: series,
    options: {
      title: {
        text: 'Offer Status',
        align: 'center'
      },
      subtitle: {
        text: subtitle || 'For school year',
        align: 'center',
        offsetY: 20
      },
      labels: labels,
      chart: {
        type: 'donut',
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        offsetY: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true
              }
            }
          }
        }
      }
    }
  };

  return (
    <Chart
      // name="Offer activity types"
      className="col-span-1 pb-2"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="donut"
        height={350}
      />
    </Chart>
  );
}
