import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Social(props) {
  const { user } = props;

  return (
    <Section
      title="Contact"
      // subtitle="Contact info and demographics."
    >
      <TextField label="Name">
        {user.data.firstName || '-'} {user.data.lastName || '-'}
      </TextField>
      <TextField label="Email">
        {user.data.email || '-'}
      </TextField>
      <TextField label="Phone">
        {user.data.phoneNumber || '-'}
      </TextField>
    </Section>
  );
}
