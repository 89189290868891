import React from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Website(props) {
  const { offer } = props;

  const offerUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    }
  });

  return (
    <Section
      title="More Information"
      // subtitle="Documents uploaded by the partner with more information about the offer."
    >
      <TextField label="Website">
        <a className="text-orange-500 underline hover:text-orange-400" href={offer.data.website} target="_blank">
          {offer.data.website}
        </a>
      </TextField>
      <TextField label="Attachments">
        {offerUploads.data.map(function(offerUpload, index) {
          return (
            <a
              key={index}
              className="text-orange-500 underline hover:text-orange-400"
              href={offerUpload.data.url}
              target="_blank"
            >
              {offerUpload.data.name}
            </a>
          );
        })}
      </TextField>
    </Section>
  )
}
