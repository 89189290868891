import React from 'react';
import { useConnect } from '@lore/connect';
import Section from '../../_components/sidePanel/Section';
import { PayloadStates } from '@lore/utils';
import ClaimSidePanel from '../../explore-claims/SidePanel';
import { useDialog } from '@lore/dialogs';
import moment from 'moment-timezone';

export default function Claims(props) {
  const { user } = props;

  const connect = useConnect();

  const claims = connect('claim.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          offerId: {
            $not: null
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  const show = useDialog();

  function onClick(claim) {
    show(
      <ClaimSidePanel claim={claim} />
    );
  }

  if (
    claims.state === PayloadStates.FETCHING ||
    claims.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Offers"
      subtitle="Past offers I've been involved with."
      className="bg-gray-50"
    >
      <div className="space-y-3">
        {claims.data.map(function(claim) {
          const offer = connect('offer.byId', {
            id: claim.data.offerId
          });

          if (offer.state === PayloadStates.FETCHING) {
            return (
              <div
                key={claim.id}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                {/*<div className="flex-shrink-0">*/}
                {/*  <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />*/}
                {/*</div>*/}
                <div className="flex-1 min-w-0">
                  <a href="#" className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">
                      ...
                    </p>
                    <p className="text-sm text-gray-500 truncate">
                      ...
                    </p>
                  </a>
                </div>
              </div>
            );
          }

          const activity = connect('tag.byId', {
            id: offer.data.activityId
          });

          const colorMap = {
            'Pending': 'bg-blue-400',
            'Connect': 'bg-green-400',
            'Decline': 'bg-red-400',
          };

          const responseMap = {
            'Pending': 'Pending',
            'Connect': 'Connected',
            'Decline': 'Declined',
          };

          const responseColor = colorMap[claim.data.response] || 'bg-gray-400';

          return (
            <div
              key={claim.id}
              className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              onClick={() => onClick(claim)}
            >
              {/*<div className="flex-shrink-0">*/}
              {/*  <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />*/}
              {/*</div>*/}
              <div className="flex-1 min-w-0">
                <a href="#" className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">
                    {offer.data.title || activity.data.name || '...'}
                  </p>
                  <p className="text-sm text-gray-500 truncate mb-2">
                    {moment(offer.data.startDate).format('MMM D, YYYY')} - {moment(offer.data.endDate).format('MMM D, YYYY')}
                  </p>
                  <p className="text-sm text-gray-500 truncatex flex space-x-2.5">
                    <span className={`mr-2.5x mt-1.5 ${responseColor} flex-shrink-0 inline-block h-2 w-2 rounded-full`}>
                      <span className="sr-only">{claim.data.response}</span>
                    </span>
                    <span>
                      {responseMap[claim.data.response] || claim.data.response}
                      {claim.data.response === 'Decline' && (
                        <>
                          <span>{` · `}</span>
                          <span className="italic">{claim.data.responseMessage}</span>
                        </>
                      )}
                    </span>
                  </p>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  )
}
