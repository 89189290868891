import React, { useState } from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/connect';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import People from './People';
import Invitation from './Invitation';
import Interaction from './Interaction';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import { PayloadStates } from '@lore/utils';

export default function SidePanel(props) {
  const { invitation } = props;

  const connect = useConnect();

  const [tabs, setTabs] = useState([
    { id: 'details', name: 'Details', selected: true },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  const event = connect('event.byId', {
    id: invitation.data.eventId
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Panel title="Invitation" onClose={props.onClose}>
        Loading...
      </Panel>
    )
  }

  const activity = connect('tag.byId', {
    id: event.data.activityId
  });

  return (
    <Panel title="Invitation" onClose={props.onClose}>
      <div>
        <Header
          src={activity.data.imageUrl}
          title={event.data.title || activity.data.name}
          subtitle={activity.data.name}
        >
          <Actions
            invitation={invitation}
          />
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'details' && (
          <div className="divide-y divide-gray-200">
            <People event={event} invitation={invitation} />
            <Invitation event={event} invitation={invitation} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <Notes invitation={invitation} />
          </div>
        )}
      </div>
    </Panel>
  );
}
