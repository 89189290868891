import React from 'react';
import { useConnect } from '@lore/connect';
import TextColumn from '../../_components/table/columns/TextColumn';
import PayloadStates from '../../../constants/PayloadStates';

export default function EducatorPhone(props) {
  const { invitation } = props;

  const connect = useConnect();

  const event = connect('event.byId', {
    id: invitation.data.eventId
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  const user = connect('user.byId', {
    id: event.data.creatorId
  });

  return (
    <TextColumn>
      {user.data.phoneNumber}
    </TextColumn>
  );
}
