import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Messages(props) {
  const { question } = props;

  const connect = useConnect();

  const project = connect('project.byId', {
    id: question.data.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  const messages = connect('message.find', {
    where: {
      eager: {
        $where: {
          $and: [
            {
              $or: [
                { senderId: project.data.creatorId },
                { senderId: question.data.userId }
              ]
            },
            {
              $or: [
                { recipientId: project.data.creatorId },
                { recipientId: question.data.userId }
              ]
            }
          ],
        }
      }
    }
  });

  if (messages.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  return (
    <TextColumn>
      {messages.meta.totalCount}
    </TextColumn>
  );
}
