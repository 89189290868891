import React from 'react';
import { useDialog } from '@lore/dialogs';
import Wrapper from '../../../_components/actions/Actions';
import useAccount from '../../../../hooks/useAccount';
import Contact from './Contact';
import Sleep from './Sleep';
import InviteOffer from './InviteOffer';
import CompleteProfile from './CompleteProfile';
import View from '../../../explore-partners/SidePanel/Actions/View';
import Inactive from './Inactive';

export default function Actions(props) {
  const { user } = props;

  const account = useAccount();

  if (user.data.accountId !== account.id) {
    return (
      <Wrapper>
        <div className="bg-accent w-full py-3 px-4 rounded-md font-medium text-white">
          Educator is outside your stewardship. Actions are disabled.
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {/*<Contact user={user} />*/}
      <InviteOffer user={user} />
      {/*<Sleep user={user} />*/}
      <Inactive user={user} />
      <View user={user} />
      <CompleteProfile user={user} />
    </Wrapper>
  );
}
