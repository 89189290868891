import React from 'react';
import { useConnect } from '@lore/connect';
import Section from '../../_components/sidePanel/Section';
import { PayloadStates } from '@lore/utils';
import OfferSidePanel from '../../explore-offers/SidePanel';
import { useDialog } from '@lore/dialogs';
import moment from 'moment-timezone';

export default function Offers(props) {
  const { user } = props;

  const connect = useConnect();

  const offers = connect('offer.find', {
    where: {
      eager: {
        $where: {
          creatorId: user.id,
          activityId: {
            $not: null
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  const show = useDialog();

  function onClick(offer) {
    show(
      <OfferSidePanel offer={offer} />
    );
  }

  return (
    <Section
      title="Offers"
      // subtitle="Past offers I created."
      className="bg-gray-50"
    >
      <div className="space-y-3">
        {offers.data.map(function(offer) {
          const activity = connect('tag.byId', {
            id: offer.data.activityId
          });

          return (
            <div
              key={offer.id}
              className="cursor-pointer relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              onClick={() => onClick(offer)}
            >
              {/*<div className="flex-shrink-0">*/}
              {/*  <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />*/}
              {/*</div>*/}
              <div className="flex-1 min-w-0">
                <a href="#" className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">
                    {offer.data.title || activity.data.name || '...'}
                  </p>
                  <p className="text-sm text-gray-500 truncatex mb-2">
                    {moment(offer.data.startDate).format('MMM D, YYYY')} - {moment(offer.data.endDate).format('MMM D, YYYY')}
                  </p>
                  <p className="text-sm text-gray-500 truncatex">
                    {activity.data.name}
                  </p>
                </a>
              </div>
            </div>
          );
        })}
        {offers.data.length === 0 && '-'}
      </div>
    </Section>
  );
}
