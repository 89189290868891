import React from 'react';
import Sidebar from '../_components/Sidebar';
import SearchHeader from '../_components/SearchHeader';
import navigationLinks from './navigationLinks';
import useAccount from '../../hooks/useAccount';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const account = useAccount();

  return (
    <>
      <Sidebar
        title="Settings"
        navigation={navigationLinks(account)}
        sidebarOpen={sidebarOpen}
      />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <SearchHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        {props.children || (
          <h3 className="font-bold text-xl text-gray-900 sm:text-2xl px-5 py-4">
            Placeholder: Settings
          </h3>
        )}
      </div>
    </>
  )
}
