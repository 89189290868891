import React from 'react';
import { useConnect } from '@lore/connect';
import useRouter from '../../hooks/useRouter';

// Educators
import starredEducators from '../explore-educators-starred/useQuery';
import incompleteEducators from '../explore-educators-incomplete/useQuery';
import invitationsEducators from '../explore-educators-invitations/useQuery';
import unresponsiveEducators from '../explore-educators-unresponsive/useQuery';
import evaluationsEducators from '../explore-educator-evaluations-pending/useQuery';
import newEducators from '../explore-educators-new/useQuery';
import noneCreatedEducators from '../explore-educators-none-created/useQuery';
import multipleCompletedEducators from '../explore-educators-multiple-completed/useQuery';
import useAccount from '../../hooks/useAccount';

export default function getNavigationLinks() {
  const { location } = useRouter();
  const connect = useConnect();
  const account = useAccount();

  function getCount(query) {
    const result = query({ location, connect });
    return result.meta ? result.meta.totalCount : '...';
  }

  const root = `/c/${account.data.subdomain}`;

  return [
    { name: 'Starred', href: `${root}/explore/educators/starred`, count: getCount(starredEducators) },
    { name: 'New', href: `${root}/explore/educators/new`, count: getCount(newEducators), highlight: true },
    { name: 'Incomplete Profile', href: `${root}/explore/educators/incomplete`, count: getCount(incompleteEducators) },
    { name: 'No Requests', href: `${root}/explore/educators/none-created`, count: getCount(noneCreatedEducators) },
    { name: '3+ Completed', href: `${root}/explore/educators/multiple-completed`, count: getCount(multipleCompletedEducators) },
    // { name: 'Pending Evaluations', href: '${root}/explore/educators/evaluations', count: getCount(evaluationsEducators) },
    // { name: 'Unresponsive', href: '${root}/explore/educators/unresponsive', count: getCount(unresponsiveEducators) },
    { name: 'All', href: `${root}/explore/educators` }
  ];
}
