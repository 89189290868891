import React from 'react';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';

export default function Partner(props) {
  const { project } = props;

  // const projectGuidances = useProjectTags(project, TagTypes.Guidance);
  const projectExpertiseTypes = useProjectTags(project, TagTypes.ExpertiseType);

  // if (
  //   projectGuidances.state === PayloadStates.FETCHING ||
  //   projectExpertiseTypes.state === PayloadStates.FETCHING
  // ) {
  //   return null;
  // }

  return (
    <Section
      title="Ideal Partner"
      // subtitle="The ideal partner has experience with some or all of these topics."
    >
      <TextField label="Experience">
        {projectExpertiseTypes.data.map(function(projectExpertiseType) {
          return (
            <div key={projectExpertiseType.id || projectExpertiseType.cid}>
              {projectExpertiseType._tag.data.name}
            </div>
          );
        })}
        {projectExpertiseTypes.data.length === 0 && '-'}
      </TextField>
      {/*{projectGuidances.data.length > 0 && (*/}
      {/*  <TextField label="Learners would benefit from partner addressing these topics.">*/}
      {/*    {projectGuidances.data.map(function(projectGuidance) {*/}
      {/*      return (*/}
      {/*        <div key={projectGuidance.id || projectGuidance.cid}>*/}
      {/*          {projectGuidance._tag.data.name}*/}
      {/*        </div>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </TextField>*/}
      {/*)}*/}
      {/*{project.data.training && (*/}
      {/*  <TextField label="Educator recommends training in the following areas.">*/}
      {/*    {project.data.training}*/}
      {/*  </TextField>*/}
      {/*)}*/}
      {/*<TextField label="This project would be a good fit for a group of partners.">*/}
      {/*  {project.data.groupActivity || 'No'}*/}
      {/*</TextField>*/}
    </Section>
  )
}
