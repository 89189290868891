import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import Metric from '../../dashboard-year/Metric';
import { useConnect } from '@lore/connect';
import useStewardshipFilter from '../../explore-partners/useStewardshipFilter';
import REQUIRED_FIELDS from '../../explore-offers/REQUIRED_FIELDS';

export default function SharedOffer(props) {
  const { account, schoolStart, schoolEnd } = props;

  const stewardshipFilter = useStewardshipFilter();

  const users = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfOffers',
            relation: 'offers',
            $where: {
              // published: true,
              activityId: {
                $not: null
              },
              accountId: account.id,
              // createdAt: {
              //   $lte: schoolEnd,
              //   $gte: schoolStart
              // },
              $and: REQUIRED_FIELDS.$and
            }
          }
        ],
        $where: {
          numberOfOffers: {
            $gte: 1
          },
          isEducator: false,
          $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <Metric
      name="Shared an Offer"
      stat={users.meta ? users.meta.totalCount : '...'}
      icon={UsersIcon}
    />
  );
}
