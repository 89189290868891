import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import { PayloadStates } from '@lore/utils';
import PartnerSidePanel from '../../explore-partners/SidePanel';
import EventSidePanel from '../../explore-events/SidePanel';

function Loader() {
  return (
    <Section>
      <TextField label="Partner">
        ...
      </TextField>
      <TextField label="Event">
        ...
      </TextField>
    </Section>
  );
}

export default function People(props) {
  const { evaluation } = props;

  const connect = useConnect();
  const show = useDialog();

  const event = connect('event.byId', {
    id: evaluation.data.eventId
  });

  const partner = connect('user.byId', {
    id: evaluation.data.userId
  });

  if (
    event.state === PayloadStates.FETCHING ||
    partner.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const activity = connect('tag.byId', {
    id: event.data.activityId
  });

  function onClickPartner() {
    show(
      <PartnerSidePanel user={partner} />
    );
  }

  function onClickEvent() {
    show(
      <EventSidePanel event={event} />
    );
  }

  return (
    <Section
      // title="Educator information"
      // subtitle="Name and contact info."
    >
      <TextField label="Partner">
        <span onClick={onClickPartner} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {partner.data.firstName} {partner.data.lastName}
        </span>
      </TextField>
      <TextField label="Event">
        <span onClick={onClickEvent} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {event.data.title || activity.data.name}
        </span>
      </TextField>
    </Section>
  );
}
