import React from 'react';
import _ from 'lodash';
import { CurrencyDollarIcon } from '@heroicons/react/outline';
import Metric from '../../dashboard-year/Metric';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';

export default function Contribution(props) {
  const { account, schoolStart, schoolEnd } = props;

  const totalEvaluations = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          'project.accountId': account.id,
          contributionValue: {
            $gte: 0
          }
        }
      },
    },
    pagination: {
      pageSize: 100
    }
  });

  if (totalEvaluations.state === PayloadStates.FETCHING) {
    return (
      <Metric
        name="Total in-kind contributions"
        stat="..."
        icon={CurrencyDollarIcon}
      />
    );
  }

  const totalCount = _.reduce(totalEvaluations.data, function(result, evaluation) {
    return result + evaluation.data.contributionValue;
  }, 0);

  return (
    <Metric
      name="Total in-kind contributions"
      stat={`$${totalCount}`}
      icon={CurrencyDollarIcon}
    />
  );
}
