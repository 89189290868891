import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import { ClockIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import MessageDeliveryStatus from '../../../components/MessageDeliveryStatus';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import ProjectsEvents from './ProjectsEvents';
import Projects from '../invite.project/Projects';
import Events from '../invite.event/Events';
import StringField from '../../../forms/_fields/StringField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    user
  } = props;

  const [title, setTitle] = useState('');
  const [name, setName] = useState('');

  function onChangeTitle(text) {
    setTitle(text);
  }

  function onChangeName(text) {
    setName(text);
  }

  function onClickProject(project) {
    onChange('project', project);
  }

  function onClickEvent(event) {
    onChange('event', event);
  }

  return (
    <FormTemplate
      title={`Invite ${user.data.firstName || 'Partner'} to a project/event?`}
      subtitle={(
        <span>
          Is there a project or event that you think would be a good fit for this partner? Search for it below!
        </span>
      )}
      showIcon={false}
      icon={ClockIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="mt-4 space-y-4">
          <StringField
            label="Title"
            placeholder="Enter title..."
            value={title}
            onChange={onChangeTitle}
          />
          <StringField
            label="Educator name"
            placeholder="Enter name..."
            value={name}
            onChange={onChangeName}
          />
          <ProjectsEvents
            title={title}
            name={name}
            onClickProject={onClickProject}
            onClickEvent={onClickEvent}
          />
          {/*<Projects*/}
          {/*  title={title}*/}
          {/*  name={name}*/}
          {/*  onClick={onClick}*/}
          {/*/>*/}
          {/*<Events*/}
          {/*  title={title}*/}
          {/*  name={name}*/}
          {/*  onClick={onClick}*/}
          {/*/>*/}
        </div>
      )}
      footer={(
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          {/*<PrimaryButton onClick={onSubmit}>*/}
          {/*  Send reminder*/}
          {/*</PrimaryButton>*/}
        </>
      )}
    />
  );
}
