import React from 'react';
import Metrics from '../../dashboard-year/Metrics';
import ImpactProjects from './ImpactProjects';
import ImpactEvents from './ImpactEvents';
import ProjectEvalResponse from './ProjectEvalResponse';

export default function Students(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Student Impact (Projects)">
        <ImpactProjects {...params} />
        {/*<ImpactEvents {...params} />*/}
      </Metrics>
      <Metrics title="Project Impact on Students (as rated by educators)">
        <ProjectEvalResponse
          {...params}
          title={["Students were", "more engaged"]}
          subtitle="For all school years"
          field="engagedRating"
        />
        <ProjectEvalResponse
          {...params}
          title={["Students built", "social-emotional skills"]}
          subtitle="For all school years"
          field="skillsRating"
        />
        <ProjectEvalResponse
          {...params}
          title={["Students increased awareness","of future academic and","career opportunities"]}
          subtitle="For all school years"
          field="awarenessRating"
        />
        <ProjectEvalResponse
          {...params}
          title={["Students increased understanding", "of the real-world application", "of topic/content"]}
          subtitle="For all school years"
          field="understandingRating"
        />
        <ProjectEvalResponse
          {...params}
          title={["Students practiced", "critical thinking, problem-solving,","and/or working as a team"]}
          subtitle="For all school years"
          field="practicedRating"
        />
      </Metrics>
    </>
  );
}
