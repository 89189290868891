import React  from 'react';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';
import useUserTags from '../../../../../hooks/useUserTags';
import TagTypes from '../../../../../constants/TagTypes';

export default function Age(props) {
  const { user } = props;

  const userAges = useUserTags(user, TagTypes.Age);

  if (userAges.state === PayloadStates.FETCHING) {
    return (
      <TextColumn loading={true} />
    );
  }

  return (
    <TextColumn>
      {userAges.data.map(function(userAge) {
        return userAge._tag.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
