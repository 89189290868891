import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import { PayloadStates } from '@lore/utils';
import EducatorSidePanel from '../../explore-educators/SidePanel';
import PartnerSidePanel from '../../explore-partners/SidePanel';
import ProjectSidePanel from '../../explore-projects/SidePanel';
// import InteractionSidePanel from '../../../explore-interactions/SidePanel';
import moment from 'moment-timezone';

function Loader() {
  return (
    <Section>
      <TextField label="Partner">
        ...
      </TextField>
      <TextField label="Project">
        ...
      </TextField>
    </Section>
  );
}

export default function People(props) {
  const { evaluation } = props;

  const connect = useConnect();
  const show = useDialog();

  const project = connect('project.byId', {
    id: evaluation.data.projectId
  });

  const partner = connect('user.byId', {
    id: evaluation.data.userId
  });

  if (
    project.state === PayloadStates.FETCHING ||
    partner.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const activity = connect('tag.byId', {
    id: project.data.activityId
  });

  function onClickPartner() {
    show(
      <PartnerSidePanel user={partner} />
    );
  }

  function onClickProject() {
    show(
      <ProjectSidePanel project={project} />
    );
  }

  return (
    <Section
      // title="Educator information"
      // subtitle="Name and contact info."
    >
      <TextField label="Partner">
        <span onClick={onClickPartner} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {partner.data.firstName} {partner.data.lastName}
        </span>
      </TextField>
      <TextField label="Project">
        <span onClick={onClickProject} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {project.data.title || activity.data.name}
        </span>
      </TextField>
    </Section>
  );
}
