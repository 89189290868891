import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useRequest } from '@lore/request';
import { useUser } from '@lore/auth';
import moment from 'moment-timezone';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SuccessMessage from '../../../forms/_common/SuccessMessage';
import Form from './Form';
import DialogTemplate from '../../_common/DialogTemplate';
import Confirmation from './Confirmation';

Dialog.propTypes = {
  conversation: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { conversation } = props;

  const currentUser = useUser();

  const [data, setData] = useState({
    type: 'One-Time',
    frequency: 'Daily',
    customFrequency: '',
    date: moment().toISOString(),
    startDate: moment().toISOString(),
    endDate: moment().toISOString(),
    checkInTime: moment().toISOString(),
    startTime: moment().toISOString(),
    endTime: moment().toISOString(),
    placeId: null,
    location: 'Virtual',
    locationDescription: '[recorded by steward]',
    bring: '',
    notes: ''
  });
  const [showErrors, setShowErrors] = useState(false);
  const [_request, setRequest] = useState(null);
  const [finished, setFinished] = useState(false);

  const { request, saving, success, error } = useRequest('interaction.', _request);

  useEffect(() => {
    if (success && !finished) {
      setFinished(true);
      // props.onClose();
      // props.onSubmit && props.onSubmit(request);
    }
  });

  function onSubmit() {
    setRequest(
      lore.actions.interaction.create({
        ...data,
        conversationId: conversation.id
      }).payload
    );
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;

    if (
      name === 'startDate' &&
      (!nextData.endDate || moment(nextData.endDate).diff(value) < 0)
    ) {
      nextData.endDate = value;
    }

    if (
      name === 'endDate' &&
      (!nextData.startDate || moment(nextData.startDate).diff(value) > 0)
    ) {
      nextData.startDate = value;
    }

    setData(nextData);
  }

  const _validators = data.type === 'One-Time' ? {
    date: [validators.isRequired],
    checkInTime: [validators.isRequired],
    startTime: [validators.isRequired],
    endTime: [validators.isRequired]
  } : {
    startDate: [validators.isRequired],
    endDate: [validators.isRequired],
    checkInTime: [validators.isRequired],
    startTime: [validators.isRequired],
    endTime: [validators.isRequired]
  };

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={_validators}>
          {({errors, hasError}) => {
            if (success) {
              return (
                <Confirmation
                  onCancel={props.onClose}
                />
              );
            }

            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onCancel={props.onClose}
                alert={error ? (
                  <RequestError request={request}/>
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : (false && success) ? (
                  <SuccessMessage>
                    Idea created.
                  </SuccessMessage>
                ) : null}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
