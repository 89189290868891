import React  from 'react';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';
import TagTypes from '../../../../../constants/TagTypes';
import useUserTags from '../../../../../hooks/useUserTags';
import useTags from '../../../../../hooks/useTags';

export default function ExpertiseType(props) {
  const { user } = props;

  const expertiseTypes = useTags(TagTypes.ExpertiseType);
  const userExpertises = useUserTags(user, TagTypes.Expertise);

  if (expertiseTypes.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  const filteredExpertiseTypes = expertiseTypes.data.filter(function(expertiseType) {
    return _.find(userExpertises.data, function(userExpertise) {
      return userExpertise._parentTag && userExpertise._parentTag.id === expertiseType.id
    });
  });

  return (
    <TextColumn>
      {filteredExpertiseTypes.map(function(expertiseType) {
        return expertiseType.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
