import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from '../../components/ScrollToTop';
import Sidebar from '../_components/Sidebar';
import getNavigationLinks from './navigationLinks.educators';
import useRouter from '../../hooks/useRouter';

LayoutExploreEducators.propTypes = {
  children: PropTypes.node
};

export default function LayoutExploreEducators(props) {
  const { children } = props;
  const { location, history } = useRouter();

  const navigationLinks = getNavigationLinks();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="h-explore-filters flex overflow-hidden">
      <Sidebar
        navigation={navigationLinks}
        sidebarOpen={sidebarOpen}
      />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        {/*<SearchHeader*/}
        {/*  sidebarOpen={sidebarOpen}*/}
        {/*  setSidebarOpen={setSidebarOpen}*/}
        {/*/>*/}
        <div id="scrollAnchor" className="flex-1 overflow-y-auto">
          <ScrollToTop key={`scroll-to-top-${location.pathname}`}/>
          {props.children}
        </div>
      </div>
    </div>
  );
}
