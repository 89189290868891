import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import { onChange, onChangeArray, onChangeLocationCondition, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils, { attributeKeys } from '../queryUtils';
import Joined from './Joined';
import TextSearch from './TextSearch';
import TagTypes from '../../../constants/TagTypes';
import TagSet from '../../explore-partners/SearchTags/_common/TagSet';

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const { params: searchParams } = getQueryUtils(parse(location.search), attributeKeys, timestamp);

  return (
    <div className="space-y-3">
      <TagSet type={TagTypes.Grade} param="grades" timestamp={timestamp} />
      <TagSet type={TagTypes.Practice} param="practices" timestamp={timestamp} />
      <TagSet type={TagTypes.Subject} param="subjects" timestamp={timestamp} />
      <Joined timestamp={timestamp} />
      <TextSearch param="name" type="Name" timestamp={timestamp} />
      <TextSearch param="organization" type="Organization" timestamp={timestamp} />
      <TextSearch param="searchEducator" type="Search" timestamp={timestamp} />
      {/*<Tags*/}
      {/*  type="Interactions created"*/}
      {/*  query={searchParams.educatorInteractions}*/}
      {/*  models={{*/}
      {/*    data: [*/}
      {/*      0,*/}
      {/*      1,*/}
      {/*      2,*/}
      {/*      3*/}
      {/*    ].map(function(value) {*/}
      {/*      return {*/}
      {/*        id: value + 1,*/}
      {/*        data: {*/}
      {/*          name: value === 3 ? `${value}+` :`${value}`*/}
      {/*        }*/}
      {/*      }*/}
      {/*    })*/}
      {/*  }}*/}
      {/*  onChange={onChangeArray('educatorInteractions', { location, history })}*/}
      {/*/>*/}
      <Tags
        type="Projects created"
        query={searchParams.projects}
        models={mapArray([
          0,1,2,3,4,5,6,7,8,9,10
        ])}
        onChange={onChangeArray('projects', { location, history })}
      />
      <Tags
        type="Events created"
        query={searchParams.projects}
        models={mapArray([
          0,1,2,3,4,5,6,7,8,9,10
        ])}
        onChange={onChangeArray('events', { location, history })}
      />
    </div>
  );
};
