import React from 'react';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import { useConfig } from '@lore/config';

export default function View(props) {
  const { project } = props;

  const config = useConfig();

  function onClick() {
    window.open(`${config.custom.platformUrl()}/projects/${project.id}`, '_blank');
  }

  return (
    <SecondaryAction onClick={onClick}>
      View Project <ExternalLinkIcon className="h-5 w-5 text-accent ml-1.5" aria-hidden="true" />
    </SecondaryAction>
  );
}
