import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';
import '../../../assets/css/rc-time-picker.css';
import classNames from '../../utils/classNames';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

TimeField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.node,
  time: PropTypes.string
};

TimeField.defaultProps = {
  disabled: false
};

export default function TimeField(props) {
  const {
    value,
    label,
    placeholder,
    description,
    disabled,
    onChange,
    error,
    time,
    name,
    className
  } = props;

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ): null}
      <div className={classNames(
        label ? 'mt-1' : '',
        error ? 'relative rounded-md shadow-sm' : ''
      )}>
        <TimePicker
          showSecond={false}
          defaultValue={time ? moment(time) : ''}
          className="w-full"
          // className={classNames(
          //   'rc-time-picker__form-control flex-1',
          //   error ?
          //     'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' :
          //     'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
          // )}
          // className={error ?
          //   'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' :
          //   'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
          // }
          popupClassName="rc-time-picker__popup"
          onChange={value => onChange(moment(value).toISOString(true))}
          use12Hours={true}
          allowEmpty={false}
          minuteStep={5}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600">
          {error}
        </p>
      )}
      {description && (
        <p className="mt-2 text-sm text-gray-500">
          {description}
        </p>
      )}
    </div>
  );
}
