import React from 'react';
import moment from 'moment-timezone';
import FeedItem from './FeedItem';
import { useConnect } from '@lore/connect';

export default function Question(props) {
  const { question } = props;

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  const partner = useConnect('user.byId', {
    id: question.data.userId
  });

  return (
    <FeedItem timestamp={moment(question.data.createdAt).fromNow()} last={props.last}>
      <span className="font-medium text-gray-900">
        {partner.data.firstName} {partner.data.lastName}
      </span> was invited to the project titled <span className="font-medium text-gray-900">
        {project.data.title || '[missing title]'}
      </span>
    </FeedItem>
  );
}
