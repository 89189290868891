import React from 'react';
import moment from 'moment-timezone';
import FeedItem from './FeedItem';
import { useConnect } from '@lore/connect';

export default function Claim(props) {
  const { claim } = props;

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  const partner = useConnect('user.byId', {
    id: claim.data.userId
  });

  return (
    <FeedItem timestamp={moment(claim.data.createdAt).fromNow()} last={props.last}>
      <span className="font-medium text-gray-900">
        {partner.data.firstName} {partner.data.lastName}
      </span> was invited to the offer titled <span className="font-medium text-gray-900">
        {offer.data.title || '[missing title]'}
      </span>
    </FeedItem>
  );
}
