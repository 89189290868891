import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import AssignEventDialog from '../../../../dialogs/event/Assign';

export default function Assign(props) {
  const {
    event,
    onClose = () => {}
  } = props;

  const show = useDialog();

  function onClick() {
    show(
      <AssignEventDialog
        event={event}
        onSubmit={() => onClose()}
      />, { template: 'dialog' }
    );
  }

  return (
    <SecondaryAction onClick={onClick} secret={true}>
      Assign to Steward
    </SecondaryAction>
  );
}
