import React  from 'react';

export default function Response(props) {
  const { question } = props;

  const responseMap = {
    Pending: 'bg-yellow-100 text-yellow-800',
    Connect: 'bg-green-100 text-green-800',
    Decline: 'bg-red-100 text-red-800'
  };

  const color = responseMap[question.data.response] || 'bg-gray-100 text-gray-800';

  return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-500">
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${color}`}>
          {question.data.response}
        </span>
      </div>
    </td>
  );
}
