import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';
import useStewardshipFilter from '../useStewardshipFilter';
import useRouter from '../../../hooks/useRouter';

export default function ProjectFilter(props) {
  const { location, history } = useRouter();

  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10
    ].map(function(value) {
      return {
        id: value + 1,
        data: {
          name: `${value}`
        }
      }
    })
  };

  const users = function(model) {
    return connect('user.find', {
      where: {
        eager: {
          // $aggregations: [
          //   {
          //     type: 'count',
          //     alias: 'numberOfProjects',
          //     relation: 'projects'
          //   }
          // ],
          $where: {
            projects: {
              $count_eq: model.id - 1,
            },
            isEducator: true,
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Projects created"
      // dropdownLabel1="They should teach"
      // dropdownLabel2="of the following subjects:"
      queryUtils={getQueryUtils}
      filterName="project"
      queryParamName="projects"
      tags={models}
      related={users}
    />
  );
};
