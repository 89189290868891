import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useRequest } from '@lore/request';
import { useUser } from '@lore/auth';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SuccessMessage from '../../../forms/_common/SuccessMessage';
import DialogTemplate from '../../_common/DialogTemplate';
import Form from './Form';
import FormProject from '../invite.project/Form';
import InviteProject from '../invite.project/Invite';
import ConfirmationProject from '../invite.project/Confirmation';
import FormEvent from '../invite.event/Form';
import InviteEvent from '../invite.event/Invite';
import ConfirmationEvent from '../invite.event/Confirmation';

Dialog.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Dialog(props) {
  const { user } = props;

  const currentUser = useUser();

  const [data, setData] = useState({
    project: null,
    event: null,
    userId: user.id,
    creatorId: currentUser.id
  });
  const [showErrors, setShowErrors] = useState(false);
  const [_request, setRequest] = useState(null);
  const [finished, setFinished] = useState(false);

  const { request, saving, success, error } = useRequest(data.project ? 'question.' : 'invitation.', _request);

  useEffect(() => {
    if (success && !finished) {
      setFinished(true);
      // props.onClose();
      // props.onSubmit && props.onSubmit(request);
    }
  });

  function onSubmitProject() {
    setRequest(
      lore.actions.question.create({
        projectId: data.project.id,
        userId: data.userId,
        creatorId: data.creatorId
      }).payload
    );
  }

  function onSubmitEvent() {
    setRequest(
      lore.actions.invitation.create({
        eventId: data.event.id,
        userId: data.userId,
        creatorId: data.creatorId
      }).payload
    );
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;

    if (name === 'project') {
      nextData.event = null;
    }

    if (name === 'event') {
      nextData.project = null;
    }

    setData(nextData);
  }

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // text: [validators.isRequired]
        }}>
          {({errors, hasError}) => {
            if (success && data.project) {
              return (
                <ConfirmationProject
                  onCancel={props.onClose}
                />
              );
            }

            if (success && data.event) {
              return (
                <ConfirmationEvent
                  onCancel={props.onClose}
                />
              );
            }

            if (data.project) {
              return (
                <InviteProject
                  data={data}
                  errors={errors}
                  showErrors={showErrors}
                  onChange={onChange}
                  onSubmit={() => hasError ? setShowErrors(true) : onSubmitProject()}
                  onCancel={props.onClose}
                  onBack={() => setData({
                    ...data,
                    project: null
                  })}
                  project={data.project}
                  user={user}
                />
              )
            }

            if (data.event) {
              return (
                <InviteEvent
                  data={data}
                  errors={errors}
                  showErrors={showErrors}
                  onChange={onChange}
                  onSubmit={() => hasError ? setShowErrors(true) : onSubmitEvent()}
                  onCancel={props.onClose}
                  onBack={() => setData({
                    ...data,
                    event: null
                  })}
                  event={data.event}
                  user={user}
                />
              )
            }

            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                // onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onCancel={props.onClose}
                alert={error ? (
                  <RequestError request={request}/>
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : (false && success) ? (
                  <SuccessMessage>
                    Idea created.
                  </SuccessMessage>
                ) : null}
                user={user}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
