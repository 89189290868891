import React, { useState } from 'react';
import { useDialog } from '@lore/dialogs';
import { useConnect } from '@lore/connect';
import PageHeading from '../_components/PageHeading';
import ParentLayout from '../settings/Layout';
import useRouter from '../../hooks/useRouter';
// import Screening from './Screening';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useAccount from '../../hooks/useAccount';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const account = useAccount();

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Settings']}
          title="Grants"
          description={(
            <>
              <span className="block">
                Would you like to allow Educators to apply for grant consideration when creating
                a project?
              </span>
            </>
          )}
        />
        {account.state === PayloadStates.FETCHING ? (
          <div className="px-2 py-4 sm:px-3 lg:px-4s space-y-6">
            <Loader />
          </div>
        ) : (
          <div className="px-2 py-4 sm:px-3 lg:px-4">
            <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">
              <Form account={account} />
            </div>
          </div>
        )}
      </main>
    </ParentLayout>
  );
}
