import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';
import moment from 'moment-timezone';
import { mapArray } from '../../../utils/searchTags';
import useStewardshipFilter from '../useStewardshipFilter';

ActiveFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function ActiveFilter(props) {
  const { location, history, timestamp } = props;

  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const models = mapArray([
    'No invites',
    'Invited',
    'Interested',
    'Scheduling',
    'Attending'
  ]);

  const stageMap = {
    'No invites': {
      numberOfConversationsInvited: 0,
      numberOfConversationsInterested: 0,
      numberOfConversationsScheduling: 0,
      numberOfConversationsAttending: 0
    },
    'Invited': {
      numberOfConversationsInvited: {
        $gte: 1
      },
      numberOfConversationsInterested: 0,
      numberOfConversationsScheduling: 0,
      numberOfConversationsAttending: 0
    },
    'Interested': {
      // numberOfConversationsInvited: 0,
      numberOfConversationsInterested: {
        $gte: 1
      },
      numberOfConversationsScheduling: 0,
      numberOfConversationsAttending: 0
    },
    'Scheduling': {
      // numberOfConversationsInvited: 0,
      // numberOfConversationsInterested: 0,
      numberOfConversationsScheduling: {
        $gte: 1
      },
      numberOfConversationsAttending: 0
    },
    'Attending': {
      // numberOfConversationsInvited: 0,
      // numberOfConversationsInterested: 0,
      // numberOfConversationsScheduling: 0,
      numberOfConversationsAttending: {
        $gte: 1
      }
    }
  }

  const related = function(model) {
    return connect('event.find', {
      where: {
        eager: {
          $aggregations: [
            'Invited',
            'Interested',
            'Scheduling',
            'Attending'
          ].map(function(stage) {
            return {
              type: 'count',
              alias: `numberOfConversations${stage}`,
              relation: 'conversations',
              $where: {
                stage: stage
              }
            };
          }),
          $where: {
            activityId: {
              $not: null
            },
            ...stageMap[model.data.name],
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Stage"
      // dropdownLabel="It should be one of the following activities:"
      // dropdownLabel1="It should match"
      // dropdownLabel2="of the following activities:"
      queryUtils={getQueryUtils}
      filterName="stage"
      queryParamName="stage"
      tags={models}
      related={related}
      timestamp={timestamp}
    />
  );
};
