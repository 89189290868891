import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import TagChip from '../../../../components/_chips/TagChip';
import useRouter from '../../../../hooks/useRouter';
import getQueryUtils, { attributeKeys } from '../../queryUtils';

export default function DataSet(props) {
  const { data, param, label, timestamp } = props;

  const { location, history } = useRouter();

  const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);
  const searchParams = queryUtils.params;

  function onChange(datum) {
    const newQuery = queryUtils.insertOrRemoveNumberFromArray(parse(location.search), param, datum.id);

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, newQuery, {
        page: 1
      }))
    });
  }

  return data.filter(function(datum) {
    return searchParams[param].indexOf(datum.id) >= 0;
  }).map(function(datum) {
    return (
      <TagChip
        key={datum.id}
        tag={datum}
        showType={true}
        type={label}
        onClick={onChange}
        onCancel={onChange}
      />
    );
  });
};
