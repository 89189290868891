import React from 'react';
import _ from 'lodash';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { PayloadStates } from '@lore/utils';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useUserTags from '../../../hooks/useUserTags';

export default function Skills(props) {
  const { user } = props;

  const expertiseTypes = useTags(TagTypes.ExpertiseType);
  const userExpertises = useUserTags(user, TagTypes.Expertise);

  // if (
  //   expertiseTypes.state === PayloadStates.FETCHING ||
  //   userExpertises.state === PayloadStates.FETCHING ||
  //   userExpertises.data.length === 0
  // ) {
  //   return null;
  // }

  const groups = _.groupBy(userExpertises.data, function(userExpertise) {
    return userExpertise._parentTag.id;
  });

  return (
    <Section
      title="Skills & Experience"
      // subtitle="I can share my work experience and interests related to the topics below with youth and educators."
      // subdivide={true}
    >
      {_.map(groups, function(value, key) {
        const expertiseType = _.find(expertiseTypes.data, function(expertiseType) {
          return expertiseType.id == key;
        });

        return (
          <div key={key} className="space-y-8 sm:space-y-6">
            {/*<Heading*/}
            {/*  // title={expertiseType ? expertiseType.data.name : '...'}*/}
            {/*  subtitle={expertiseType ? expertiseType.data.name : '...'}*/}
            {/*/>*/}
            <div className="pt-6">
              <div className="flex items-center">
                <h3 className="font-semibold leading-6 text-md text-gray-900">
                  {expertiseType ? expertiseType.data.name : '...'}
                </h3>
              </div>
            </div>
            {value.map(function(userExpertise) {
              return (
                <TextField
                  invert={true}
                  key={userExpertise.id}
                  label={userExpertise._tag.data.name}
                >
                  {userExpertise._tagNode.data.description || '-'}
                </TextField>
              );
            })}
          </div>
        );
      })}
    </Section>
  )
}
