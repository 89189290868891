import React, { useState } from 'react';
import PageHeading from '../_components/PageHeading';
import Table from '../explore-partners/Table';
import SidePanel from './SidePanel';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import ParentLayout from '../explore/Layout';
import Filters from './Filters';
import useRouter from '../../hooks/useRouter';
import SearchTags from './SearchTags';
import initialColumns from './columns';
import Columns from '../explore-partners/Table/Columns';
import getQueryUtils, { attributeKeys } from './queryUtils';
import moment from 'moment-timezone';
import Views from './Views';
import StewardshipCheckbox from './StewardshipCheckbox';
import useStewardshipFilter from './useStewardshipFilter';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();

  const [timestamp] = useState(moment().toISOString());

  const show = useDialog();
  const stewardshipFilter = useStewardshipFilter();

  function onClick(offer) {
    show(
      <SidePanel
        offer={offer}
        primaryAction="recommend_educator"
      />
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Explore']}
          title="Offers"
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        <div className="px-2 py-4 sm:px-3 lg:px-4 space-y-4">
          {/*<div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4">*/}
          {/*  <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide mb-2">*/}
          {/*    Frequently Used Views*/}
          {/*  </h2>*/}
          {/*  <Views timestamp={timestamp}/>*/}
          {/*</div>*/}
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">
            <Filters timestamp={timestamp} />
            <SearchTags timestamp={timestamp} />
          </div>
        </div>
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-between items-center">
              <div className="w-1/2">
                <StewardshipCheckbox />
              </div>
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);

            return getState('offer.find', {
              where: {
                eager: {
                  $where: {
                    $and: [...queryUtils.where],
                    activityId: {
                      $not: null
                    },
                    $or: stewardshipFilter
                  }
                }
              },
              pagination: {
                page: parse(location.search).page || '1',
                pageSize: PAGE_SIZE,
                order: 'createdAt desc'
              }
            });
          }}
          row={function (offer) {
            return (
              <tr key={offer.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(offer)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      offer={offer}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
