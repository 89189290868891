import React from 'react';
import Id from './Columns/Id';
import Project from './Columns/Project';
import Educator from './Columns/Educator';
import School from './Columns/School';
import Assignee from './Columns/Assignee';
import DateWindow from './Columns/DateWindow';
import DateCreated from './Columns/DateCreated';
import Partners from './Columns/Partners';
import Recommendations from './Columns/Recommendations';
import Status from './Columns/Status';
import Resources from './Columns/Resources';
import MissingFields from './Columns/MissingFields';
import FundingRequest from './Columns/FundingRequest';

export default [
  { id: 'id',              name: 'ID',                row: Id,              selected: false },
  { id: 'title',           name: 'Title',             row: Project,         selected: true },
  { id: 'educator',        name: 'Educator',          row: Educator,        selected: true },
  { id: 'school',          name: 'School',            row: School,          selected: false },
  { id: 'date_window',     name: 'Date window',       row: DateWindow,      selected: true },
  { id: 'partners',        name: 'Partners',          row: Partners,        selected: true },
  // { id: 'recommendations', name: 'Recommendations',   row: Recommendations, selected: false },
  { id: 'assignee',        name: 'Assignee',          row: Assignee,        selected: false },
  { id: 'date_created',    name: 'Date created',      row: DateCreated,     selected: false },
  { id: 'status',          name: 'Status',            row: Status,          selected: false },
  { id: 'missing_fields',  name: 'Missing fields',    row: MissingFields,   selected: false },
  { id: 'request',         name: 'Funding requested', row: FundingRequest,  selected: false },
  { id: 'resources',       name: 'Needs resources',   row: Resources,       selected: false },
];
