import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import { onChange, onChangeArray, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils from '../queryUtils';

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const connect = useConnect();

  const { params: searchParams } = getQueryUtils(parse(location.search), [
    'partner',
    'title',
  ], timestamp);

  const params = {
    pagination: {
      page_size: 100,
      ordering: 'name'
    }
  };

  return (
    <div className="space-y-3">
      <Tag
        type="Partner"
        query={searchParams.partner}
        onChange={onChange('partner', { location, history })}
      />
      <Tag
        type="Title"
        query={searchParams.title}
        onChange={onChange('title', { location, history })}
      />
    </div>
  );
};
