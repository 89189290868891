import React from 'react';
import PropTypes from 'prop-types';

export default function HiddenTag(props) {
  const { resource } = props;

  if (resource.data.published) {
    return null;
  }

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
      HIDDEN
    </span>
  );
}
