import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import TagChip from '../../../../components/_chips/TagChip';
import useRouter from '../../../../hooks/useRouter';
import getQueryUtils, { attributeKeys } from '../../queryUtils';

export default function TextSearch(props) {
  const { label, param, type, timestamp } = props;

  const { location, history } = useRouter();

  const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);
  const searchParams = queryUtils.params;

  function onChange(search) {
    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, parse(location.search), {
        [param]: search,
        page: 1
      }))
    });
  }

  if (!searchParams[param]) {
    return null;
  }

  return (
    <TagChip
      tag={{
        data: {
          name: searchParams[param],
          type: type
        }
      }}
      showType={true}
      type={label || type}
      onClick={() => onChange(undefined)}
      onCancel={() => onChange(undefined)}
    />
  );
};
