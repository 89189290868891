import { extractArrayFromQuery } from '../../../utils/query';

const options = [
  'Male',
  'Female',
  'Agender',
  'Gender Fluid',
  'Gender Non-conforming',
  'Genderqueer',
  'Non-binary',
  'Questioning',
  'Self Identify Another Option',
  'Prefer Not to Say'
];

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        gender: {
          $in: values.map(function(value) {
            return options[Number(value) - 1];
          })
        }
      }
    }
  }
}
