import React  from 'react';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Resources(props) {
  const { project } = props;

  return (
    <TextColumn>
      {project.data.resources}
    </TextColumn>
  );
}
