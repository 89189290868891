import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import ReminderDialog from '../../../../dialogs/evaluationProjectPartner/reminder';
import { useUser } from '@lore/auth';

export default function ReminderComplete(props) {
  const { evaluation } = props;

  const show = useDialog();

  function onClick() {
    show(
      <ReminderDialog
        evaluationProjectPartner={evaluation}
      />, { template: 'dialog' }
    );
  }

  return (
    <SecondaryAction onClick={onClick} secret={true}>
      Send reminder to complete
    </SecondaryAction>
  );
}
