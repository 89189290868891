import React from 'react';
import Wrapper from '../../../_components/actions/Actions';
import useAccount from '../../../../hooks/useAccount';
import Invite from './Invite';
import Destroy from './Destroy';
import Visibility from './Visibility';
import Assign from './Assign';
import ReminderInvite from './ReminderInvite';
import ReminderComplete from './ReminderComplete';
import View from './View';

export default function Actions(props) {
  const {
    offer,
    onClose
  } = props;

  const account = useAccount();

  if (offer.data.accountId !== account.id) {
    return (
      <Wrapper>
        <div className="bg-accent w-full py-3 px-4 rounded-md font-medium text-white">
          Offer is outside your stewardship. Actions are disabled.
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Invite offer={offer} onClose={onClose} />
      <View offer={offer} />
      <Visibility offer={offer} onClose={onClose} />
      {/*<Assign offer={offer} onClose={onClose} />*/}
      <Destroy offer={offer} onClose={onClose} />
      <ReminderInvite offer={offer} onClose={onClose} />
      <ReminderComplete offer={offer} onClose={onClose} />
    </Wrapper>
  );
}
