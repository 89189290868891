import React from 'react';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';

export default function Audience(props) {
  const { event } = props;

  const eventParticipants = useEventTags(event, TagTypes.Participant);
  const eventAges = useEventTags(event, TagTypes.Age);
  const eventSubjects = useEventTags(event, TagTypes.Subject);

  // if (
  //   eventParticipants.state === PayloadStates.FETCHING ||
  //   eventAges.state === PayloadStates.FETCHING
  // ) {
  //   return null;
  // }

  const workingWithStudents = _.find(eventParticipants.data, model => model._tag.data.name === 'Students');

  return (
    <Section
      title="Event Audience"
      // subtitle="Learners the activity is designed for."
    >
      <TextField label="Educator/Students">
        {eventParticipants.data.map(function(eventParticipant) {
          return (
            <div key={eventParticipant.id || eventParticipant.cid}>
              {eventParticipant._tag.data.name}
            </div>
          );
        })}
        {eventParticipants.data.length === 0 && '-'}
      </TextField>
      {workingWithStudents && (
        <>
          <TextField label="Students Impacted">
            {event.data.students || '-'}
          </TextField>
          <TextField label="Student Ages">
            {eventAges.data.map(function(eventAge) {
              return (
                <div key={eventAge.id || eventAge.cid}>
                  {eventAge._tag.data.name}
                </div>
              );
            })}
            {eventAges.data.length === 0 && '-'}
          </TextField>
          <TextField label="Group Size">
            {event.data.groupSize || '-'}
          </TextField>
        </>
      )}
      <TextField label="Subject Areas">
        {eventSubjects.data.map(function(eventSubject, index) {
          return (
            <div key={index}>
              {eventSubject._tag.data.name}
            </div>
          );
        })}
        {eventSubjects.data.length === 0 && '-'}
      </TextField>
    </Section>
  )
}
