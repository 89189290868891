import React from 'react';
import moment from 'moment-timezone';
import FeedItem from './FeedItem';
import { useConnect } from '@lore/connect';

export default function Invitation(props) {
  const { invitation } = props;

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  const partner = useConnect('user.byId', {
    id: invitation.data.userId
  });

  return (
    <FeedItem timestamp={moment(invitation.data.createdAt).fromNow()} last={props.last}>
      <span className="font-medium text-gray-900">
        {partner.data.firstName} {partner.data.lastName}
      </span> was invited to the event titled <span className="font-medium text-gray-900">
        {event.data.title || '[missing title]'}
      </span>
    </FeedItem>
  );
}
