import React from 'react';
import PropTypes from 'prop-types';
import { EyeIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import StringField from '../../../forms/_fields/StringField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import FormField from '../../../forms/_common/FormField';
import SelectField from '../../../forms/_fields/SelectField.v2';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    offer
  } = props;

  const connect = useConnect();

  const educator = connect('user.byId', {
    id: offer.data.creatorId
  });

  const activity = offer.data.activityId ? connect('tag.byId', {
    id: offer.data.activityId
  }) : {
    state: PayloadStates.RESOLVED,
    data: {
      name: 'No Title'
    }
  };

  return (
    <FormTemplate
      title="Change offer visibility"
      subtitle={(
        <>
          You are about to change the visibility of the
          offer <strong>{offer.data.title || activity.data.name || '...'}</strong> created
          by <strong>{educator.data.firstName} {educator.data.lastName}</strong>.
        </>
      )}
      icon={EyeIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          {/*<FormField name="confirmation" data={data} errors={showErrors && errors} onChange={onChange}>*/}
          {/*  {(field) => (*/}
          {/*    <StringField*/}
          {/*      label="Confirmation"*/}
          {/*      placeholder="Type PUBLISH..."*/}
          {/*      // description="Some text about a thing I like"*/}
          {/*      value={field.value}*/}
          {/*      error={field.error}*/}
          {/*      onChange={field.onChange}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</FormField>*/}
          <FormField name="published" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <SelectField
                label="Visibility"
                value={field.value === true ? 'Visible' : field.value === false ? 'Hidden' : ''}
                error={field.error}
                placeholder="Select..."
                options={{
                  data: [
                    'Visible',
                    'Hidden'
                  ].map((option) => {
                    return {
                      id: option,
                      data: {
                        name: option
                      }
                    }
                  })
                }}
                optionLabel="name"
                onChange={(value) => {
                  value === 'Visible' ? field.onChange(true) : field.onChange(false)
                }}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <PrimaryButton onClick={onSubmit}>
            Save
          </PrimaryButton>
        </>
      )}
    />
  );
}
