import { extractArrayFromQuery, mapAttributes } from '../../../utils/query';

import educator from './educator';
import partner from './partner';
import responses from './responses';
import title from './title';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagNodes.tagId': {
            $in: values
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  educator,
  partner,
  responses,
  title
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
