import React from 'react';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';

export default function Location(props) {
  const { offer } = props;

  const offerInteractionTypes = useOfferTags(offer, TagTypes.InteractionType);
  const offerLocations = useOfferTags(offer, TagTypes.Location);

  if (
    offerInteractionTypes.state === PayloadStates.FETCHING ||
    offerLocations.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  const isInPerson = _.find(offerInteractionTypes.data, model => model._tag.data.name === 'In-person');

  return (
    <Section
      title="Location"
      subtitle="Virtual, in-person, possible locations."
    >
      <TextField label="Location Type">
        {offerInteractionTypes.data.map(function(offerInteractionType) {
          return (
            <div key={offerInteractionType.id || offerInteractionType.cid}>
              {offerInteractionType._tag.data.name}
            </div>
          );
        })}
      </TextField>
      {isInPerson && offerLocations.data.length > 0 && (
        <TextField label="Possible Locations">
          {offerLocations.data.map(function(offerLocation) {
            return (
              <div key={offerLocation.id || offerLocation.cid}>
                {offerLocation._tag.data.name}
              </div>
            )
          })}
        </TextField>
      )}
    </Section>
  )
}
