import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import DeleteOfferDialog from '../../../../dialogs/offer/Delete';
import { useUser } from '@lore/auth';

export default function Destroy(props) {
  const {
    offer,
    onClose = () => {}
  } = props;

  const show = useDialog();

  function onClick() {
    show(
      <DeleteOfferDialog
        offer={offer}
        onSubmit={() => onClose()}
      />, { template: 'dialog' }
    );
  }

  return (
    <SecondaryAction onClick={onClick} secret={true}>
      Delete offer
    </SecondaryAction>
  );
}
