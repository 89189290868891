import moment from 'moment-timezone';
import TagTypes from '../../constants/TagTypes';

const timestamp = moment().toISOString();

const REQUIRED_FIELDS_PUBLIC = {
  $and: [
    // Must provide activity type and description
    {
      activityId: {
        $exists: true
      },
      description: {
        $not: '',
        $exits: true
      }
    },
    // Date must be provided and in the future
    {
      $or: [
        {
          specificDate: 'Yes',
          date: {
            $gte: timestamp
          }
        },
        {
          specificDate: 'No',
          startDate: {
            $exists: true
          },
          endDate: {
            $gte: timestamp
          }
        }
      ]
    },
    // Must specify a participant
    {
      tags: {
        $tag_type_count_gte: {
          type: TagTypes.Participant,
          value: 1
        }
      }
    },
    // Must specify a method of interaction
    {
      tags: {
        $tag_type_count_gte: {
          type: TagTypes.InteractionType,
          value: 1
        }
      }
    },
    // Must be visible, and not completed or cancelled
    {
      cancelled: false,
      completed: false,
      published: true
    }
  ]
}

export default REQUIRED_FIELDS_PUBLIC;
