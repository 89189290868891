import { mapAttributes } from '../../../utils/query';

import accounts from './accounts';
import educator from './educator';
import evalExperiences from './evalExperiences';
import partner from './partner';
import recommendPartners from './recommendPartners';
import school from './school';
import statuses from './statuses';
import testimonials from './testimonials';
import title from './title';
import trainings from './trainings';

const attributes = {
  accounts,
  educator,
  // evalExperiences,
  // partner,
  recommendPartners,
  school,
  statuses,
  testimonials,
  title,
  trainings
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
