import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useConfig } from '@lore/config';

export default function DialogTemplate(props) {
  const { children } = props;

  let [isOpen, setIsOpen] = useState(false);
  const config = useConfig();
  const modalRef = useRef(null);

  useEffect(() => {
    openModal();
  }, []);

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const { showTransitions } = config.dialogs;

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      afterLeave={props.afterLeave}
      afterEnter={props.afterEnter}
    >
      <Dialog as="div" className="relative z-50" onClose={closeModal} initialFocus={modalRef}>
        <div ref={modalRef} />
        {showTransitions ? (
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
        ) : (
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        )}
        <div className="fixed inset-y-0 right-0 overflow-y-auto">
          {/*<div className="flex min-h-full items-center justify-center p-4">*/}
          {showTransitions ? (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="ease-in duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel>
                {React.cloneElement(children, {
                  closeModal: closeModal,
                  dismiss: closeModal,
                  onCancel: closeModal,
                  onClose: closeModal
                })}
              </Dialog.Panel>
            </Transition.Child>
            ) : (
              <Dialog.Panel>
                {React.cloneElement(children, {
                  closeModal: closeModal,
                  dismiss: closeModal,
                  onCancel: closeModal,
                  onClose: closeModal
                })}
              </Dialog.Panel>
            )}
          {/*</div>*/}
        </div>
      </Dialog>
    </Transition>
  );
}
