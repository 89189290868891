import React from 'react';
import PropTypes from 'prop-types';
import InteractionFilter from './Interaction';
import ProjectFilter from './Project';
import EventFilter from './Event';
import SearchFilter from '../../explore-partners/Filters/_common/SearchFilter';
import JoinedFilter from './Joined';
import TagTypes from '../../../constants/TagTypes';
import TagFilter from './_common/TagFilter';

export default function Filters(props) {
  const { timestamp } = props;

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <TagFilter
            type={TagTypes.Grade}
            label="Grade levels"
            filterName="grade"
            queryParamName="grades"
            timestamp={timestamp}
          />
          <TagFilter
            type={TagTypes.Subject}
            label="Subjects"
            filterName="subject"
            queryParamName="subjects"
            timestamp={timestamp}
          />
          <TagFilter
            type={TagTypes.Practice}
            label="Teaching practices"
            filterName="practice"
            queryParamName="practices"
            timestamp={timestamp}
          />
          {/*<InteractionFilter timestamp={timestamp} />*/}
          <ProjectFilter timestamp={timestamp} />
          <EventFilter timestamp={timestamp} />
          <JoinedFilter timestamp={timestamp} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <SearchFilter
            label="Name"
            param="name"
            placeholder="Enter name..."
            timestamp={timestamp}
          />
          <SearchFilter
            label="Organization"
            placeholder="Enter organization name..."
            param="organization"
            timestamp={timestamp}
          />
          <SearchFilter
            label="Search"
            tooltip="Searches bio and subjects."
            description="Searches bio and subjects."
            placeholder="Enter word or phrase..."
            param="searchEducator"
            timestamp={timestamp}
          />
        </div>
      </div>
    </div>
  );
};
