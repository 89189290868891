import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from '../utils/classNames';
import { SelectorIcon } from '@heroicons/react/solid';
import { useUser } from '@lore/auth';
import avatar from '../../assets/images/avatar.svg';
import PrimaryMenuProfileItems from './_components/PrimaryMenuProfileItems';

export default function Transitions(props) {
  const currentUser = useUser();

  return (
    <Menu as="div" className="px-3 mt-6 relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="group w-full bg-white rounded-md px-3.5 py-2 text-sm text-left font-medium text-orange-700 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-orange-100 focus:ring-orange-500">
              <span className="flex w-full justify-between items-center">
                <span className="flex min-w-0 items-center justify-between space-x-3">
                  <img
                    className="w-10 h-10 bg-orange-300 rounded-full object-cover flex-shrink-0"
                    src={currentUser.data.avatarUrl || avatar}
                    alt=""
                  />
                  <span className="flex-1 flex flex-col min-w-0">
                    <span className="text-orange-600 text-sm font-medium truncate">
                      {`${currentUser.data.firstName} ${currentUser.data.lastName}`.trim() || 'Profile'}
                    </span>
                    <span className="text-orange-400 text-sm truncate">
                      {currentUser.data.email}
                    </span>
                  </span>
                </span>
                <SelectorIcon
                  className="flex-shrink-0 h-5 w-5 text-orange-400 group-hover:text-orange-400"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
            >
              <PrimaryMenuProfileItems />
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
