import React from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';

export default function useTagTrees(props = {}, _connect) {
  const connect = _connect || useConnect();

  return connect('tagTree.find', {
    pagination: {
      pageSize: 1000
    }
  });
}
