import React from 'react';
import moment from 'moment-timezone';
import FeedItem from './FeedItem';
import { useConnect } from '@lore/connect';

export default function Project(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  return (
    <FeedItem timestamp={moment(project.data.createdAt).fromNow()} last={props.last}>
      <span className="font-medium text-gray-900">
        {creator.data.firstName} {creator.data.lastName}
      </span> created a project titled <span className="font-medium text-gray-900">
        {project.data.title || '[missing title]'}
      </span>
    </FeedItem>
  );
}
