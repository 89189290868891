import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import DeclineInvitationDialog from '../../../../dialogs/claim/decline';
import { useUser } from '@lore/auth';

export default function Decline(props) {
  const { claim } = props;

  const show = useDialog();

  function onClick() {
    show(
      <DeclineInvitationDialog
        claim={claim}
      />, { template: 'dialog' }
    );
  }

  return (
    <SecondaryAction onClick={onClick}>
      Decline invitation
    </SecondaryAction>
  );
}
