import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';

export default function Organization(props) {
  const { user } = props;

  const connect = useConnect();

  const roles = connect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  if (roles.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  const role = roles.data[0];

  if (!role) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
        </div>
        <div className="text-sm text-gray-500">
        </div>
      </td>
    );
  }

  const organization = connect('organization.byId', {
    id: role.data.organizationId
  });

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="text-sm text-gray-900 line-clamp-1x">
        {organization.data.name} {roles.data.length > 1 && (
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
            + {roles.data.length - 1}
          </span>
        )}
      </div>
      <div className="text-sm text-gray-500 line-clamp-1x">
        {role.data.title}
      </div>
    </td>
  );
}
