import React  from 'react';
import moment from 'moment-timezone';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';

export default function Date(props) {
  const { evaluation } = props;

  const project = useConnect('project.byId', {
    id: evaluation.data.projectId
  });

  const currentUser = useUser();

  if (project.state === PayloadStates.FETCHING) {
    return (
      <SupportingTextColumn loading={true} />
    );
  }

  const startDate = moment(project.data.startDate).format('MMM Do, YYYY');
  const endDate = moment(project.data.endDate).format('MMM Do, YYYY');
  const startTime = moment(project.data.startTime).tz(currentUser.data.timezone).format('h:mm A ');
  const endTime = moment(project.data.endTime).tz(currentUser.data.timezone).format('h:mm A');

  return (
    <SupportingTextColumn
      title={`${startDate} - ${endDate}`}
      // subtitle={`${startTime} - ${endTime}`}
    />
  );
}
