import React, { useState } from 'react';
import TextColumn from '../../_components/table/columns/TextColumn';
import { useDialog } from '@lore/dialogs';
import useAccount from '../../../hooks/useAccount';
import moment from 'moment-timezone';
import RevokeInvitationDialog from '../../../dialogs/stewardInvitation/destroy';

export default function Revoke(props) {
  const { stewardInvitation } = props;

  const show = useDialog();
  const account = useAccount();
  const [timestamp] = useState(moment().toISOString());

  function onClick() {
    show(
      <RevokeInvitationDialog
        stewardInvitation={stewardInvitation}
      />, { template: 'dialog' }
    );
  }

  return (
    <TextColumn>
      <span className="text-accent underline hover:text-primary cursor-pointer" onClick={onClick}>
        Revoke
      </span>
    </TextColumn>
  );
}
