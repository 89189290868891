import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Social(props) {
  const { user } = props;

  return (
    <Section
      title="Website & Social Media"
      // subtitle="Places where you can learn more about them."
    >
      <TextField label="Website">
        {user.data.website || '-'}
      </TextField>
      <TextField label="LinkedIn">
        {user.data.linkedInUrl || '-'}
      </TextField>
      <TextField label="Twitter">
        {user.data.twitterHandle || '-'}
      </TextField>
    </Section>
  );
}
