import React from 'react';
import moment from 'moment-timezone';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import { PayloadStates } from '@lore/utils';
import EducatorSidePanel from '../../explore-educators/SidePanel';
import PartnerSidePanel from '../../explore-partners/SidePanel';
import ProjectSidePanel from '../../explore-projects/SidePanel';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

function Loader() {
  return (
    <Section>
      <TextField label="Educator">
        ...
      </TextField>
      <TextField label="Partner">
        ...
      </TextField>
      <TextField label="Project">
        ...
      </TextField>
    </Section>
  );
}

export default function People(props) {
  const { project, question } = props;

  const show = useDialog();

  const educator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const partner = useConnect('user.byId', {
    id: question.data.userId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  if (
    activity.state === PayloadStates.FETCHING ||
    educator.state === PayloadStates.FETCHING ||
    partner.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  function onClickEducator() {
    show(
      <EducatorSidePanel user={educator} />
    );
  }

  function onClickPartner() {
    show(
      <PartnerSidePanel user={partner} />
    );
  }

  function onClickProject() {
    show(
      <ProjectSidePanel project={project} />
    );
  }

  return (
    <Section
      // title="Educator information"
      // subtitle="Name and contact info."
    >
      <TextField label="Educator">
        <span onClick={onClickEducator} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {`${educator.data.firstName} ${educator.data.lastName}`.trim() || '[Missing Name]'}
        </span>
      </TextField>
      <TextField label="Partner">
        <span onClick={onClickPartner} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {`${partner.data.firstName} ${partner.data.lastName}`.trim() || '[Missing Name]'}
        </span>
      </TextField>
      <TextField label="Project">
        <span onClick={onClickProject} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {project.data.title || activity.data.name}
        </span>
      </TextField>
      {project.data.specificDate === 'Yes' ? (
        <TextField label="Project Date">
          {project.data.date && moment(project.data.date).format('MMM D, YYYY')}
        </TextField>
      ) : (
        <TextField label="Project Date Window">
          {(project.data.startDate && project.data.endDate) && (
            `${moment(project.data.startDate).format('MMM Do, YYYY')} - ${moment(project.data.endDate).format('MMM Do, YYYY')}`
          )}
        </TextField>
      )}
      <TextField label="Contact initiated by">
        {
          project.data.creatorId === question.data.creatorId ? 'Educator' :
          question.data.userId === question.data.creatorId ? 'Partner' :
          'Steward'
        }
      </TextField>
      <TextField label="Invite sent">
        {moment(question.data.createdAt).format('MMM Do, YYYY')}
      </TextField>
      <TextField label="Response">
        {question.data.response}
      </TextField>
    </Section>
  );
}
