import React from 'react';
import { stringify } from 'query-string';
import { UserAddIcon, SparklesIcon } from '@heroicons/react/outline';
import useRouter from '../../../hooks/useRouter';
import View from '../../explore-educators/Views/View';

export default function Views(props) {
  const { history, location } = useRouter();

  function navigate(search = {}) {
    history.push({
      pathname: location.pathname,
      search: stringify(search)
    })
  }

  return (
    <div className="flex flex-row space-x-2">
      <View
        icon={SparklesIcon}
        onClick={() => navigate({
          // createdDates: ['lte_1_weeks'].join(',')
          createdDates: [1].join(',')
        })}
      >
        New projects
      </View>
      <View
        icon={UserAddIcon}
        onClick={() => navigate({
          active: [1].join(',')
        })}
      >
        Active projects
      </View>
    </div>
  )
}
