import React from 'react';

export default function Metrics(props) {
  const { title, children } = props;

  if (!title) {
    <div className="bg-gray-50x rounded-xl px-2 pb-6 sm:px-3 lg:px-4 space-y-2">
      <dl className="mt-5x grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {children}
      </dl>
    </div>
  }

  return (
    <div className="bg-gray-50x rounded-xl px-2 py-6 sm:px-3 lg:px-4 space-y-5">
      <h3 className="text-2xl leading-6 font-semibold text-gray-900">
        {title}
      </h3>
      <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {children}
      </div>
    </div>
  );
}
