import React from 'react';
import moment from 'moment-timezone';

export function getYear(timestamp) {
  return timestamp.month() > 6 ?
    timestamp.year() :
    timestamp.year() - 1;
}

export default function(account, currentUser) {
  const root = `/c/${account.data.subdomain}`;

  const startingYear = getYear(moment(account.data.createdAt));
  const endingYear = getYear(moment());

  const years = [];

  for (let year = endingYear; year >= startingYear; year--) {
    years.push({
      name: `${year}-${year-1999} School Year`,
      href: `${root}/dashboard/year/${year}`
    })
  }

  years.push({
    name: 'All School Years',
    href: `${root}/dashboard/overview`
  });

  if (currentUser && currentUser.data.isSuperuser) {
    years.push({
      name: '[ Feed ]',
      href: `${root}/dashboard/feed`
    });
  }

  return years;
};
