import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { PayloadStates } from '@lore/utils';
import useUserTags from '../../../hooks/useUserTags';
import TagTypes from '../../../constants/TagTypes';

export default function Interests(props) {
  const { user } = props;

  const userActivities = useUserTags(user, TagTypes.Activity);
  const userAges = useUserTags(user, TagTypes.Age);
  const userGroups = useUserTags(user, TagTypes.Group);

  // if (
  //   userActivities.state === PayloadStates.FETCHING ||
  //   userAges.state === PayloadStates.FETCHING ||
  //   userGroups.state === PayloadStates.FETCHING ||
  //   (
  //     userActivities.data.length === 0 &&
  //     userAges.data.length === 0 &&
  //     userGroups.data.length === 0
  //   )
  // ) {
  //   return null;
  // }

  return (
    <Section
      title="Engagement Preferences"
      subtitle="I'm especially interested in volunteering with..."
    >
      <TextField label="Activities">
        {userActivities.data.map(function(userActivity) {
          return (
            <div key={userActivity.id}>
              {userActivity._tag.data.name || '...'}
            </div>
          );
        })}
        {userActivities.data.length === 0 && '-'}
      </TextField>
      <TextField label="Age Groups">
        {userAges.data.map(function(userAge) {
          return (
            <div key={userAge.id}>
              {userAge._tag.data.name || '...'}
            </div>
          )
        })}
        {userAges.data.length === 0 && '-'}
      </TextField>
      <TextField label="Student Groups">
        {userGroups.data.map(function(userGroup) {
          return (
            <div key={userGroup.id}>
              {userGroup._tag.data.name || '...'}
            </div>
          )
        })}
        {userGroups.data.length === 0 && '-'}
      </TextField>
    </Section>
  );
}
