import React, { useState } from 'react';
import _ from 'lodash';
import PageHeading from '../_components/PageHeading';
import Table from './Table';
import SidePanel from './SidePanel';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import ParentLayout from '../explore/Layout';
import Filters from './Filters';
import useRouter from '../../hooks/useRouter';
import SearchTags from './SearchTags';
import initialColumns from './columns';
import Columns from './Table/Columns';
import getQueryUtils, { attributeKeys } from './queryUtils';
import Views from './Views';
import moment from 'moment-timezone';
import { useConnect } from '@lore/connect';
import DownloadCsvDialog from '../../dialogs/csv/download';
import StewardshipCheckbox from './StewardshipCheckbox';
import useStewardshipFilter from './useStewardshipFilter';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();

  const [timestamp] = useState(moment().toISOString());

  const show = useDialog();
  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter(true);

  function onClick(user) {
    show(
      <SidePanel
        user={user}
        primaryAction="recommend_project"
        secondaryAction="sleep_profile"
      />
    );
  }

  function onDownload(query) {
    show(
      <DownloadCsvDialog
        modelName="csvUser"
        query={query}
      />, { template: 'dialog' }
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Explore']}
          title="Partners"
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        <div className="px-2 py-4 sm:px-3 lg:px-4 space-y-4">
          {/*<div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4">*/}
          {/*  <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide mb-2">*/}
          {/*    Frequently Used Views*/}
          {/*  </h2>*/}
          {/*  <Views timestamp={timestamp}/>*/}
          {/*</div>*/}
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">
            <Filters timestamp={timestamp} />
            <SearchTags timestamp={timestamp} />
          </div>
        </div>
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-between items-center">
              <div className="w-1/2">
                <StewardshipCheckbox />
              </div>
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);

            return getState('user.find', {
              where: {
                eager: {
                  $aggregations: [
                    // {
                    //   type: 'count',
                    //   alias: 'numberOfPartnerInteractions',
                    //   relation: 'partnerInteractions'
                    // },
                    {
                      type: 'sum',
                      alias: 'numberOfHoursServed',
                      field: 'hours',
                      relation: 'partnerProjectEvaluations'
                    },
                    // {
                    //   type: 'sum',
                    //   alias: 'numberOfStudentsServed',
                    //   field: 'served',
                    //   relation: 'partnerInteractions.evaluations'
                    // },
                    {
                      type: 'sum',
                      alias: 'totalOfContributionsProvided',
                      field: 'contributionValue',
                      relation: 'partnerProjectEvaluations'
                    }
                  ],
                  $where: {
                    $and: [...queryUtils.where],
                    isEducator: false,
                    isHidden: false,
                    $or: stewardshipFilter
                  }
                }
              },
              pagination: {
                page: parse(location.search).page || '1',
                pageSize: PAGE_SIZE,
                order: 'createdAt desc'
              }
            });
          }}
          csvQuery={function() {
            const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);

            return onDownload({
              where: {
                eager: {
                  $aggregations: [
                    // {
                    //   type: 'count',
                    //   alias: 'numberOfPartnerInteractions',
                    //   relation: 'partnerInteractions'
                    // },
                    {
                      type: 'sum',
                      alias: 'numberOfHoursServed',
                      field: 'hours',
                      relation: 'partnerProjectEvaluations'
                    },
                    // {
                    //   type: 'sum',
                    //   alias: 'numberOfStudentsServed',
                    //   field: 'served',
                    //   relation: 'partnerInteractions.evaluations'
                    // },
                    {
                      type: 'sum',
                      alias: 'totalOfContributionsProvided',
                      field: 'contributionValue',
                      relation: 'partnerProjectEvaluations'
                    }
                  ],
                  $where: {
                    $and: [...queryUtils.where],
                    isEducator: false,
                    isHidden: false,
                    $or: stewardshipFilter
                  }
                }
              },
              pagination: {
                page: parse(location.search).page || '1',
                pageSize: PAGE_SIZE,
                order: 'createdAt desc'
              }
            });
          }}
          row={function(user) {
            return (
              <tr key={user.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(user)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      user={user}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
