import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';
import useStewardshipFilter from '../explore-projects/useStewardshipFilter';
import REQUIRED_FIELDS_PUBLIC from '../explore-projects/REQUIRED_FIELDS_PUBLIC';
import { PayloadStates } from '@lore/utils';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  const [timestamp] = useState(moment().toISOString());
  const [expireTimestamp] = useState(moment().add(21, 'days').toISOString());
  const stewardshipFilter = useStewardshipFilter();

  return connect('project.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfQuestionsPending',
            relation: 'questions',
            $where: {
              response: 'Pending'
            }
          }
        ],
        $where: {
          $and: REQUIRED_FIELDS_PUBLIC.$and,
          // numberOfQuestionsPending: {
          //   $lte: 0
          // },
          endDate: {
            $gte: timestamp,
            $lte: expireTimestamp
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    },
    exclude: function(model) {
      return model.state === PayloadStates.DELETED;
    }
  });
}
