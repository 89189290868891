import React  from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import logo from '../../../assets/images/logo.png';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import RemoveLoadingScreen from '../RemoveLoadingScreen';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

export default function Layout(props) {
  const { history } = props;

  return (
    <>
      <RemoveLoadingScreen />
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                <ExclamationIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  You do not have permission to access this application.
                </h3>
                <div className="mt-2 space-y-4">
                  <p className="text-sm text-gray-500">
                    If you believe this is a mistake, it's possible that you are logged in using the
                    wrong account. In that case, please try logging out and logging back in.
                  </p>
                  <Link
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:text-sm"
                    to="/logout"
                  >
                    Logout
                  </Link>
                </div>
                <div className="mt-12">
                  <p className="text-sm text-gray-500">
                    If that does not fix the issue, or you believe this is an error, please
                    contact <a className="underline text-accent" href="mailto:support@communityshare.org">support@communityshare.org</a>.
                  </p>
                </div>
              </div>
            </div>
            {/*<div className="mt-5 sm:mt-6">*/}
            {/*  <Link*/}
            {/*    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:text-sm"*/}
            {/*    to="/explore"*/}
            {/*  >*/}
            {/*    Go to home page*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </>
  );
}
