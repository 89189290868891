import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import useStewardshipFilter from '../useStewardshipFilter';

AgeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function AgeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const ages = connect('guidance.find', {
    pagination: {
      pageSize: 100,
      order: 'name asc'
    }
  });

  const users = function(guidance) {
    return connect('offer.find', {
      where: {
        eager: {
          $where: {
            "id": {
              $includes_any: {
                "offerGuidances.guidanceId": [guidance.id]
              }
            },
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Professional competencies"
      dropdownLabel1="It should be looking to provide guidance around"
      dropdownLabel2="of the following topics:"
      queryUtils={getQueryUtils}
      filterName="guidance"
      queryParamName="guidances"
      tags={ages}
      related={users}
    />
  );
};
