import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useRequest } from '@lore/request';
import { useUser } from '@lore/auth';
import validators from '../../utils/validators';
import Overlay from '../_common/Overlay';
import FormErrors from '../_common/FormErrors';
import RequestError from '../_common/RequestError';
import ErrorMessage from '../_common/ErrorMessage';
import SuccessMessage from '../_common/SuccessMessage';
import Form from './Form';

Wrapper.propTypes = {
  extraParams: PropTypes.object.isRequired
};

export default function Wrapper(props) {
  const { extraParams } = props;

  const currentUser = useUser();

  const [data, setData] = useState({
    // conversationId: conversation.id,
    ...extraParams,
    creatorId: currentUser.id,
    text: ''
  });
  const [showErrors, setShowErrors] = useState(false);
  const [_request, setRequest] = useState(null);
  const [finished, setFinished] = useState(false);

  const { request, saving, success, error } = useRequest('note.', _request);

  useEffect(() => {
    if (success && !finished) {
      setFinished(true);
      props.onClose && props.onClose();
      props.onSubmit && props.onSubmit(request);
    }
  });

  function onSubmit() {
    setRequest(
      lore.actions.note.create(data).payload
    );
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  return (
    <div className="px-6 py-4 bg-gray-50 mt-6">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          text: [validators.isRequired]
        }}>
          {({errors, hasError}) => {
            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onCancel={props.onClose}
                alert={error ? (
                  <RequestError request={request}/>
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : (false && success) ? (
                  <SuccessMessage>
                    Idea created.
                  </SuccessMessage>
                ) : null}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
