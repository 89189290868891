import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';
import HiddenTag from '../../_components/HiddenTag';
import CancelledTag from '../../_components/CancelledTag';
import CompletedTag from '../../_components/CompletedTag';

export default function Project(props) {
  const { evaluation } = props;

  const connect = useConnect();

  const project = connect('project.byId', {
    id: evaluation.data.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <SupportingTextColumn loading={true} />
    );
  }

  const activity = connect('tag.byId', {
    id: project.data.activityId
  });

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="text-sm text-gray-900 line-clamp-1">
        {project.data.title || activity.data.name}
      </div>
      <div className="text-sm text-gray-500">
        {activity.data.name} <HiddenTag resource={project}/> <CancelledTag resource={project}/> <CompletedTag resource={project}/>
      </div>
    </td>
  );
}
