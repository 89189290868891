import React from 'react';
import PropTypes from 'prop-types';
import Response from './Response';
import Event from './Event';
import Partner from './Partner';
import Educator from './Educator';
import Community from './Community';
import useRouter from '../../../hooks/useRouter';

Filters.propTypes = {
  // timestamp: PropTypes.string.isRequired
};

export default function Filters(props) {
  const { location, history } = useRouter();

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <Response />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <Event />
          <Partner />
          <Educator />
        </div>
      </div>
    </div>
  );
};
