import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import StringField from '../../../forms/_fields/StringField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import FormField from '../../../forms/_common/FormField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    offer
  } = props;

  const connect = useConnect();

  const educator = connect('user.byId', {
    id: offer.data.creatorId
  });

  const activity = offer.data.activityId ? connect('tag.byId', {
    id: offer.data.activityId
  }) : {
    state: PayloadStates.RESOLVED,
    data: {
      name: 'No Title'
    }
  };

  return (
    <FormTemplate
      title="Delete offer"
      subtitle={(
        <>
          <span className="block mb-4">
            You are about to delete the
            offer <strong>{offer.data.title || activity.data.name || '...'}</strong> created
            by <strong>{educator.data.firstName} {educator.data.lastName}</strong>.
          </span>
          <span className="block mb-4">
            If you are sure you want to do this, type <strong>DELETE</strong> into the field below.
          </span>
        </>
      )}
      icon={ClockIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          <FormField name="confirmation" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Confirmation"
                placeholder="Type DELETE..."
                // description="Some text about a thing I like"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <PrimaryButton onClick={onSubmit}>
            Delete offer
          </PrimaryButton>
        </>
      )}
    />
  );
}
