import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';

RecommendEducator.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function RecommendEducator(props) {
  const { location, history } = props;

  const connect = useConnect();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      {
        id: 1,
        data: {
          name: 'Yes'
        }
      },
      {
        id: 2,
        data: {
          name: 'No'
        }
      }
    ]
  };

  const users = function(model) {
    return connect('evaluationEventPartner.find', {
      where: {
        eager: {
          $where: {
            recommend: model.data.name
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Recommends educator"
      dropdownLabel="The training will be:"
      // dropdownLabel1="It should match"
      // dropdownLabel2="of the following activities:"
      queryUtils={getQueryUtils}
      filterName="recommendEducator"
      queryParamName="recommendEducators"
      tags={models}
      related={users}
    />
  );
};
