import { extractArrayFromQuery, mapAttributes } from '../../../utils/query';

import accounts from './accounts';
import contributions from './contributions';
import educator from './educator';
import partner from './partner';
import recommendEducators from './recommendEducators';
import recommendPlatforms from './recommendPlatforms';
import school from './school';
import statuses from './statuses';
import title from './title';
import trainings from './trainings';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagNodes.tagId': {
            $in: values
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  accounts,
  contributions,
  educator,
  partner,
  recommendEducators,
  recommendPlatforms,
  school,
  statuses,
  title,
  trainings
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
