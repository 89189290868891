import React  from 'react';
import { useUser } from '@lore/auth';
import { getDistanceFromLatLonInMiles } from '../../../../../utils/distance';
import TextColumn from '../TextColumn';

export default function Distance(props) {
  const { user } = props;

  const currentUser = useUser();

  const distance = getDistanceFromLatLonInMiles(user.data, currentUser.data);

  return (
    <TextColumn>
      {distance <= 1 ?
        '1 mile away' :
        `${distance} miles away`
      }
    </TextColumn>
  );
}
