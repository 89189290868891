import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Social(props) {
  const { user } = props;

  return (
    <Section
      title="About Me"
      // subtitle="Contact info and demographics."
    >
      <TextField label="About">
        {user.data.biography || '-'}
      </TextField>
    </Section>
  );
}
