import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import { onChange, onChangeArray, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils, { attributeKeys } from '../queryUtils';
import PayloadStates from '../../../constants/PayloadStates';

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const connect = useConnect();

  const { params: searchParams } = getQueryUtils(parse(location.search), attributeKeys, timestamp);

  const params = {
    pagination: {
      page_size: 100,
      ordering: 'name'
    }
  };

  return (
    <div className="space-y-3">
      {/*<Tags*/}
      {/*  type="Community"*/}
      {/*  query={searchParams.accounts}*/}
      {/*  models={connect('account.find', params)}*/}
      {/*  onChange={onChangeArray('accounts', { location, history })}*/}
      {/*  label={tag => tag.data.subdomain}*/}
      {/*/>*/}
      <Tag
        type="Educator"
        query={searchParams.educator}
        onChange={onChange('educator', { location, history })}
      />
      {/*<Tags*/}
      {/*  type="Experience"*/}
      {/*  query={searchParams.evalExperiences}*/}
      {/*  models={mapArray([*/}
      {/*    'Poor',*/}
      {/*    'Satisfactory',*/}
      {/*    'Good',*/}
      {/*    'Great'*/}
      {/*  ])}*/}
      {/*  onChange={onChangeArray('evalExperiences', { location, history })}*/}
      {/*/>*/}
      {/*<Tag*/}
      {/*  type="Partner"*/}
      {/*  query={searchParams.partner}*/}
      {/*  onChange={onChange('partner', { location, history })}*/}
      {/*/>*/}
      {/*<Tags*/}
      {/*  type="Recommend partner"*/}
      {/*  query={searchParams.recommendPartners}*/}
      {/*  models={mapArray([*/}
      {/*    'Yes',*/}
      {/*    'No'*/}
      {/*  ])}*/}
      {/*  onChange={onChangeArray('recommendPartners', { location, history })}*/}
      {/*/>*/}
      <Tag
        type="School"
        query={searchParams.school}
        onChange={onChange('school', { location, history })}
      />
      <Tags
        type="Status"
        query={searchParams.statuses}
        models={mapArray([
          'Complete',
          'Pending'
        ])}
        onChange={onChangeArray('statuses', { location, history })}
      />
      <Tags
        type="Testimonial"
        query={searchParams.testimonials}
        models={mapArray([
          'Yes',
          'No'
        ])}
        onChange={onChangeArray('testimonials', { location, history })}
      />
      <Tag
        type="Title"
        query={searchParams.title}
        onChange={onChange('title', { location, history })}
      />
      {/*<Tags*/}
      {/*  type="Training"*/}
      {/*  query={searchParams.trainings}*/}
      {/*  models={mapArray([*/}
      {/*    'Yes',*/}
      {/*    'No'*/}
      {/*  ])}*/}
      {/*  onChange={onChangeArray('trainings', { location, history })}*/}
      {/*/>*/}
    </div>
  );
};
