import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import { ClockIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import MessageDeliveryStatus from '../../../components/MessageDeliveryStatus';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import avatar from '../../../../assets/images/avatar.svg';
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid';
import Loader from '../../../components/Loader';
import UserAvatar from '../../../components/UserAvatar';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    onBack,
    offer,
    user
  } = props;

  const currentUser = useUser();
  const [timestamp] = useState(moment().toISOString());

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          userId: user.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    sortBy: function(model) {
      return moment(model.data.createdAt).unix();
    }
  });

  const title = (
    <>
      Invite {user.data.firstName || 'Educator'} to this offer?
    </>
  );

  const subtitle = (
    <span className="space-y-2">
      <span className="block">
        Confirming will send {user.data.firstName || 'Educator'} an email inviting them to the
        offer <span className="font-semibold">{offer.data.title}</span>.
      </span>
      <span className="block">
        This invitation will appear to come from the partner, and the invitation and resulting
        experience will be identical to if it had.
      </span>
    </span>
  );

  if (claims.state === PayloadStates.FETCHING) {
    return (
      <FormTemplate
        title={title}
        subtitle={subtitle}
        showIcon={false}
        icon={ClockIcon}
        onClose={onCancel}
        alert={alert}
        body={(
          <div className="mt-4">
            <Loader />
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onBack}>
              Back
            </OutlineButton>
            <PrimaryButton onClick={onSubmit} disabled={true}>
              Send invitation
            </PrimaryButton>
          </>
        )}
      />
    );
  }

  if (claims.data.length > 0) {
    return (
      <FormTemplate
        title={title}
        subtitle={subtitle}
        showIcon={false}
        icon={ClockIcon}
        onClose={onCancel}
        alert={alert}
        body={(
          <div className="mt-4">
            {claims.data.length > 0 && (
              // <div className="text-sm text-gray-700 mb-8">
                <div className="text-sm text-gray-700 bg-orange-50 border border-accent shadow p-4 rounded my-6">
                  {claims.data.map(function (claim) {
                    return (
                      <div key={claim.id || claim.cid}>
                        Invitation was already sent on {moment(claim.data.createdAt).tz(currentUser.data.timezone).format('MMM Do YYYY, hh:mm a')}
                        {claim.data.sendgridMessageId && (
                          <div className="text-sm uppercase font-bold text-accent ml-2x">
                            <MessageDeliveryStatus message={claim} />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              // </div>
            )}
            <p className="text-sm text-gray-700 mb-6">
              This partner has already been invited to this offer. Would you like to select a different offer?
            </p>
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onCancel}>
              Close
            </OutlineButton>
            <PrimaryButton onClick={onBack}>
              Select different offer
            </PrimaryButton>
          </>
        )}
      />
    );
  }

  return (
    <FormTemplate
      title={title}
      subtitle={subtitle}
      showIcon={false}
      icon={ClockIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="mt-4 space-y-6">
          <div className="block bg-white shadow rounded-lg">
            <div className="flex items-center px-4 py-4 sm:px-6">
              <div className="min-w-0 flex-1 flex items-center">
                <div className="flex-shrink-0">
                  <UserAvatar large={true} user={user} />
                </div>
                <div className="min-w-0 flex-1 px-4 ">
                  <div>
                    <p className="text-sm font-medium text-indigo-600x truncate">
                      {user.data.firstName} {user.data.lastName}
                    </p>
                    <p className="mt-1 flex items-center text-sm text-gray-500">
                      <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span className="truncate">
                        {user.data.email}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<p className="text-sm text-gray-700 mb-6">*/}
          {/*  Would you like to include a custom message about why you're sending this invitation?*/}
          {/*</p>*/}
          {/*<FormField name="message" data={data} errors={showErrors && errors} onChange={onChange}>*/}
          {/*  {(field) => (*/}
          {/*    <TextAreaField*/}
          {/*      label="Custom Message"*/}
          {/*      placeholder="Enter message..."*/}
          {/*      rows={11}*/}
          {/*      value={field.value}*/}
          {/*      error={field.error}*/}
          {/*      onChange={field.onChange}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</FormField>*/}
        </div>
      )}
      footer={(
        <>
          <OutlineButton onClick={onBack}>
            Back
          </OutlineButton>
          <PrimaryButton onClick={onSubmit}>
            Send invitation
          </PrimaryButton>
        </>
      )}
    />
  );
}
