import React from 'react';
import Metrics from '../Metrics';
import TotalYear from './TotalYear';
import AssociatedWithProject from './AssociatedWithProject';
import AssociatedWithEvent from './AssociatedWithEvent';
import AssociatedWithProjectOrEvent from './AssociatedWithProjectOrEvent';

export default function Schools(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Schools">
        <TotalYear {...params} />
        <AssociatedWithProject {...params} />
        <AssociatedWithEvent {...params} />
        <AssociatedWithProjectOrEvent {...params} />
      </Metrics>
    </>
  );
}
