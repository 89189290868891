import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import Summary from './Summary';
import Partner from './Partner';
import Organizations from './Organizations';
import Logistics from './Logistics';
import Location from './Location';
import Subjects from './Subjects';
import Competencies from './Competencies';
import Audience from './Audience';
import Flexibility from './Flexibility';
import Website from './Website';
import Resources from './Resources';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import Claims from './Claims';
import Bookmark from '../../_components/Bookmark';

export default function SidePanel(props) {
  const { offer } = props;

  const [tabs, setTabs] = useState([
    { id: 'details', name: 'Details', selected: true },
    { id: 'claims', name: 'Invitations', selected: false },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  })

  return (
    <Panel title="Offer" onClose={props.onClose}>
      <div>
        <Header
          bookmark={(
            <Bookmark
              type="offer"
              resource={offer}
            />
          )}
          src={activity.data.imageUrl}
          title={offer.data.title || activity.data.name}
          subtitle={activity.data.name}
        >
          <Actions
            offer={offer}
            onClose={props.onClose}
          />
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'details' && (
          <div className="divide-y divide-gray-200">
            <Summary offer={offer} />
            <Audience offer={offer} />
            <Partner offer={offer} />
            <Logistics offer={offer} />
            <Resources offer={offer} />
            <Website offer={offer} />
            {/*<Organizations offer={offer} />*/}
            {/*<Location offer={offer} />*/}
            {/*<Subjects offer={offer} />*/}
            {/*<Competencies offer={offer} />*/}
            {/*<Flexibility offer={offer} />*/}
          </div>
        )}
        {selectedTab.id === 'claims' && (
          <div className="mb-6x">
            <Claims offer={offer} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <Notes offer={offer} />
          </div>
        )}
      </div>
    </Panel>
  )
}
