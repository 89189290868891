import React from 'react';
import Metrics from '../Metrics';
import ImpactProjectsYear from './ImpactProjectsYear';
import ImpactEventsYear from './ImpactEventsYear';
import ProjectEngagedResponseYear from './ProjectEngagedResponseYear';
import ProjectEvalResponseYear from './ProjectEvalResponseYear';

export default function Students(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Student Impact (Projects)">
        {/*<ProjectEngagedResponseYear {...params} />*/}
        <ImpactProjectsYear {...params} />
      </Metrics>
      <Metrics title="Project Impact on Students (as rated by educators)">
        <ProjectEvalResponseYear
          {...params}
          title={["Students were", "more engaged"]}
          field="engagedRating"
        />
        <ProjectEvalResponseYear
          {...params}
          title={["Students built", "social-emotional skills"]}
          field="skillsRating"
        />
        <ProjectEvalResponseYear
          {...params}
          title={["Students increased awareness","of future academic and","career opportunities"]}
          field="awarenessRating"
        />
        <ProjectEvalResponseYear
          {...params}
          title={["Students increased understanding", "of the real-world application", "of topic/content"]}
          field="understandingRating"
        />
        <ProjectEvalResponseYear
          {...params}
          title={["Students practiced", "critical thinking, problem-solving,","and/or working as a team"]}
          field="practicedRating"
        />
        {/*<ImpactProjectsYear {...params} />*/}
        {/*<ImpactEventsYear {...params} />*/}
      </Metrics>
    </>
  );
}
