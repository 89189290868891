import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { PayloadStates } from '@lore/utils';
import useUserTags from '../../../hooks/useUserTags';
import TagTypes from '../../../constants/TagTypes';

export default function Teaching(props) {
  const { user } = props;

  const userGrades = useUserTags(user, TagTypes.Grade);
  const userSubjects = useUserTags(user, TagTypes.Subject);
  const userPractices = useUserTags(user, TagTypes.Practice);

  return (
    <Section
      title="Teaching"
      // subtitle="I teach the following subject areas."
    >
      <TextField label="Grade Levels">
        {userGrades.data.map(function(userGrade, index) {
          return (
            <div key={index}>
              {userGrade._tag.data.name}
            </div>
          );
        })}
        {userGrades.data.length === 0 && '-'}
      </TextField>
      <TextField label="Subjects Taught">
        {userSubjects.data.map(function(userSubject, index) {
          return (
            <div key={index}>
              {userSubject._tag.data.name}
            </div>
          );
        })}
        {userSubjects.data.length === 0 && '-'}
      </TextField>
      <TextField label="Teaching Practices">
        {userPractices.data.map(function(userPractice, index) {
          return (
            <div key={index}>
              {userPractice._tag.data.name}
            </div>
          );
        })}
        {userPractices.data.length === 0 && '-'}
      </TextField>
    </Section>
  )
}
