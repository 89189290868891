import React  from 'react';
import moment from 'moment-timezone';
import TextColumn from '../TextColumn';

export default function LastActive(props) {
  const { user } = props;

  return (
    <TextColumn>
      {/*{moment(user.data.lastActiveAt).format('MMM D, YYYY')}*/}
      {moment(user.data.lastActiveAt).fromNow()}
    </TextColumn>
  );
}
