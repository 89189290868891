import React, { useState } from 'react';
import _ from 'lodash';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import Chart from '../../dashboard-year/Chart';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import moment from 'moment-timezone';
import useStewardshipFilter from '../../explore-projects/useStewardshipFilter';
import REQUIRED_FIELDS from '../../explore-projects/REQUIRED_FIELDS';
import MISSING_FIELDS from '../../explore-projects/MISSING_FIELDS';

export default function GrowthByMonth(props) {
  const { timestamps, empty, schoolStart } = props;

  const stewardshipFilter = useStewardshipFilter();

  const [currentTimestamp] = useState(moment().toISOString());

  const connect = useConnect();

  const partners = timestamps.map(function(timestamp) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          all: empty(),
          draft: empty(),
          published: empty(),
          new: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        all: connect('project.find', {
          where: {
            eager: {
              $where: {
                activityId: {
                  $not: null
                },
                createdAt: {
                  $lte: timestamp.end,
                  $gte: timestamp.schoolStart
                },
                $or: stewardshipFilter
                // ...accountQuery
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        draft: connect('project.find', {
          where: {
            eager: {
              $where: {
                published: false,
                activityId: {
                  $not: null
                },
                $and: {
                  $or: {
                    ...MISSING_FIELDS
                  }
                },
                createdAt: {
                  $lte: timestamp.end,
                  $gte: schoolStart
                },
                $or: stewardshipFilter
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        published: connect('project.find', {
          where: {
            eager: {
              $where: {
                published: true,
                activityId: {
                  $not: null
                },
                $and: REQUIRED_FIELDS.$and,
                createdAt: {
                  $lte: timestamp.end,
                  $gte: schoolStart
                },
                $or: stewardshipFilter
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        new: connect('project.find', {
          where: {
            eager: {
              $where: {
                activityId: {
                  $not: null
                },
                createdAt: {
                  $gte: timestamp.start,
                  $lte: timestamp.end
                },
                $or: stewardshipFilter
                // ...accountQuery
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        })
      }
    }
  });

  const loading = _.reduce(partners, function(result, partner) {
    return (
      result ||
      partner.results.all.state === PayloadStates.FETCHING ||
      partner.results.draft.state === PayloadStates.FETCHING ||
      partner.results.published.state === PayloadStates.FETCHING ||
      partner.results.new.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <Chart name="Projects" className="col-span-3">
        <Loader />
      </Chart>
    );
  }

  const data = {
    series: [
      {
        name: 'Total',
        data: partners.map(e => e.results.all.meta.totalCount)
      },
      {
        name: 'Draft',
        data: partners.map(e => e.results.draft.meta.totalCount)
      },
      {
        name: 'Published',
        data: partners.map(e => e.results.published.meta.totalCount)
      },
      {
        name: 'New',
        data: partners.map(e => e.results.new.meta.totalCount)
      }
    ],
    options: {
      title: {
        text: 'Project growth',
        align: 'center'
      },
      subtitle: {
        text: 'Over school year',
        align: 'center'
      },
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: partners.map(e => e.label)
      },
      yaxis: {
        title: {
          text: 'Count'
        }
      },
      fill: {
        opacity: 1
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands"
      //     }
      //   }
      // }
    }
  };

  return (
    <Chart
      // name="Project growth"
      className="col-span-3"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={350}
      />
    </Chart>
  );
}
