import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import { StarIcon  } from '@heroicons/react/outline';
import { StarIcon as SolidStarIcon } from '@heroicons/react/solid';

Bookmark.propTypes = {
  type: PropTypes.oneOf([
    'user',
    'project',
    'event',
    'offer',
  ]).isRequired
};

export default function Bookmark(props) {
  const { type, resource } = props;

  const currentUser = useUser();

  const bookmarks = useConnect('star.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          [`${type}Id`]: resource.id
        }
      }
    }
  });

  const storeState = lore.store.getState();
  const bookmark = _.find(storeState.star.byCid, function(bookmark) {
    return (
      bookmark.data.creatorId === currentUser.id &&
      bookmark.data[`${type}Id`] === resource.id &&
      bookmark.state !== PayloadStates.DELETED
    );
  }) || {
    state: PayloadStates.NOT_FOUND
  };

  function onCreateBookmark() {
    lore.actions.star.create({
      creatorId: currentUser.id,
      [`${type}Id`]: resource.id
    });
  }

  function onDestroyBookmark() {
    lore.actions.star.destroy(bookmark);
  }

  if (bookmark.state === PayloadStates.FETCHING) {
    return (
      <StarIcon
        className="h-6 w-6 text-gray-400"
      />
    );
  }

  if (
    bookmark.state === PayloadStates.NOT_FOUND ||
    bookmark.state === PayloadStates.DELETED
  ) {
    return (
      <StarIcon
        className="h-6 w-6 text-accent cursor-pointer"
        onClick={onCreateBookmark}
      />
    );
  }

  return (
    <SolidStarIcon
      className="h-6 w-6 text-accent cursor-pointer"
      onClick={onDestroyBookmark}
    />
  );
}
