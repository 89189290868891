import React from 'react';
import Sidebar from '../_components/Sidebar';
import SearchHeader from '../_components/SearchHeader';
import navigationLinks from './navigationLinks';
import { useUser } from '@lore/auth';
import useAccount from '../../hooks/useAccount';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const currentUser = useUser();
  const account = useAccount();

  const root = `/c/${account.data.subdomain}`;

  const _navigationLinks = [
    { name: 'Staff', href: `${root}/team/staff` }
  ];

  if (currentUser.data.isSuperuser) {
    _navigationLinks.push({
      name: '[ Invitations ]', href: `${root}/team/invitations`
    });
  }

  return (
    <>
      <Sidebar
        title="Team"
        navigation={currentUser.data.isSuperuser ? _navigationLinks : navigationLinks(account)}
        sidebarOpen={sidebarOpen}
      />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <SearchHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        {props.children || (
          <h3 className="font-bold text-xl text-gray-900 sm:text-2xl px-5 py-4">
            Placeholder: Team
          </h3>
        )}
      </div>
    </>
  );
}
