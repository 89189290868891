import React from 'react';
import Id from '../explore-questions/Table/Id';
import Project from '../explore-questions/Table/Project';
import Response from '../explore-questions/Table/Response';
import Days from '../explore-questions/Table/Days';
import Educator from '../explore-questions/Table/Educator';
import Partner from '../explore-questions/Table/Partner';
import Action from './Table/Action';

export default [
  { id: 'id',       name: 'ID',               row: Id,       selected: false },
  { id: 'partner',  name: 'Partner',          row: Partner,  selected: true },
  { id: 'educator', name: 'Educator',         row: Educator, selected: true },
  { id: 'project',  name: 'Project',          row: Project,  selected: true },
  { id: 'status',   name: 'Status',           row: Response,   selected: true },
  { id: 'days',     name: 'Invite sent',      row: Days,     selected: true },
  { id: 'action',   name: 'Suggested action', row: Action,   selected: true }
];
