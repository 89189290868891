import React from 'react';
import { stringify } from 'query-string';
import _ from 'lodash';
import { UserAddIcon, BanIcon, ShareIcon } from '@heroicons/react/outline';
import useRouter from '../../../hooks/useRouter';
import View from './View';

export default function Views(props) {
  const { history, location, timestamp } = useRouter();

  function navigate(search = {}) {
    history.push({
      pathname: location.pathname,
      search: stringify(search)
    })
  }

  return (
    <div className="flex flex-row space-x-2">
      <View
        icon={UserAddIcon}
        onClick={() => navigate({
          // createdDates: ['lte_1_weeks'].join(',')
          createdDates: [1].join(',')
        })}
      >
        New educators
      </View>
      <View
        icon={BanIcon}
        onClick={() => navigate({
          projects: [1].join(',')
        })}
      >
        No projects
      </View>
      {/*<View*/}
      {/*  icon={ShareIcon}*/}
      {/*  onClick={() => navigate({*/}
      {/*    educatorInteractions: [4].join(',')*/}
      {/*  })}*/}
      {/*>*/}
      {/*  3+ interactions*/}
      {/*</View>*/}
    </div>
  )
}
