import React from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import useTags from './useTags';

export default function useProjectTags(project, type, timestamp, _connect) {
  const tags = useTags();

  const connect = _connect || useConnect();

  const extraArgs = timestamp ? {
    createdAt: {
      $lte: timestamp
    }
  } : {};

  const tagNodes = connect('tagNode.find', {
    where: {
      eager: {
        $where: {
          'tag.type': type,
          projectId: project.id,
          ...extraArgs,
        }
      }
    },
    pagination: {
      pageSize: 1000
    },
    include: function(tagNode) {
      const tag = _.find(tags.data, tag => tag.id === tagNode.data.tagId);

      return (
        tagNode.data.projectId === project.id &&
        tag.data.type === type
      );
    },
    exclude: function(tagNode) {
      return tagNode.state === PayloadStates.DELETED;
    }
  });

  return {
    state: tagNodes.state,
    data: tagNodes.data.map(function(tagNode) {
      const tag = _.find(tags.data, tag => tag.id === tagNode.data.tagId);

      return {
        id: tagNode.id,
        cid: tagNode.cid,
        data: tag.data,
        _tag: tag,
        _tagNode: tagNode,
        _meta: 'generated in useProjectTags',
      }
    })
  }
}
