import React from 'react';
import Metrics from '../Metrics';
import EngagementByMonth from './EngagementByMonth';
import SharedProject from './SharedProject';
import SharedEvent from './SharedEvent';
import TotalYear from './TotalYear';
import TotalYearComplete from './TotalYearComplete';
import InvitedToOffer from './InvitedToOffer';
import InvitedToOfferAndResponded from './InvitedToOfferAndResponded';
import CreatedInviteToOffer from './CreatedInviteToOffer';

export default function Educators(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Educators">
        {/*<Chart {...params} />*/}
        <EngagementByMonth {...params} />
      </Metrics>
      <Metrics>
        <TotalYear {...params} />
        <TotalYearComplete {...params} />
        <SharedProject {...params} />
        <SharedEvent {...params} />

        <InvitedToOffer {...params} />
        <InvitedToOfferAndResponded {...params} />
        <CreatedInviteToOffer {...params} />
      </Metrics>
    </>
  );
}
