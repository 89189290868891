import React from 'react';
import { useConnect } from '@lore/connect';
import useRouter from '../../hooks/useRouter';

// Offers
import starredOffers from '../explore-offers-starred/useQuery';
import newOffers from '../explore-offers-new/useQuery';
import activeOffers from '../explore-offers-active/useQuery';
import draftOffers from '../explore-offers-draft/useQuery';
import schoolYearOffers from '../explore-offers-school-year/useQuery';

// Educators
import invitationsEducators from '../explore-educators-invitations/useQuery';
import useAccount from '../../hooks/useAccount';

export default function getNavigationLinks() {
  const { location } = useRouter();
  const connect = useConnect();
  const account = useAccount();

  function getCount(query) {
    const result = query({ location, connect });
    return result.meta ? result.meta.totalCount : '...';
  }

  const root = `/c/${account.data.subdomain}`;

  return [
    { name: 'Starred', href: `${root}/explore/offers/starred`, count: getCount(starredOffers) },
    { name: 'New', href: `${root}/explore/offers/new`, count: getCount(newOffers), highlight: true },
    { name: 'Pending Offer Invitations', href: `${root}/explore/offers/invitations`, count: getCount(invitationsEducators) },
    { name: 'Active Offers', href: `${root}/explore/offers/active`, count: getCount(activeOffers) },
    // { name: 'Draft', href: `${root}/explore/offers/draft`, count: getCount(draftOffers) },
    { name: 'All Offers this School Year', href: `${root}/explore/offers/school-year`, count: getCount(schoolYearOffers) },
    { name: 'All', href: `${root}/explore/offers` }
  ];
}
