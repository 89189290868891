import React, { useState } from 'react';
import PageHeading from '../_components/PageHeading';
import Table from '../team-staff/Table';
import InviteStewardDialog from '../../dialogs/user/invite.steward';
import PartnerSidePanel from '../explore-partners/SidePanel';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import ParentLayout from '../team/Layout';
import useRouter from '../../hooks/useRouter';
import initialColumns from './columns';
import useAccount from '../../hooks/useAccount';
import { PayloadStates } from '@lore/utils';
import SecondaryAction from '../_components/actions/SecondaryAction';
import moment from 'moment-timezone';


export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();

  const show = useDialog();
  const account = useAccount();
  const [timestamp] = useState(moment().toISOString());

  function onClick() {
    show(
      <InviteStewardDialog />,
      { template: 'dialog' }
    );
  }

  const PAGE_SIZE = 1000;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected);

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Team']}
          title="Invitations"
          description="When someone signs up with the email listed, they will automatically be granted steward access."
        >
          <div>
            <SecondaryAction onClick={onClick} secret={true}>
              Invite Steward
            </SecondaryAction>
          </div>
        </PageHeading>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            return getState('stewardInvitation.find', {
              where: {
                eager: {
                  $where: {
                    accountId: account.id,
                    createdAt: {
                      $lte: timestamp
                    }
                  }
                }
              },
              pagination: {
                pageSize: PAGE_SIZE,
                order: 'createdAt desc'
              },
              include: function(model) {
                return (
                  model.data.accountId === account.id &&
                  model.state === PayloadStates.RESOLVED
                );
              },
              exclude: function(model) {
                return model.state === PayloadStates.DELETED;
              }
            });
          }}
          row={function (stewardInvitation) {
            return (
              <tr key={stewardInvitation.id}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      stewardInvitation={stewardInvitation}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
