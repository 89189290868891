import { extractArrayFromQuery } from '../../../utils/query';

export default {
  param: extractArrayFromQuery,
  query: function (values) {
    if (values.length > 0) {
      return {
        response: {
          $or: values.map(function(value) {
            return {
              1: 'Pending',
              2: 'Connect',
              3: 'Decline'
            }[value]
          })
        }
      }
    }
  }
}
