import React, { useState } from 'react';
import _ from 'lodash';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import Chart from '../Chart';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import moment from 'moment-timezone';
import { REQUIRED_EDUCATOR_FIELDS } from '../../../utils/user/REQUIRED_FIELDS';
import useStewardshipFilter from '../../explore-educators/useStewardshipFilter';

export default function EngagementByMonth(props) {
  const { account, timestamps, empty, schoolStart } = props;

  const stewardshipFilter = useStewardshipFilter();

  const [currentTimestamp] = useState(moment().toISOString());

  const connect = useConnect();

  const educators = timestamps.map(function(timestamp, index) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          // all: empty(),
          // complete: empty(),
          // new: empty(),
          createdProject: empty(),
          createdEvent: empty(),
          connectAboutOffer: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        // all: connect('user.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         isEducator: true,
        //         createdAt: {
        //           $lte: timestamp.end,
        //           $gte: schoolStart
        //         },
        //         $or: stewardshipFilter
        //         // ...accountQuery
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        // complete: connect('user.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         isEducator: true,
        //         createdAt: {
        //           $lte: timestamp.end,
        //           $gte: schoolStart
        //         },
        //         ...REQUIRED_EDUCATOR_FIELDS,
        //         $and: {
        //           $or: stewardshipFilter
        //         }
        //         // ...accountQuery
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        // new: connect('user.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         isEducator: true,
        //         createdAt: {
        //           $gte: timestamp.start,
        //           $lte: timestamp.end
        //         },
        //         $or: stewardshipFilter
        //         // ...accountQuery
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        createdProject: connect('user.find', {
          where: {
            eager: {
              $aggregations: _.remove([
                {
                  type: 'count',
                  alias: 'numberOfProjects',
                  relation: 'projects',
                  $where: {
                    createdAt: {
                      $gte: timestamp.start,
                      $lte: timestamp.end
                    },
                    activityId: {
                      $not: null
                    }
                  }
                }
              ]),
              $where: {
                numberOfProjects: {
                  $gte: 1
                },
                isEducator: true,
                $or: stewardshipFilter
              }
            },
          },
          pagination: {
            pageSize: 1
          }
        }),
        createdEvent: connect('user.find', {
          where: {
            eager: {
              $aggregations: _.remove([
                {
                  type: 'count',
                  alias: 'numberOfEvents',
                  relation: 'events',
                  $where: {
                    createdAt: {
                      $gte: timestamp.start,
                      $lte: timestamp.end
                    },
                    activityId: {
                      $not: null
                    }
                  }
                }
              ]),
              $where: {
                numberOfEvents: {
                  $gte: 1
                },
                isEducator: true,
                $or: stewardshipFilter
              }
            },
          },
          pagination: {
            pageSize: 1
          }
        }),
        // connectAboutOffer: connect('claim.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         createdAt: {
        //           $gte: timestamp.start,
        //           $lte: timestamp.end
        //         },
        //         response: 'Connect',
        //         $or: stewardshipFilter
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        connectAboutOffer: connect('user.find', {
          where: {
            eager: {
              $aggregations: _.remove([
                {
                  type: 'count',
                  alias: 'numberOfClaims',
                  relation: 'claims',
                  $where: {
                    createdAt: {
                      $gte: timestamp.start,
                      $lte: timestamp.end
                    },
                    response: 'Connect'
                  }
                }
              ]),
              $where: {
                numberOfClaims: {
                  $gte: 1
                },
                isEducator: true,
                $or: stewardshipFilter
              }
            },
          },
          pagination: {
            pageSize: 1
          }
        })
      }
    }
  });

  const loading = _.reduce(educators, function(result, educator) {
    return (
      result ||
      // educator.results.all.state === PayloadStates.FETCHING ||
      // educator.results.complete.state === PayloadStates.FETCHING ||
      // educator.results.new.state === PayloadStates.FETCHING ||
      educator.results.createdProject.state === PayloadStates.FETCHING ||
      educator.results.createdEvent.state === PayloadStates.FETCHING ||
      educator.results.connectAboutOffer.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <Chart name="Educator Engagement" className="col-span-3">
        <Loader />
      </Chart>
    );
  }

  const data = {
    series: [
      // {
      //   name: 'Total',
      //   data: educators.map(e => e.results.all.meta.totalCount)
      // },
      // {
      //   name: 'Complete profile',
      //   data: educators.map(e => e.results.complete.meta.totalCount)
      // },
      // {
      //   name: 'New',
      //   data: educators.map(e => e.results.new.meta.totalCount)
      // },
      {
        name: 'Created Project',
        data: educators.map(e => e.results.createdProject.meta.totalCount)
      },
      {
        name: 'Created Event',
        data: educators.map(e => e.results.createdEvent.meta.totalCount)
      },
      {
        name: 'Connected about Offer',
        data: educators.map(e => e.results.connectAboutOffer.meta.totalCount)
      }
    ],
    options: {
      title: {
        text: 'Educator Engagement',
        align: 'center'
      },
      subtitle: {
        text: 'Over school year',
        align: 'center'
      },
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: educators.map(e => e.label)
      },
      yaxis: {
        title: {
          text: 'Count'
        }
      },
      fill: {
        opacity: 1
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands"
      //     }
      //   }
      // }
    }
  };

  return (
    <Chart
      // name="Educator growth"
      className="col-span-3"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={350}
      />
    </Chart>
  );
}
