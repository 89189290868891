import React  from 'react';
import User from './User';
import { useConnect } from '@lore/connect';
import PayloadStates from '../../../constants/PayloadStates';

export default function Educator(props) {
  const { invitation } = props;

  const connect = useConnect();

  const event = connect('event.byId', {
    id: invitation.data.eventId
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <User user={{
        state: PayloadStates.FETCHING
      }} />
    );
  }

  const user = connect('user.byId', {
    id: event.data.creatorId
  });

  return (
    <User user={user} />
  );
}
