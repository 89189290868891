import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import Metric from '../../dashboard-year/Metric';
import { useConnect } from '@lore/connect';
import { REQUIRED_EDUCATOR_FIELDS } from '../../../utils/user/REQUIRED_FIELDS';

export default function TotalComplete(props) {
  const { account, schoolStart, schoolEnd } = props;

  const users = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          isEducator: true,
          // createdAt: {
          //   $lte: schoolEnd,
          //   $gte: schoolStart
          // },
          $and: {
            ...REQUIRED_EDUCATOR_FIELDS
          }
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <Metric
      name="Total (complete)"
      stat={users.meta ? users.meta.totalCount : '...'}
      icon={UsersIcon}
    />
  );
}
