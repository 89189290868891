import React from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Resources(props) {
  const { event } = props;

  const eventUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id
        }
      }
    }
  });

  return (
    <Section
      title="More Information"
      // subtitle="Documents uploaded by the educator with more information about the event."
    >
      <TextField label="Uploaded Attachments">
        {eventUploads.data.map(function(eventUpload) {
          return (
            <a
              key={eventUpload.id || eventUpload.cid}
              href={eventUpload.data.url}
              target="_blank"
            >
              {eventUpload.data.name}
            </a>
          );
        })}
        {eventUploads.data.length === 0 && '-'}
      </TextField>
    </Section>
  )
}
