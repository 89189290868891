import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
// import SelectField from '../../../forms/_fields/SelectField';
import SelectField from '../../../forms/_fields/UserSelectField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import FormTemplate from '../../_common/FormTemplate.vertical';
import FormField from '../../../forms/_common/FormField';
import Loader from '../../../components/Loader';
import useAccount from '../../../hooks/useAccount';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  const account = useAccount();

  const users = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          'members.isStaff': true,
          'members.isHidden': false,
          'members.account.subdomain': account.data.subdomain
        }
      }
    },
    pagination: {
      order: 'firstName asc'
    }
  });

  if (users.state === PayloadStates.FETCHING) {
    return (
      <FormTemplate
        title="Assign Event"
        subtitle="Select the person you want to assign this event to."
        icon={ClockIcon}
        onClose={onCancel}
        alert={alert}
        body={(
          <div className="text-center py-8">
            <Loader />
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onCancel}>
              Cancel
            </OutlineButton>
            <PrimaryButton onClick={onSubmit}>
              Assign
            </PrimaryButton>
          </>
        )}
      />
    );
  }

  return (
    <FormTemplate
      title="Assign Event"
      subtitle="Select the person you want to assign this event to."
      icon={ClockIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          <FormField name="assigneeId" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <SelectField
                label="Assignee"
                value={field.value}
                error={field.error}
                placeholder="Select a person..."
                options={users}
                optionLabel="firstName"
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <PrimaryButton onClick={onSubmit}>
            Assign
          </PrimaryButton>
        </>
      )}
    />
  );
}
