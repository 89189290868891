import React from 'react';
import moment from 'moment-timezone';
import PayloadStates from '../../constants/PayloadStates';
import useRouter from '../../hooks/useRouter';
import useAccount from '../../hooks/useAccount';
import PageHeading from '../_components/PageHeading';
import ParentLayout from '../dashboard/Layout';
import Students from './Students';
import Educators from './Educators';
import Partners from './Partners';
import Projects from './Projects';
import Events from './Events';
import Offers from './Offers';
import Schools from './Schools';
import Organizations from './Organizations';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, match } = useRouter();

  const account = useAccount();

  const year = Number(match.params.year); // 2021

  const start = moment(`${year}-07-01T12:00:00.000-00:00`).toISOString();
  const end = moment(`${year + 1}-07-01T12:00:00.000-00:00`).toISOString();

  const timestamps = [0,1,2,3,4,5,6,7,8,9,10,11].map(function(value) {
    // const timestamps = [0].map(function(value) {
    return {
      schoolStart: start,
      schoolEnd: end,
      start: moment(start).add(value, 'months').toISOString(),
      end: moment(start).add(value + 1, 'months').toISOString(),
      label: moment(start).add(value, 'months').format('MMM')
    }
  });

  function empty() {
    return {
      state: PayloadStates.RESOLVED,
      data: [],
      meta: {
        totalCount: 0
      }
    }
  }

  const params = {
    account,
    schoolStart: start,
    schoolEnd: end,
    timestamps,
    empty
  }

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Dashboard']}
          title={`${year}-${year-1999} School Year`}
        >
        </PageHeading>
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        <div className="px-2 py-4 sm:px-3 lg:px-4">
          <Students params={params} />
          <Educators params={params} />
          <Partners params={params} />
          <Projects params={params} />
          <Events params={params} />
          <Offers params={params} />
          <Schools params={params} />
          <Organizations params={params} />

          {/*<Metrics title="Evaluations">*/}
          {/*  <EducatorEvaluations {...params} />*/}
          {/*  <PartnerEvaluations {...params} />*/}
          {/*  <StudentsServed {...params} />*/}
          {/*  <RecommendPlatform {...params} />*/}
          {/*</Metrics>*/}
        </div>
      </main>
    </ParentLayout>
  );
}
