import React  from 'react';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';
import useUserTags from '../../../../../hooks/useUserTags';
import TagTypes from '../../../../../constants/TagTypes';

export default function Group(props) {
  const { user } = props;

  const userGroups = useUserTags(user, TagTypes.Group);

  if (userGroups.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  return (
    <TextColumn>
      {userGroups.data.map(function(userGroup) {
        return userGroup._tag.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
