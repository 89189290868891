import React  from 'react';
import { useConnect } from '@lore/connect';
import HiddenTag from '../../_components/HiddenTag';
import CancelledTag from '../../_components/CancelledTag';
import CompletedTag from '../../_components/CompletedTag';

export default function Offer(props) {
  const { offer } = props;

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });

  const account = useConnect('account.byId', {
    id: offer.data.accountId
  });

  const tag = false && (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
      {account.data.subdomain}
    </span>
  );

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="text-sm text-gray-900">
        {offer.data.title || activity.data.name} {tag}
      </div>
      <div className="text-sm text-gray-500">
        {activity.data.name} <HiddenTag resource={offer}/> <CancelledTag resource={offer}/> <CompletedTag resource={offer}/>
      </div>
    </td>
  );
}
