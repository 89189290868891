import React from 'react';
import Metrics from '../Metrics';
import ActivityByMonth from './ActivityByMonth';
import EventActivityYear from './EventActivityYear';
import EventActivityYearTreemap from './EventActivityYear.treemap';
import TotalYear from './TotalYear';
import EventStatusYear from './EventStatusYear';
import StudentsYear from './StudentsYear';

export default function Events(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Events">
        <ActivityByMonth {...params} />
        {/*<EventActivityYear {...params} />*/}
        <EventActivityYearTreemap {...params} />
        <EventStatusYear {...params} />
      </Metrics>
      <Metrics>
        <TotalYear {...params} />
        <StudentsYear {...params} />
      </Metrics>
    </>
  );
}
