import React  from 'react';
import User from './User';
import { useConnect } from '@lore/connect';
import PayloadStates from '../../../constants/PayloadStates';

export default function Partner(props) {
  const { claim } = props;

  const connect = useConnect();

  const offer = connect('offer.byId', {
    id: claim.data.offerId
  });

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <User user={{
        state: PayloadStates.FETCHING
      }} />
    );
  }

  const user = connect('user.byId', {
    id: offer.data.creatorId
  });

  return (
    <User user={user} />
  );
}
