import React from 'react';
import { useConnect } from '@lore/connect';
import useRouter from '../../hooks/useRouter';

// Projects
import starredProjects from '../explore-projects-starred/useQuery';
import expiringProjects from '../explore-projects-expiring/useQuery';
import expiredProjects from '../explore-projects-expired/useQuery';
import draftProjects from '../explore-projects-draft/useQuery';
import newProjects from '../explore-projects-new/useQuery';
import activeProjects from '../explore-projects-active/useQuery';
import partnersInvitedProjects from '../explore-projects-partners-invited/useQuery';
import partnersConnectedProjects from '../explore-projects-partners-connected/useQuery';
import wantsContactProjects from '../explore-projects-wants-contact/useQuery';
import resourcesNeededProjects from '../explore-projects-resources-needed/useQuery';
import fundingRequestedProjects from '../explore-projects-funding-requested/useQuery';
import expiredNotClosedProjects from '../explore-projects-expired-not-closed/useQuery';
import missingEducatorEvaluationProjects from '../explore-projects-missing-educator-evaluation/useQuery';
import completedProjects from '../explore-projects-completed/useQuery';
import schoolYearProjects from '../explore-projects-school-year/useQuery';

// Events
import starredEvents from '../explore-events-starred/useQuery';
import newEvents from '../explore-events-new/useQuery';
import draftEvents from '../explore-events-draft/useQuery';
import partnersEvents from '../explore-events-partners/useQuery';
import expiringEvents from '../explore-events-expiring/useQuery';
import happeningSoonEvents from '../explore-events-soon/useQuery';
import recentlyCompletedEvents from '../explore-events-recently-completed/useQuery';
import expiredNotClosedEvents from '../explore-events-expired-not-closed/useQuery';
import missingEducatorEvaluationEvents from '../explore-events-missing-educator-evaluation/useQuery';
import completedEvents from '../explore-events-completed/useQuery';
import schoolYearEvents from '../explore-events-school-year/useQuery';

// Offers
import starredOffers from '../explore-offers-starred/useQuery';
import newOffers from '../explore-offers-new/useQuery';
import activeOffers from '../explore-offers-active/useQuery';
import draftOffers from '../explore-offers-draft/useQuery';
import schoolYearOffers from '../explore-offers-school-year/useQuery';

// Partners
import starredPartners from '../explore-partners-starred/useQuery';
import incompletePartners from '../explore-partners-incomplete/useQuery';
import questionsPartners from '../explore-partners-questions/useQuery';
import invitationsPartners from '../explore-partners-invitations/useQuery';
import unresponsivePartners from '../explore-partners-unresponsive/useQuery';
import evaluationsPartners from '../explore-partner-evaluations-pending/useQuery';
import newPartners from '../explore-partners-new/useQuery';
import noInteractionsPartners from '../explore-partners-no-interactions/useQuery';
import multipleEvaluationsPartners from '../explore-partners-multiple-evaluations/useQuery';
import multipleHoursPartners from '../explore-partners-multiple-hours/useQuery';
import multipleStudentsPartners from '../explore-partners-multiple-students/useQuery';

// Educators
import starredEducators from '../explore-educators-starred/useQuery';
import incompleteEducators from '../explore-educators-incomplete/useQuery';
import invitationsEducators from '../explore-educators-invitations/useQuery';
import unresponsiveEducators from '../explore-educators-unresponsive/useQuery';
import evaluationsEducators from '../explore-educator-evaluations-pending/useQuery';
import newEducators from '../explore-educators-new/useQuery';
import noneCreatedEducators from '../explore-educators-none-created/useQuery';
import multipleCompletedEducators from '../explore-educators-multiple-completed/useQuery';
import useAccount from '../../hooks/useAccount';

export default function getNavigationLinks(key) {
  const { location } = useRouter();
  const connect = useConnect();
  const account = useAccount();

  const root = `/c/${account.data.subdomain}`;

  function getCount(query) {
    const result = query({ location, connect });
    return result.meta ? result.meta.totalCount : '...';
  }

  return [
    {
      title: 'Projects',
      links: [
        { name: 'Starred', href: `${root}/explore/projects/starred`, count: getCount(starredProjects) },
        { name: 'New', href: `${root}/projects/new`, count: getCount(newProjects) },
        { name: 'Incomplete', href: `${root}/projects/draft`, count: getCount(draftProjects) },
        { name: 'Wants Contact', href: `${root}/projects/wants-contact`, count: getCount(wantsContactProjects) },
        { name: 'No Partners Invited', href: `${root}/projects/partners-invited`, count: getCount(partnersInvitedProjects) },
        { name: 'No Partners Connected', href: `${root}/projects/partners-connected`, count: getCount(partnersConnectedProjects) },
        { name: 'Pending Invitations', href: `${root}/partners/questions`, count: getCount(questionsPartners) },
        { name: 'Active Projects', href: `${root}/projects/active`, count: getCount(activeProjects) },
        { name: 'Needs Resources', href: `${root}/projects/resources-needed`, count: getCount(resourcesNeededProjects) },
        { name: 'Requested Funding', href: `${root}/projects/funding-requested`, count: getCount(fundingRequestedProjects) },
        { name: 'Expiring', href: `${root}/projects/expiring`, count: getCount(expiringProjects) },
        // { name: 'Recently Expired', href: `${root}/projects/expired`, count: getCount(expiredProjects) },
        { name: 'Expired but not Closed', href: `${root}/projects/expired-not-closed`, count: getCount(expiredNotClosedProjects) },
        { name: 'Missing Educator Evaluation', href: `${root}/projects/missing-educator-evaluation`, count: getCount(missingEducatorEvaluationProjects) },
        { name: 'Completed', href: `${root}/projects/completed`, count: getCount(completedProjects) },
        { name: 'All Projects this School Year', href: `${root}/projects/school-year`, count: getCount(schoolYearProjects) },
        { name: 'All', href: `${root}/projects` }
      ]
    },
    {
      title: 'Events',
      links: [
        { name: 'Starred', href: `${root}/events/starred`, count: getCount(starredEvents) },
        { name: 'New', href: `${root}/events/new`, count: getCount(newEvents) },
        { name: 'Incomplete', href: `${root}/events/draft`, count: getCount(draftEvents) },
        { name: 'Needs partners', href: `${root}/events/partners`, count: getCount(partnersEvents) },
        { name: 'Pending Invitations', href: `${root}/partners/invitations`, count: getCount(invitationsPartners) },
        // { name: 'Expiring', href: `${root}/events/expiring`, count: getCount(expiringEvents) },
        { name: 'Happening Soon', href: `${root}/events/soon`, count: getCount(happeningSoonEvents) },
        // { name: 'Recently Completed', href: `${root}/events/recently-completed`, count: getCount(recentlyCompletedEvents) },
        { name: 'Expired but not Closed', href: `${root}/events/expired-not-closed`, count: getCount(expiredNotClosedEvents) },
        { name: 'Missing Educator Evaluation', href: `${root}/events/missing-educator-evaluation`, count: getCount(missingEducatorEvaluationEvents) },
        { name: 'Completed', href: `${root}/events/completed`, count: getCount(completedEvents) },
        { name: 'All Events this School Year', href: `${root}/events/school-year`, count: getCount(schoolYearEvents) },
        { name: 'All', href: `${root}/events` }
      ]
    },
    {
      title: 'Offers',
      links: [
        { name: 'Starred', href: `${root}/offers/starred`, count: getCount(starredOffers) },
        { name: 'New', href: `${root}/offers/new`, count: getCount(newOffers) },
        { name: 'Pending Offer Invitations', href: `${root}/educators/invitations`, count: getCount(invitationsEducators) },
        { name: 'Active Offers', href: `${root}/offers/active`, count: getCount(activeOffers) },
        // { name: 'Draft', href: `${root}/offers/draft`, count: getCount(draftOffers) },
        { name: 'All Offers this School Year', href: `${root}/offers/school-year`, count: getCount(schoolYearOffers) },
        { name: 'All', href: `${root}/offers` }
      ]
    },
    // {
    //   title: 'Project Invitations',
    //   links: [
    //     { name: 'All', href: `${root}/questions` },
    //     { name: 'Pending', href: `${root}/partners/questions`, count: getCount(questionsPartners) },
    //   ]
    // },
    // {
    //   title: 'Event Invitations',
    //   links: [
    //     { name: 'All', href: `${root}/invitations` },
    //     { name: 'Pending', href: `${root}/partners/invitations`, count: getCount(invitationsPartners) }
    //   ]
    // },
    // {
    //   title: 'Offer Invitations',
    //   links: [
    //     { name: 'All', href: `${root}/claims` },
    //     { name: 'Pending', href: `${root}/educators/invitations`, count: getCount(invitationsEducators) }
    //   ]
    // },
    {
      title: 'Partners',
      links: [
        { name: 'Starred', href: `${root}/partners/starred`, count: getCount(starredPartners) },
        { name: 'New', href: `${root}/partners/new`, count: getCount(newPartners) },
        { name: 'Incomplete Profile', href: `${root}/partners/incomplete`, count: getCount(incompletePartners) },
        { name: 'No Interactions', href: `${root}/partners/no-interactions`, count: getCount(noInteractionsPartners) },
        { name: '3+ Evaluations', href: `${root}/partners/multiple-evaluations`, count: getCount(multipleEvaluationsPartners) },
        { name: '25+ Hours Served', href: `${root}/partners/multiple-hours`, count: getCount(multipleHoursPartners) },
        { name: '50+ Students Served', href: `${root}/partners/multiple-students`, count: getCount(multipleStudentsPartners) },
        // { name: 'Pending Evaluations', href: `${root}/partners/evaluations`, count: getCount(evaluationsPartners) },
        // { name: 'Unresponsive', href: `${root}/partners/unresponsive`, count: getCount(unresponsivePartners) },
        { name: 'All', href: `${root}/partners` }
      ]
    },
    {
      title: 'Educators',
      links: [
        { name: 'Starred', href: `${root}/educators/starred`, count: getCount(starredEducators) },
        { name: 'New', href: `${root}/educators/new`, count: getCount(newEducators) },
        { name: 'Incomplete Profile', href: `${root}/educators/incomplete`, count: getCount(incompleteEducators) },
        { name: 'No Requests', href: `${root}/educators/none-created`, count: getCount(noneCreatedEducators) },
        { name: '3+ Completed', href: `${root}/educators/multiple-completed`, count: getCount(multipleCompletedEducators) },
        // { name: 'Pending Evaluations', href: `${root}/educators/evaluations`, count: getCount(evaluationsEducators) },
        // { name: 'Unresponsive', href: `${root}/educators/unresponsive`, count: getCount(unresponsiveEducators) },
        { name: 'All', href: `${root}/educators` }
      ]
    }
  ];

  const linksMap = {
    projects: 0,
    events: 1,
    offers: 2,
    partners: 3,
    educators: 4
  };
}
