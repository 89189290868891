/**
 * This component is intended to reflect the high level structure of your application,
 * and render any components that are common across all views, such as the header or
 * top-level navigation. All other components should be rendered by route handlers.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryNavigation from '../_components/PrimaryNavigation';
import moment from 'moment';
import { useUser } from '@lore/auth';
import { useConfig } from '@lore/config';
import SearchHeader from '../_components/SearchHeader';
import LayoutExploreProjects from './LayoutExploreProjects';
import LayoutExploreEvents from './LayoutExploreEvents';
import LayoutExploreOffers from './LayoutExploreOffers';
import LayoutExplorePartners from './LayoutExplorePartners';
import LayoutExploreEducators from './LayoutExploreEducators';
import Header from './Header';
import useMember from '../../hooks/useMember';

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default function Layout(props) {
  const { location, history, children } = props;

  const user = useUser();
  const member = useMember();
  const config = useConfig();

  // const navigationLinks = getNavigationLinks_projects();

  const key = location.pathname.split('/')[4];

  const ParentLayout = {
    projects: LayoutExploreProjects,
    events: LayoutExploreEvents,
    offers: LayoutExploreOffers,
    partners: LayoutExplorePartners,
    educators: LayoutExploreEducators,
  }[key];

  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const { enabled, appId, companyId, companyName } = config.intercom;
    const createdAt = moment(user.data.createdAt).unix();

    if (enabled) {
      window.Intercom("boot", {
        app_id: appId,
        name: `${user.data.firstName} ${user.data.lastName}`,
        email: user.data.email,
        created_at: createdAt,
        company: {
          id: companyId,
          name: companyName
        }
      });
    }

    history.listen(function () {
      window.Intercom("update");
    });
  }, []);

  useEffect(() => {
    const { enabled } = config.fullstory;

    if (enabled) {
      window.FS.setUserVars({
        uid: user.id,
        displayName: `${user.data.firstName} ${user.data.lastName}`,
        email: user.data.email,
      });
    }
  }, []);

  useEffect(() => {
    if (!member.data.isStaff) {
      history.push('/unauthorized');
    }
  }, []);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <PrimaryNavigation sidebarOpen={sidebarOpen} />
      <div className="h-screen flex-col flex-1 overflow-hidden bg-white">
        <SearchHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <Header />
        {ParentLayout ? (
          <ParentLayout>
            {props.children}
          </ParentLayout>
        ) : props.children}
      </div>
    </div>
  );
}
