import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid'
import avatar from '../../../../assets/images/avatar.svg';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import UserAvatar from '../../../components/UserAvatar';
import useAccount from '../../../hooks/useAccount';
import REQUIRED_FIELDS_PUBLIC from '../../../pages/explore-offers/REQUIRED_FIELDS_PUBLIC';
import moment from 'moment-timezone';

export default function Users(props) {
  const { name = '', title = '', onClick } = props;

  const title_words = title.split(' ');
  const name_words = name.split(' ');

  const account = useAccount();
  const connect = useConnect();
  const [timestamp] = useState(moment().toISOString());

  const offers = useConnect('offer.find', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          $and: [
            ...REQUIRED_FIELDS_PUBLIC.$and,
            {
              $or: title_words.map(function(word){
                return {
                  title: {
                    $ilike: `%${word}%`
                  }
                };
              })
              // .concat(title_words.map(function(word){
              //   return {
              //     'activity.name': {
              //       $ilike: `%${word}%`
              //     }
              //   };
              // }))
            },
            {
              $or: name_words.map(function(word){
                return {
                  'creator.firstName': {
                    $ilike: `%${word}%`
                  }
                };
              }).concat(name_words.map(function(word){
                return {
                  'creator.lastName': {
                    $ilike: `%${word}%`
                  }
                };
              }))
            }
          ],
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 5,
      order: 'createdAt desc'
    }
  })

  if (offers.state === PayloadStates.FETCHING) {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {[1,2,3,4,5].map((offer, index) => (
            <li key={index}>
              <div className="block hover:bg-gray-50">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="flex-shrink-0">
                      <img className="h-12 w-12 rounded-full object-cover" src={avatar} alt="Avatar" />
                    </div>
                    <div className="min-w-0 flex-1 px-4 ">
                      <div>
                        <p className="text-sm font-medium text-indigo-600x truncate">
                          ...
                        </p>
                        <p className="mt-1 flex items-center text-sm text-gray-500">
                          {/*<MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                          <span className="truncate">
                            ...
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (offers.data.length === 0) {
    return (
      <div className="text-gray-500 text-sm">
        There are no active offers that match the search criteria.
      </div>
    )
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {offers.data.map((offer) => {
          const creator = connect('user.byId', {
            id: offer.data.creatorId
          });

          const activity = connect('tag.byId', {
            id: offer.data.activityId
          });

          return (
            <li key={offer.id}>
              <div className="block hover:bg-gray-50 cursor-pointer" onClick={() => onClick(offer)}>
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="flex-shrink-0">
                      <UserAvatar
                        user={creator}
                        large={true}
                      />
                    </div>
                    <div className="min-w-0 flex-1 px-4 ">
                      <div>
                        <p className="text-sm font-medium text-indigo-600x truncatex">
                          {offer.data.title || activity.data.name}
                        </p>
                        <p className="mt-1 flex items-center text-sm text-gray-500">
                          {/*<MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
                          <span className="truncate">
                            {creator.data.firstName} {creator.data.lastName}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  );
}
