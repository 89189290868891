import React from 'react';
import PropTypes from 'prop-types';
import ActiveFilter from './Status';
import ActivityFilter from './Activity';
import AssigneeFilter from './Assignee';
import CreatedFilter from './Created';
import PublishedFilter from './Published';
import StageFilter from './Stage';
import TagTypes from '../../../constants/TagTypes';
import TagFilter from './_common/TagFilter';
import getQueryUtils from '../queryUtils';
import SearchFilter from './_common/SearchFilter';
import useRouter from '../../../hooks/useRouter';

Filters.propTypes = {
  timestamp: PropTypes.string.isRequired
};

export default function Filters(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <ActivityFilter />
          <TagFilter
            type={TagTypes.ExpertiseType}
            label="Skills and experience"
            filterName="expertiseType"
            queryParamName="expertiseTypes"
          />
          {/*<TagFilter*/}
          {/*  type={TagTypes.Guidance}*/}
          {/*  label="Professional competencies"*/}
          {/*  filterName="guidance"*/}
          {/*  queryParamName="guidances"*/}
          {/*/>*/}
          {/*<TagFilter*/}
          {/*  type={TagTypes.Group}*/}
          {/*  label="Youth types"*/}
          {/*  filterName="group"*/}
          {/*  queryParamName="groups"*/}
          {/*/>*/}
          <TagFilter
            type={TagTypes.Subject}
            label="Subject areas"
            filterName="subject"
            queryParamName="subjects"
          />
          <TagFilter
            type={TagTypes.Age}
            label="Student ages"
            filterName="age"
            queryParamName="ages"
          />
          <TagFilter
            type={TagTypes.Day}
            label="Day"
            filterName="day"
            queryParamName="days"
          />
          <TagFilter
            type={TagTypes.InteractionType}
            label="Interaction types"
            filterName="interactionType"
            queryParamName="interactionTypes"
          />
          <TagFilter
            type={TagTypes.Participant}
            label="Participants"
            filterName="participant"
            queryParamName="participants"
          />
          <TagFilter
            type={TagTypes.Time}
            label="Time"
            filterName="time"
            queryParamName="times"
          />
          <TagFilter
            type={TagTypes.Commitment}
            label="Commitment"
            filterName="commitment"
            queryParamName="commitments"
          />
          {/*<AssigneeFilter location={location} history={history}/>*/}
          <CreatedFilter location={location} history={history} timestamp={timestamp}/>
          {/*<PublishedFilter location={location} history={history} />*/}
          <ActiveFilter location={location} history={history} timestamp={timestamp}/>
          {/*<StageFilter location={location} history={history} />*/}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <SearchFilter
            label="Project title"
            placeholder="Enter title..."
            param="title"
            timestamp={timestamp}
          />
          <SearchFilter
            label="Educator name"
            placeholder="Enter name..."
            param="name"
            timestamp={timestamp}
          />
          <SearchFilter
            label="School"
            placeholder="Enter school name..."
            param="school"
            timestamp={timestamp}
          />
        </div>
      </div>
    </div>
  );
};
