import React, { useState } from 'react';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import PageHeading from '../_components/PageHeading';
import Table from '../explore-partners/Table';
import SidePanel from '../explore-offers/SidePanel';
import ParentLayout from '../explore/Layout';
import useRouter from '../../hooks/useRouter';
import initialColumns from '../explore-offers/columns';
import Columns from '../explore-partners/Table/Columns';
import useQuery from './useQuery';
import { useConnect } from '@lore/connect';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();
  const connect = useConnect();

  const show = useDialog();

  function onClick(offer) {
    show(
      <SidePanel
        offer={offer}
        primaryAction="complete_offer"
        extraActions={[
          'publish_offer',
          'delete_offer'
        ]}
      />
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Explore', 'Offers']}
          title="Draft Offers"
          description={(
            <span>
              These are offers that a partner started, but never published. We recommend sending them
              a reminder to complete the offer, as they might just need a nudge. If that doesn't work,
              we suggest contacting them to see what prompted them to create the offer and if there's
              something preventing them from wanting to publish it.
            </span>
          )}
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        {/*<div className="px-2 py-4 sm:px-3 lg:px-4">*/}
        {/*  <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">*/}
        {/*    <Filters*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*    <SearchTags*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-end">
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            return useQuery({
              location,
              PAGE_SIZE,
              connect
            });
          }}
          row={function (offer) {
            return (
              <tr key={offer.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(offer)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      offer={offer}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
