import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import Loader from './Loader';
import TagChip from './_chips/TagChip';

Tags.propTypes = {
  type: PropTypes.string.isRequired,
  query: PropTypes.array,
  models: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.func
};

export default function Tags(props) {
  const { type, query, models, onChange, label } = props;

  if (!query) {
    return null;
  }

  if (models.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const tags = models.data.filter(function(model) {
    return query.indexOf(model.id) >= 0;
  });

  return tags.map(function(tag) {
    return (
      <TagChip
        key={tag.id}
        tag={tag}
        type={type}
        showType={true}
        onClick={() => onChange(tag)}
        label={label ? label(tag) : null}
        // onCancel={onChange}
      />
    );
  });
};
