import React from 'react';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Support(props) {
  const { project } = props;

  return (
    <Section
      title="Support Needed"
      // subtitle="Learners the activity is designed for."
    >
      <TextField label="Resources Needed">
        {project.data.resources || '-'}
      </TextField>
      <TextField label="Grant Requested">
        {project.data.grant || '-'}
      </TextField>
      {project.data.grant === 'Yes' && (
        <>
          <TextField label="Budget">
            {project.data.budget || '-'}
          </TextField>
          <TextField label="Total">
            {project.data.request || '-'}
          </TextField>
        </>
      )}
      <TextField label="Steward Help Requested">
        {project.data.contact || '-'}
      </TextField>
    </Section>
  )
}
