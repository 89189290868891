import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import Metric from '../Metric';
import REQUIRED_FIELDS from '../../explore-events/REQUIRED_FIELDS';

export default function TotalYear(props) {
  const { account, schoolStart, schoolEnd } = props;

  const allEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          accountId: account.id,
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const newEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          accountId: account.id,
          createdAt: {
            $lte: schoolEnd,
            $gte: schoolStart
          }
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const totalString = allEvents.meta ? allEvents.meta.totalCount : '...';
  const newString = newEvents.meta ? newEvents.meta.totalCount : '...';

  return (
    <Metric
      name="Total (+New)"
      stat={`${totalString} (+${newString})`}
      icon={UsersIcon}
    />
  );
}
