import React from 'react';
import Wrapper from '../../../_components/actions/Actions';
import useAccount from '../../../../hooks/useAccount';
import ReminderComplete from './ReminderComplete';

export default function Actions(props) {
  const { evaluation } = props;

  const account = useAccount();

  if (evaluation.data.accountId !== account.id) {
    return (
      <Wrapper>
        <div className="bg-accent w-full py-3 px-4 rounded-md font-medium text-white">
          Evaluation is outside your stewardship. Actions are disabled.
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ReminderComplete evaluation={evaluation} />
    </Wrapper>
  );
}
