import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import FormTemplate from '../../_common/FormTemplate.vertical';
import { ClockIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import MessageDeliveryStatus from '../../../components/MessageDeliveryStatus';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import Users from './Users';
import StringField from '../../../forms/_fields/StringField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    offer
  } = props;

  const [search, setSearch] = useState('');
  const [name, setName] = useState('');

  function onChangeSearch(text) {
    setSearch(text);
  }

  function onClick(user) {
    onChange('user', user);
  }

  return (
    <FormTemplate
      title="Invite an educator to this offer?"
      subtitle={(
        <span>
          Is there an educator you think would be a good fit for this offer? Search for them below!
        </span>
      )}
      showIcon={false}
      icon={ClockIcon}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="mt-4 space-y-4">
          <StringField
            label="Educator name"
            placeholder="Enter name..."
            value={search}
            onChange={onChangeSearch}
          />
          <Users
            name={search}
            onClick={onClick}
          />
        </div>
      )}
      footer={(
        <>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          {/*<PrimaryButton onClick={onSubmit}>*/}
          {/*  Send reminder*/}
          {/*</PrimaryButton>*/}
        </>
      )}
    />
  );
}
