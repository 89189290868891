import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';
import useStewardshipFilter from '../useStewardshipFilter';
import REQUIRED_FIELDS_PUBLIC from '../REQUIRED_FIELDS_PUBLIC';

ActiveFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function ActiveFilter(props) {
  const { location, history, timestamp } = props;

  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      {
        id: 1,
        data: {
          name: 'Active'
        }
      },
      {
        id: 2,
        data: {
          name: 'Completed'
        }
      },
      {
        id: 3,
        data: {
          name: 'Cancelled'
        }
      }
    ]
  };

  const related = function(model) {
    const whereMap = {
      1: {
        $and: REQUIRED_FIELDS_PUBLIC.$and,
        completed: false,
        cancelled: false,
        $or: stewardshipFilter
      },
      2: {
        completed: true,
        $or: stewardshipFilter
      },
      3: {
        cancelled: true,
        $or: stewardshipFilter
      }
    };

    const where = whereMap[model.id] || {
      $or: stewardshipFilter
    };

    return connect('project.find', {
      where: {
        eager: {
          $where: where
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Completion status"
      // dropdownLabel="It should be one of the following activities:"
      // dropdownLabel1="It should match"
      // dropdownLabel2="of the following activities:"
      queryUtils={getQueryUtils}
      filterName="status"
      queryParamName="status"
      tags={models}
      related={related}
      timestamp={timestamp}
    />
  );
};
