import React from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import TagTypes from '../../../constants/TagTypes';
import useOfferTags from '../../../hooks/useOfferTags';

export default function Resources(props) {
  const { offer } = props;

  const offerSupplies = useOfferTags(offer, TagTypes.Supply);

  const noSupplies = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply._tag.data.name === 'No supplies needed.';
  });

  const basicSupplies = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply._tag.data.name === 'Basic art supplies (crayons, markers, scissors)';
  });

  const specialSupplies = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply._tag.data.name === 'Other special supplies';
  });

  return (
    <Section
      title="Resources Needed"
      // subtitle="Date, time, cost, and supplies."
    >
      {noSupplies ? (
        <TextField label="Supplies Needed">
          No supplies needed.
        </TextField>
      ) : (
        <TextField label="Supplies Needed">
          {basicSupplies && (
            <div>
              {'Basic art supplies'}
            </div>
          )}
          {specialSupplies && offer.data.supplies && (
            <div>
              {offer.data.supplies}
            </div>
          )}
          <div className="mt-4">
            *{offer.data.suppliesProvidedBy || 'Partner will provide supplies'}.
          </div>
        </TextField>
      )}
      <TextField label="Cost">
        {offer.data.hasCost === 'Yes' ? offer.data.cost : 'Free'}
      </TextField>
    </Section>
  )
}
