import React  from 'react';
import { useConnect } from '@lore/connect';
import TextColumn from '../../_components/table/columns/TextColumn';
import { PayloadStates } from '@lore/utils';

export default function School(props) {
  const { interaction } = props;

  const connect = useConnect();

  const conversation = connect('conversation.byId', {
    id: interaction.data.conversationId
  });

  if (conversation.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  const role = connect('role.first', {
    where: {
      eager: {
        $where: {
          userId: conversation.data.educatorId
        }
      }
    }
  });

  if (role.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  if (role.state === PayloadStates.NOT_FOUND) {
    return (
      <TextColumn/>
    );
  }

  const organization = connect('organization.byId', {
    id: role.data.organizationId
  });

  return (
    <TextColumn>
      {organization.data.name || '...'}
    </TextColumn>
  );
}
