import React from 'react';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';

export default function Subjects(props) {
  const { offer } = props;

  const offerSubjects = useOfferTags(offer, TagTypes.Subject);

  if (
    offerSubjects.state === PayloadStates.FETCHING ||
    offerSubjects.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Subjects"
      subtitle="Offer is related to these subject areas."
    >
      <TextField label="">
        {offerSubjects.data.map(function(offerSubject) {
          return (
            <div key={offerSubject.id || offerSubject.cid}>
              {offerSubject._tag.data.name}
            </div>
          );
        })}
      </TextField>
    </Section>
  )
}
