import React from 'react';
import Id from './Table/Id';
import User from './Table/User';
import Project from './Table/Project';
import Offer from './Table/Offer';
import Date from './Table/Date';
import Educator from './Table/Educator';
import Partner from './Table/Partner';
import Creator from './Table/Creator';

export default [
  { id: 'id',      name: 'ID',             row: Id,       selected: false },
  { id: 'partner', name: 'Partner',        row: Partner,  selected: true },
  { id: 'project', name: 'Project',        row: Project,  selected: true },
  // { id: 'user',    name: 'Educator',       row: Educator, selected: true },
  // { id: 'offer',   name: 'Offer',          row: Offer,    selected: true },
  { id: 'creator', name: 'Recommended By', row: Creator,  selected: true },
  { id: 'date',    name: 'Date',           row: Date,     selected: true }
];
