import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';

UserRole.propTypes = {
  user: PropTypes.object
};

export default function UserRole(props) {
  const { user, children } = props;

  const connect = useConnect();

  const roles = connect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  if (roles.state === PayloadStates.FETCHING) {
    return children ?
      children('...', {}) :
      '...';
  }

  if (!roles.data[0]) {
    return children ?
      children('', {}) || '' :
      '';
  }

  const role = roles.data[0];

  const organization = connect('organization.byId', {
    id: role.data.organizationId
  });

  const { title } = role.data;
  const { name } = organization.data;

  if (title || name) {
    return `${title || ''}${title && name ? ' @ ' : ''}${name}`;
  }

  return user.data.email;
}
