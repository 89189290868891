import React from 'react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import Metric from '../Metric';
import { useConnect } from '@lore/connect';

export default function TotalYear(props) {
  const { account, schoolStart, schoolEnd } = props;

  const schools = useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEducators',
            relation: 'users',
            $where: {
              isEducator: true,
              'accounts.id': account.id
            }
          }
        ],
        $where: {
          numberOfEducators: {
            $gt: 0
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const newSchools = useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEducators',
            relation: 'users',
            $where: {
              isEducator: true,
              'accounts.id': account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        ],
        $where: {
          numberOfEducators: {
            $gt: 0
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const totalCount = schools.meta ? schools.meta.totalCount : '...';
  const newCount = newSchools.meta ? newSchools.meta.totalCount : '...';

  return (
    <Metric
      name="Total (+New)"
      stat={`${totalCount} (+${newCount})`}
      icon={OfficeBuildingIcon}
    />
  );
}
