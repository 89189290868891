import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';

RecommendEducator.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function RecommendEducator(props) {
  const { location, history } = props;

  const connect = useConnect();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      [1, '$0'],
      [2, '$1+'],
      [5, '$5+'],
      [10, '$10+'],
      [25, '$25+'],
      [50, '$50+'],
      [100, '$100+'],
      [250, '$250+'],
      [500, '$500+']
    ].map(function(value) {
      // const weeks = Number(value[0].split('_')[1]);
      return {
        // id: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
        id: value[0],
        data: {
          name: value[1]
        }
      }
    })
  };

  const users = function(model) {
    const total = {
      1: 0,
      2: 1
    }[model.id] || Number(model.id);

    return connect('evaluationProjectPartner.find', {
      where: {
        eager: {
          $where: {
            contributionValue: {
              $gte: total
            }
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="In-kind contribution amount"
      dropdownLabel="The training will be:"
      // dropdownLabel1="It should match"
      // dropdownLabel2="of the following activities:"
      queryUtils={getQueryUtils}
      filterName="contribution"
      queryParamName="contributions"
      tags={models}
      related={users}
    />
  );
};
