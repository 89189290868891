import React  from 'react';
import User from './User';
import { useConnect } from '@lore/connect';

export default function Partner(props) {
  const { question } = props;

  const user = useConnect('user.byId', {
    id: question.data.userId
  });

  return (
    <User user={user} />
  );
}
