import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';
import useStewardshipFilter from '../explore-partners/useStewardshipFilter';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect, returnRawQuery = false } = props;

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  const query = {
    where: {
      eager: {
        $where: {
          isEducator: false,
          createdAt: {
            $gte: moment(timestamp).subtract(1, 'weeks').toISOString()
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  };

  if (returnRawQuery) {
    return query;
  }

  return connect('user.find', query);
}
