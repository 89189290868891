import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../../_components/table/columns/TextColumn';
import moment from 'moment-timezone';

export default function Filled(props) {
  const { event } = props;

  const [timestamp] = useState(moment().toISOString());

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          response: 'Yes',
          eventId: event.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  if (invitations.state === PayloadStates.FETCHING) {
    return (
      <TextColumn loading={true} />
    );
  }

  if (!event.data.slots) {
    return (
      <TextColumn>
        Unspecified
      </TextColumn>
    );
  }

  // if (invitations.data.length === 0) {
  //   return (
  //     <TextColumn>
  //       0%
  //     </TextColumn>
  //   );
  // }

  const percent = Math.floor(invitations.data.length / event.data.slots * 100);

  return (
    <TextColumn>
      {percent}%
    </TextColumn>
  );
}
