import React from 'react';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';

export default function Competencies(props) {
  const { offer } = props;

  const offerGuidances = useOfferTags(offer, TagTypes.Guidance);

  if (
    offerGuidances.state === PayloadStates.FETCHING ||
    offerGuidances.data.length === 0
  ) {
    return null;
  }

  return (
    <Section
      title="Professional Competencies"
      subtitle="Offer addresses these topics."
    >
      <TextField label="">
        {offerGuidances.data.map(function(offerGuidance) {
          return (
            <div key={offerGuidance.id || offerGuidance.cid}>
              {offerGuidance._tag.data.name}
            </div>
          );
        })}
      </TextField>
    </Section>
  )
}
