import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

/**
 * The AuthenticatedRoute provides an easy way to redirect the user
 * to a login experience if we don't know who they are.
 */

import AuthenticatedRoute from './src/routes/AuthenticatedRoute';
import AuthenticatedRouteExplore from './src/routes/AuthenticatedRouteExplore';
import SetupRoute from './src/routes/SetupRoute';

/**
 * Routes are used to declare your view hierarchy
 * See: https://reacttraining.com/react-router/web/guides/quick-start
 */

import Login from './src/pages/Login';
import Signup from './src/pages/Signup';
import Logout from './src/pages/Logout';
import AuthCallback from './src/pages/AuthCallback';

import SelectCommunityLayout from './src/pages/select-community/Layout';

import NotAuthorized from './src/pages/unauthorized/Layout';
import NotFoundPage from './src/pages/NotFoundPage';

/*
 *  routes
 */

import DashboardRedirectLayout from './src/pages/dashboard-redirect/Layout';
import DashboardFeedLayout from './src/pages/dashboard-feed/Layout';
import DashboardOverviewLayout from './src/pages/dashboard-overview/Layout';
import DashboardYearLayout from './src/pages/dashboard-year/Layout';
import ExploreProjectInvitationsLayout from './src/pages/explore-invitations-projects/Layout';
import ExploreOfferInvitationsLayout from './src/pages/explore-invitations-offers/Layout';

import ExploreProjectsLayout from './src/pages/explore-projects/Layout';
import ExploreStarredProjectsLayout from './src/pages/explore-projects-starred/Layout';
import ExploreExpiringProjectsLayout from './src/pages/explore-projects-expiring/Layout';
import ExploreExpiredProjectsLayout from './src/pages/explore-projects-expired/Layout';
import ExplorePartnersInvitedProjectsLayout from './src/pages/explore-projects-partners-invited/Layout';
import ExplorePartnersConnectedProjectsLayout from './src/pages/explore-projects-partners-connected/Layout';
import ExploreDraftProjectsLayout from './src/pages/explore-projects-draft/Layout';
import ExploreNewProjectsLayout from './src/pages/explore-projects-new/Layout';
import ExploreActiveProjectsLayout from './src/pages/explore-projects-active/Layout';
import ExploreProjectsWantsContactLayout from './src/pages/explore-projects-wants-contact/Layout';
import ExploreProjectsResourcesNeededLayout from './src/pages/explore-projects-resources-needed/Layout';
import ExploreProjectsFundingRequestedLayout from './src/pages/explore-projects-funding-requested/Layout';
import ExploreProjectsExpiredNotClosedLayout from './src/pages/explore-projects-expired-not-closed/Layout';
import ExploreProjectsMissingEducatorEvaluationLayout from './src/pages/explore-projects-missing-educator-evaluation/Layout';
import ExploreProjectsCompletedLayout from './src/pages/explore-projects-completed/Layout';
import ExploreProjectsSchoolYearLayout from './src/pages/explore-projects-school-year/Layout';

import ExploreEventsLayout from './src/pages/explore-events/Layout';
import ExploreStarredEventsLayout from './src/pages/explore-events-starred/Layout';
import ExploreExpiringEventsLayout from './src/pages/explore-events-expiring/Layout';
import ExplorePartnersEventsLayout from './src/pages/explore-events-partners/Layout';
import ExploreDraftEventsLayout from './src/pages/explore-events-draft/Layout';
import ExploreNewEventsLayout from './src/pages/explore-events-new/Layout';
import ExploreSoonEventsLayout from './src/pages/explore-events-soon/Layout';
import ExploreRecentlyCompletedEventsLayout from './src/pages/explore-events-recently-completed/Layout';
import ExploreEventsExpiredNotClosedLayout from './src/pages/explore-events-expired-not-closed/Layout';
import ExploreEventsMissingEducatorEvaluationLayout from './src/pages/explore-events-missing-educator-evaluation/Layout';
import ExploreEventsCompletedLayout from './src/pages/explore-events-completed/Layout';
import ExploreEventsSchoolYearLayout from './src/pages/explore-events-school-year/Layout';

import ExploreOffersLayout from './src/pages/explore-offers/Layout';
import ExploreStarredOffersLayout from './src/pages/explore-offers-starred/Layout';
import ExploreNewOffersLayout from './src/pages/explore-offers-new/Layout';
import ExploreActiveOffersLayout from './src/pages/explore-offers-active/Layout';
import ExploreDraftOffersLayout from './src/pages/explore-offers-draft/Layout';
import ExploreOffersSchoolYearLayout from './src/pages/explore-offers-school-year/Layout';

import ExplorePartnersLayout from './src/pages/explore-partners/Layout';
import ExploreStarredPartnersLayout from './src/pages/explore-partners-starred/Layout';
import ExploreIncompletePartnersLayout from './src/pages/explore-partners-incomplete/Layout';
import ExplorePartnerQuestionsLayout from './src/pages/explore-partners-questions/Layout';
import ExplorePartnerInvitationsLayout from './src/pages/explore-partners-invitations/Layout';
import ExploreUnresponsivePartnersLayout from './src/pages/explore-partners-unresponsive/Layout';
import ExplorePartnerEvaluationsPendingLayout from './src/pages/explore-partner-evaluations-pending/Layout';
import ExplorePartnersNewLayout from './src/pages/explore-partners-new/Layout';
import ExplorePartnersNoInteractionsLayout from './src/pages/explore-partners-no-interactions/Layout';
import ExplorePartnersMultipleEvaluationsLayout from './src/pages/explore-partners-multiple-evaluations/Layout';
import ExplorePartnersMultipleHoursLayout from './src/pages/explore-partners-multiple-hours/Layout';
import ExplorePartnersMultipleStudentsLayout from './src/pages/explore-partners-multiple-students/Layout';

import ExploreEducatorsLayout from './src/pages/explore-educators/Layout';
import ExploreStarredEducatorsLayout from './src/pages/explore-educators-starred/Layout';
import ExploreIncompleteEducatorsLayout from './src/pages/explore-educators-incomplete/Layout';
import ExploreEducatorInvitationsLayout from './src/pages/explore-educators-invitations/Layout';
import ExploreUnresponsiveEducatorsLayout from './src/pages/explore-educators-unresponsive/Layout';
import ExploreEducatorEvaluationsPendingLayout from './src/pages/explore-educator-evaluations-pending/Layout';
import ExploreEducatorsNewLayout from './src/pages/explore-educators-new/Layout';
import ExploreEducatorsNoneCreatedLayout from './src/pages/explore-educators-none-created/Layout';
import ExploreEducatorsMultipleCompletedLayout from './src/pages/explore-educators-multiple-completed/Layout';

import AppreciateLayout from './src/pages/appreciate/Layout';
import CommunicateLayout from './src/pages/communicate/Layout';
import CoordinateLayout from './src/pages/coordinate/Layout';

import TeamStaffLayout from './src/pages/team-staff/Layout';
import TeamInvitationsLayout from './src/pages/team-invitations/Layout';

import ExploreQuestionsLayout from './src/pages/explore-questions/Layout';
import ExploreClaimsLayout from './src/pages/explore-claims/Layout';
import ExploreInvitationsLayout from './src/pages/explore-invitations/Layout';
import ExploreInteractionsLayout from './src/pages/explore-interactions/Layout';
import EvaluationsProjectEducatorLayout from './src/pages/evaluations-project-educators/Layout';
import EvaluationsProjectPartnerLayout from './src/pages/evaluations-project-partners/Layout';
import EvaluationsEventEducatorLayout from './src/pages/evaluations-event-educators/Layout';
import EvaluationsEventPartnerLayout from './src/pages/evaluations-event-partners/Layout';
import ExploreRecommendationsProjectsLayout from './src/pages/explore-recommendations-projects/Layout';
import ExploreRecommendationsOffersLayout from './src/pages/explore-recommendations-offers/Layout';

import SettingsScreeningLayout from './src/pages/settings-screening/Layout';
import SettingsTrainingLayout from './src/pages/settings-training/Layout';
import SettingsBannerLayout from './src/pages/settings-banner/Layout';
import SettingsGrantsLayout from './src/pages/settings-grants/Layout';

export default (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route path="/logout" component={Logout} />
    <Route path="/auth/callback" component={AuthCallback} />
    <Route path="/unauthorized" component={NotAuthorized} />

    <Redirect exact from="/" to="/select-community" />
    <Redirect exact from="/c/:subdomain/" to="/c/:subdomain/explore/partners" />
    <Redirect exact from="/c/:subdomain/dashboard" to="/c/:subdomain/dashboard/redirect" />
    {/*<Redirect exact from="/c/:subdomain/troubleshoot" to="/c/:subdomain/troubleshoot/partners/invitations" />*/}
    <Redirect exact from="/c/:subdomain/explore" to="/c/:subdomain/explore/projects" />
    <Redirect exact from="/c/:subdomain/evaluations" to="/c/:subdomain/evaluations/projects/educators" />
    <Redirect exact from="/c/:subdomain/explore/recommendations" to="/c/:subdomain/explore/recommendations/projects" />
    <Redirect exact from="/c/:subdomain/team" to="/c/:subdomain/team/staff" />
    <Redirect exact from="/c/:subdomain/settings" to="/c/:subdomain/settings/screening" />

    <SetupRoute exact path="/select-community" component={SelectCommunityLayout} />

    <AuthenticatedRoute exact path="/c/:subdomain/dashboard/redirect" component={DashboardRedirectLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/dashboard/feed" component={DashboardFeedLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/dashboard/overview" component={DashboardOverviewLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/dashboard/year/:year" component={DashboardYearLayout} />
    {/*<AuthenticatedRoute exact path="/dashboard/overview/:id/:tenant" component={DashboardOverviewLayout} />*/}

    {/* Explore */}
    {/*<AuthenticatedRouteExplore exact path="/explore/invitations-projects" component={ExploreProjectInvitationsLayout} />*/}
    {/*<AuthenticatedRouteExplore exact path="/explore/invitations-offers" component={ExploreOfferInvitationsLayout} />*/}

    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects" component={ExploreProjectsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/starred" component={ExploreStarredProjectsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/expiring" component={ExploreExpiringProjectsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/expired" component={ExploreExpiredProjectsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/draft" component={ExploreDraftProjectsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/new" component={ExploreNewProjectsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/active" component={ExploreActiveProjectsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/partners-invited" component={ExplorePartnersInvitedProjectsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/partners-connected" component={ExplorePartnersConnectedProjectsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/wants-contact" component={ExploreProjectsWantsContactLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/resources-needed" component={ExploreProjectsResourcesNeededLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/funding-requested" component={ExploreProjectsFundingRequestedLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/expired-not-closed" component={ExploreProjectsExpiredNotClosedLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/missing-educator-evaluation" component={ExploreProjectsMissingEducatorEvaluationLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/completed" component={ExploreProjectsCompletedLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/school-year" component={ExploreProjectsSchoolYearLayout} />

    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events" component={ExploreEventsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/starred" component={ExploreStarredEventsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/expiring" component={ExploreExpiringEventsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/new" component={ExploreNewEventsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/draft" component={ExploreDraftEventsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/partners" component={ExplorePartnersEventsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/soon" component={ExploreSoonEventsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/recently-completed" component={ExploreRecentlyCompletedEventsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/expired-not-closed" component={ExploreEventsExpiredNotClosedLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/missing-educator-evaluation" component={ExploreEventsMissingEducatorEvaluationLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/completed" component={ExploreEventsCompletedLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/school-year" component={ExploreEventsSchoolYearLayout} />

    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/offers" component={ExploreOffersLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/offers/starred" component={ExploreStarredOffersLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/offers/new" component={ExploreNewOffersLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/offers/active" component={ExploreActiveOffersLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/offers/draft" component={ExploreDraftOffersLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/offers/school-year" component={ExploreOffersSchoolYearLayout} />

    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/questions" component={ExploreQuestionsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/projects/questions" component={ExplorePartnerQuestionsLayout} />

    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/invitations" component={ExploreInvitationsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/events/invitations" component={ExplorePartnerInvitationsLayout} />

    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/claims" component={ExploreClaimsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/offers/invitations" component={ExploreEducatorInvitationsLayout} />

    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/partners" component={ExplorePartnersLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/partners/starred" component={ExploreStarredPartnersLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/partners/new" component={ExplorePartnersNewLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/partners/incomplete" component={ExploreIncompletePartnersLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/partners/no-interactions" component={ExplorePartnersNoInteractionsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/partners/multiple-evaluations" component={ExplorePartnersMultipleEvaluationsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/partners/multiple-hours" component={ExplorePartnersMultipleHoursLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/partners/multiple-students" component={ExplorePartnersMultipleStudentsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/partners/unresponsive" component={ExploreUnresponsivePartnersLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/partners/evaluations-pending" component={ExplorePartnerEvaluationsPendingLayout} />

    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/educators" component={ExploreEducatorsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/educators/starred" component={ExploreStarredEducatorsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/educators/incomplete" component={ExploreIncompleteEducatorsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/educators/unresponsive" component={ExploreUnresponsiveEducatorsLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/educators/evaluations-pending" component={ExploreEducatorEvaluationsPendingLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/educators/new" component={ExploreEducatorsNewLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/educators/none-created" component={ExploreEducatorsNoneCreatedLayout} />
    <AuthenticatedRouteExplore exact path="/c/:subdomain/explore/educators/multiple-completed" component={ExploreEducatorsMultipleCompletedLayout} />

    <AuthenticatedRoute exact path="/c/:subdomain/appreciate" component={AppreciateLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/communicate" component={CommunicateLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/coordinate" component={CoordinateLayout} />

    <AuthenticatedRoute exact path="/c/:subdomain/team/staff" component={TeamStaffLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/team/invitations" component={TeamInvitationsLayout} />

    <AuthenticatedRoute exact path="/c/:subdomain/explore/interactions" component={ExploreInteractionsLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/explore/recommendations/projects" component={ExploreRecommendationsProjectsLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/explore/recommendations/offers" component={ExploreRecommendationsOffersLayout} />

    {/* Evaluations */}
    <AuthenticatedRoute exact path="/c/:subdomain/evaluations/projects/educators" component={EvaluationsProjectEducatorLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/evaluations/projects/partners" component={EvaluationsProjectPartnerLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/evaluations/events/educators" component={EvaluationsEventEducatorLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/evaluations/events/partners" component={EvaluationsEventPartnerLayout} />

    <AuthenticatedRoute exact path="/c/:subdomain/settings/screening" component={SettingsScreeningLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/settings/training" component={SettingsTrainingLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/settings/banner" component={SettingsBannerLayout} />
    <AuthenticatedRoute exact path="/c/:subdomain/settings/grants" component={SettingsGrantsLayout} />

    <Route component={NotFoundPage} />
  </Switch>
);
