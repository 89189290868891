import React from 'react';
import PropTypes from 'prop-types';
import Communities from './Communities';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../components/ScrollToTop';
import RemoveLoadingScreen from '../RemoveLoadingScreen';
import logo from '../../../assets/images/logo.png';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import Redirect from '../../components/Redirect';

export default function Layout(props) {
  const { history } = props;
  const currentUser = useUser();

  const accounts = useConnect('account.find', {
    where: {
      eager: {
        $where: {
          isActive: true,
          'members.userId': currentUser.id,
          'members.isStaff': true
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  if (accounts.state === PayloadStates.FETCHING) {
    return null;
  }

  if (accounts.data.length === 0) {
    return (
      <Redirect to="/unauthorized" />
    );
  }

  if (accounts.data.length === 1) {
    const account = accounts.data[0];
    return (
      <Redirect to={`/c/${account.data.subdomain}`} />
    );
  }

  return (
    <div className="bg-white">
      <Helmet>
        <title>Select Community</title>
      </Helmet>
      <ScrollToTop />
      <RemoveLoadingScreen/>
      <div className="flex flex-col lg:flex-row h-100 lg:h-screen">
        <div className="flex items-center justify-center bg-gradient-to-b from-orange-100 to-orange-200 px-16 h-40 py-8 lg:h-screen">
          <img src={logo} className="w-24 lg:w-40 xl:w-64 filter drop-shadow-md mx-4" alt="logo" />
        </div>
        <div className="flex-1 flex flex-col h-auto lg:h-100 lg:overflow-scroll">
          <Communities accounts={accounts} />
        </div>
      </div>
    </div>
  );
};
