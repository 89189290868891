import React from 'react';
import {
  HomeIcon,
  ExclamationIcon,
  SparklesIcon,
  SpeakerphoneIcon,
  OfficeBuildingIcon,
  UserGroupIcon,
  MapIcon,
  CogIcon,
  CheckIcon,
  CheckCircleIcon,
  ClipboardListIcon
} from '@heroicons/react/outline';

export default function(account) {
  const root = `/c/${account.data.subdomain}`;

  return [
    { name: 'Dashboard', href: `${root}/dashboard`, icon: HomeIcon },
    // { name: 'Actions', href: `/explore', icon: CheckIcon },
    { name: 'Explore', href: `${root}/explore`, icon: MapIcon },
    { name: 'Evaluations', href: `${root}/evaluations`, icon: ClipboardListIcon },
    // { name: 'Appreciate', href: `${root}/appreciate`, icon: SparklesIcon },
    // { name: 'Communicate', href: `${root}/communicate`, icon: SpeakerphoneIcon },
    // { name: 'Coordinate', href: `${root}/coordinate`, icon: OfficeBuildingIcon },
    { name: 'Team', href: `${root}/team`, icon: UserGroupIcon },
    { name: 'Settings', href: `${root}/settings`, icon: CogIcon }
  ]
};
