import React from 'react';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';

export default function Audience(props) {
  const { offer } = props;

  const offerGroups = useOfferTags(offer, TagTypes.Group);
  const offerSizes = useOfferTags(offer, TagTypes.Size);
  const offerParticipants = useOfferTags(offer, TagTypes.Participant);

  return (
    <Section
      title="Project Audience"
      // subtitle="Learners the activity is designed for."
    >
      <TextField label="Ideal Audience">
        {offer.data.audience || '-'}
      </TextField>
      <TextField label="Target Student Groups">
        {offerGroups.data.map(function(offerGroup) {
          return (
            <div key={offerGroup.id || offerGroup.cid}>
              {offerGroup._tag.data.name}
            </div>
          )
        })}
        {offerGroups.data.length === 0 && '-'}
      </TextField>
      <TextField label="Educator/Students">
        {offerParticipants.data.map(function(offerParticipant) {
          return (
            <div key={offerParticipant.id || offerParticipant.cid}>
              {offerParticipant._tag.data.name}
            </div>
          );
        })}
        {offerParticipants.data.length === 0 && '-'}
      </TextField>
      <TextField label="Group Size">
        {offerSizes.data.map(function(offerSize) {
          return (
            <div key={offerSize.id || offerSize.cid}>
              {offerSize._tag.data.name}
            </div>
          )
        })}
        {offerSizes.data.length === 0 && '-'}
      </TextField>
    </Section>
  )
}
