import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { onChange, onChangeArray, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils, { attributeKeys } from '../queryUtils';

SearchTags.propTypes = {
  // timestamp: PropTypes.string.isRequired
};

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const { params: searchParams } = getQueryUtils(parse(location.search), attributeKeys);

  return (
    <div className="space-y-3">
      <Tag
        type="Educator"
        query={searchParams.educator}
        onChange={onChange('educator', { location, history })}
      />
      <Tag
        type="Partner"
        query={searchParams.partner}
        onChange={onChange('partner', { location, history })}
      />
      <Tags
        type="Response"
        query={searchParams.responses}
        models={mapArray([
          'Pending',
          'Yes',
          'Maybe',
          'No'
        ])}
        onChange={onChangeArray('responses', { location, history })}
      />
      <Tag
        type="Title"
        query={searchParams.title}
        onChange={onChange('title', { location, history })}
      />
    </div>
  );
};
