import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/classNames';
import { useConfig } from '@lore/config';

Community.propTypes = {
  account: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Community(props) {
  const {
    account,
    onClick
  } = props;

  const { tenant } = useConfig();

  return (
    <div>
      <div
        className={classNames(
          'flex bg-white shadow-sm rounded-lg p-6 space-x-4 cursor-pointer max-w-lg hover:bg-orange-50',
          'border border-cs-grey-100 m-px'
        )}
        onClick={() => onClick(account)}
      >
        <div className="">
          <div className="borderx border-gray-200x rounded-sm">
            <img
              src={tenant.networkLogo[account.data.subdomain]}
              className="w-12 h-12 object-contain"
              alt="Logo"
            />
          </div>
        </div>
        <div className="flex-1 p-6x">
          <h2 className="font-semibold text-gray-700">
            {account.data.name}
          </h2>
          <div className="text-sm font-light">
            {account.data.city}, {account.data.state}
          </div>
        </div>
      </div>
    </div>
  );
};
