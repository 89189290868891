import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Flexibility(props) {
  const { offer } = props;

  if (!offer.data.flexibility) {
    return null;
  }

  return (
    <Section
      title="Flexibility"
      subtitle={(
        <span>
          Does the activity need to be completed exactly as outlined, or is there
          flexibility if an educator wants to propose changes?
        </span>
      )}
    >
      <TextField label="">
        {offer.data.flexibility}
      </TextField>
    </Section>
  )
}
