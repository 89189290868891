import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import $ from 'jquery';
import '../../../lib/bootstrap-datepicker';
import classNames from '../../utils/classNames';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

DateField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.node,
  date: PropTypes.string
};

DateField.defaultProps = {
  disabled: false
};

export default function DateField(props) {
  const { onChange: onStartDateChange } = props;
  const dateRef = useRef(null);

  useEffect(() => {
    $(dateRef.current).datepicker({
      forceParse: false,
      orientation: 'bottom left',
      autoclose: true,
      format: {
        toDisplay: function (date, format, language) {
          var d = new Date(date);
          return d.toISOString(true);
        },
        toValue: function (date, format, language) {
          var d = new Date(date);
          return new Date(d);
        }
      }
    }).on('changeDate', function(event) {
      const value = event.date.toISOString(true);
      onStartDateChange(value);
    });
  }, [onStartDateChange]);

  const {
    value,
    label,
    placeholder,
    description,
    disabled,
    onChange,
    error,
    date,
    name,
    className
  } = props;

  return (
    <div className={className}>
      {label ? (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ): null}
      <div className={classNames(
        label ? 'mt-1' : '',
        error ? 'relative rounded-md shadow-sm' : ''
      )}>
        <input
          ref={dateRef}
          type="text"
          name={name}
          className={error ?
            'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md' :
            'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
          }
          value={date ? moment(date).format('MMM D, YYYY') : ''}
          placeholder={placeholder}
          readOnly={disabled}
          onChange={() => {}}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {/*<div className="input-group input-daterange">*/}
      {/*  <input*/}
      {/*    ref={dateRef}*/}
      {/*    type="text"*/}
      {/*    className="form-control"*/}
      {/*    value={date ? moment(date).format('MMM D, YYYY') : ''}*/}
      {/*    onChange={() => {}}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={`form-control ${error ? 'is-invalid' : ''}`} style={{ display: 'none'}}/>*/}
      {error && (
        <p className="mt-2 text-sm text-red-600">
          {error}
        </p>
      )}
      {description && (
        <p className="mt-2 text-sm text-gray-500">
          {description}
        </p>
      )}
    </div>
  );
}
