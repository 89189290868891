import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import Feed from '../../_components/Feed';
import NoteForm from '../../../forms/note';
import Comment from '../../_components/Comment';

export default function Notes(props) {
  const { evaluation } = props;

  const connect = useConnect();
  const [timestamp] = useState(moment().toISOString());

  const notes = connect('note.find', {
    where: {
      eager: {
        $where: {
          evaluationEventPartnerId: evaluation.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      order: 'createdAt asc'
    },
    include: function(note) {
      const isEvaluation = note.data.evaluationEventPartnerId === evaluation.id;
      const isOptimistic = !note.id;
      const isNew = moment(note.data.createdAt).diff(timestamp) > 0;
      return isEvaluation && (isOptimistic || isNew);
    },
    sortBy: function(model) {
      return moment(model.data.createdAt).unix();
    },
    exclude: function(note) {
      return note.state === PayloadStates.DELETED;
    }
  });

  return (
    <>
      <div className="px-6">
        <Feed models={notes}>
          {function(note) {
            const creator = connect('user.byId', {
              id: note.data.creatorId
            });

            return (
              <Comment
                model={note}
                title={`${creator.data.firstName} ${creator.data.lastName}`}
                imageSrc={creator.data.avatarUrl}
              />
            );
          }}
        </Feed>
      </div>
      <NoteForm
        extraParams={{
          evaluationEventPartnerId: evaluation.id
        }}
      />
    </>
  );
}
