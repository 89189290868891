import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import Feed from '../../_components/Feed';
import Comment from '../../_components/Comment';

export default function Notes(props) {
  const { request } = props;

  const connect = useConnect();
  const [timestamp] = useState(moment().toISOString());

  const notes = connect('projectNote.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      order: 'createdAt asc'
    }
  });

  return (
    <Feed models={notes}>
      {function(note) {
        const creator = connect('user.byId', {
          id: note.data.creatorId
        });

        return (
          <Comment
            model={note}
            title={`${creator.data.firstName} ${creator.data.lastName}`}
            imageSrc={creator.data.avatarUrl}
          />
        );
      }}
    </Feed>
  );
}
