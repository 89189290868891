import React  from 'react';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';

export default function Recommend(props) {
  const { evaluation } = props;

  return (
    <SupportingTextColumn
      title={evaluation.data.recommend}
      subtitle={evaluation.data.testimonial || evaluation.data.improve}
    />
  );
}
