import React from 'react';
import { useConnect } from '@lore/connect';
import Section from '../../_components/sidePanel/Section';
import { PayloadStates } from '@lore/utils';

export default function Stories(props) {
  const { user } = props;

  const connect = useConnect();

  const storyUsers = connect('userNode.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          storyId: {
            $not: null
          }
        }
      }
    }
  });

  // if (
  //   storyUsers.state === PayloadStates.FETCHING ||
  //   storyUsers.data.length === 0
  // ) {
  //   return null;
  // }

  return (
    <Section
      title="Impact Stories"
      // subtitle="Past impact stories I've been involved with."
      className="bg-gray-50"
    >
      <div>
        <ul className="space-y-12">
          {storyUsers.data.map((storyUser) => {
            const story = connect('story.byId', {
              id: storyUser.data.storyId
            });

            return (
              <li key={storyUser.id}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2">
                    <img className="object-cover shadow-lg rounded-lg" src={story.data.imageUrl} alt="" />
                  </div>
                  <div className="space-y-2">
                    <div className="text-md leading-6 font-medium space-y-1">
                      <h3>{story.data.title}</h3>
                      {/*<p className="text-indigo-600">{storyUser.role}</p>*/}
                    </div>
                    <div className="text-sm">
                      <p className="text-gray-500 line-clamp-5x">
                        {story.data.description}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
          {storyUsers.data.length === 0 && '-'}
        </ul>
      </div>
    </Section>
  )
}
