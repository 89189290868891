import React  from 'react';
// import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Contribution(props) {
  const { evaluation } = props;

  // return (
  //   <SupportingTextColumn
  //     title={evaluation.data.contribution}
  //     subtitle={evaluation.data.contributionValue}
  //   />
  // );

  if (evaluation.data.contributionValue) {
    return (
      <TextColumn>
        ${evaluation.data.contributionValue}
      </TextColumn>
    );
  }

  return (
    <TextColumn/>
  );
}
