import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';
import useStewardshipFilter from '../explore-events/useStewardshipFilter';
import REQUIRED_FIELDS_PUBLIC from '../explore-events/REQUIRED_FIELDS_PUBLIC';
import { PayloadStates } from '@lore/utils';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  return connect('event.find', {
    where: {
      eager: {
        // $aggregations: [
        //   'Pending',
        //   'Yes',
        //   'Maybe',
        //   'No'
        // ].map(function(response) {
        //   return {
        //     type: 'count',
        //     alias: `numberOfInvitations${response}`,
        //     relation: 'invitations',
        //     $where: {
        //       response: response
        //     }
        //   };
        // }),
        $aggregations: [
          // 'Pending',
          'Yes',
          // 'Maybe',
          // 'No'
        ].map(function(response) {
          return {
            type: 'count',
            alias: `numberOfInvitations${response}`,
            relation: 'invitations',
            $where: {
              response: response
            }
          };
        }),
        $where: {
          $and: REQUIRED_FIELDS_PUBLIC.$and,
          // activityId: {
          //   $not: null
          // },
          // published: true,
          // numberOfInvitationsPending: 0,
          numberOfInvitationsYes: 0,
          // numberOfInvitationsMaybe: 0,
          // numberOfInvitationsNo: 0,
          // endDate: {
          //   $or: {
          //     $equals: null,
          //     $gte: timestamp
          //   }
          // },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    },
    exclude: function(model) {
      return model.state === PayloadStates.DELETED;
    }
  });
}
