import React from 'react';
import { useConnect } from '@lore/connect';
import TextColumn from '../../_components/table/columns/TextColumn';
import PayloadStates from '../../../constants/PayloadStates';

export default function PartnerEmail(props) {
  const { claim } = props;

  const connect = useConnect();

  const offer = connect('offer.byId', {
    id: claim.data.offerId
  });

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  const user = connect('user.byId', {
    id: offer.data.creatorId
  });

  return (
    <TextColumn>
      {user.data.email}
    </TextColumn>
  );
}
