import React from 'react';
import Metrics from '../Metrics';
import SharedOffer from './SharedOffer';
import TotalYear from './TotalYear';
import TotalYearComplete from './TotalYearComplete';
import InvitedToProject from './InvitedToProject';
import InvitedToEvent from './InvitedToEvent';
import InvitedToProjectAndResponded from './InvitedToProjectAndResponded';
import InvitedToEventAndResponded from './InvitedToEventAndResponded';
import CreatedInviteToProject from './CreatedInviteToProject';
import CreatedInviteToEvent from './CreatedInviteToEvent';
import EngagementByMonth from './EngagementByMonth';
import ContributionYear from './ContributionYear';

export default function Partners(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Partners">
        {/*<Chart {...params} />*/}
        <EngagementByMonth {...params} />
      </Metrics>
      <Metrics>
        {/*<OfferPartners {...params} />*/}
        <TotalYear {...params} />
        <TotalYearComplete {...params} />
        <SharedOffer {...params} />

        <InvitedToProject {...params} />
        <InvitedToEvent {...params} />
        <InvitedToProjectAndResponded {...params} />
        <InvitedToEventAndResponded {...params} />

        <CreatedInviteToProject {...params} />
        <CreatedInviteToEvent {...params} />

        {/*<QuestionPartners {...params} />*/}
        {/*<InitiatedQuestionPartners {...params} />*/}
        <ContributionYear {...params} />
      </Metrics>
    </>
  );
}
