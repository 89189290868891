import React, { useState } from 'react';
import { useDialog } from '@lore/dialogs';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import PageHeading from '../_components/PageHeading';
import Table from '../explore-partners/Table';
import SidePanel from '../explore-events/SidePanel';
import ParentLayout from '../explore/Layout';
import useRouter from '../../hooks/useRouter';
import initialColumns from '../explore-events/columns';
import Columns from '../explore-partners/Table/Columns';
import useQuery from './useQuery';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const { location, history } = useRouter();

  const connect = useConnect();
  const show = useDialog();

  function onClick(event) {
    show(
      <SidePanel
        event={event}
        primaryAction="complete_event"
        extraActions={[
          'publish_event',
          'delete_event'
        ]}
      />
    );
  }

  const PAGE_SIZE = 10;

  const [columns, setColumns] = useState(initialColumns);

  const selectedColumns = columns.filter(column => column.selected)

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main id="scrollAnchor" className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Explore', 'Events']}
          title="Completed"
          description={(
            <span>
              These are events where an educator indicated they completed an event. CommunityShare
              recommends finding ways to publicize successful events through media releases, social
              media, and presentations. Congratulating educators (and their supervisors) or partners
              (and their employers) after a successful event builds goodwill and momentum for
              community-engaged learning in your community.
            </span>
          )}
        />
        {/*<div className="px-4 py-4 sm:px-6 lg:px-8">*/}
        {/*<div className="px-2 py-4 sm:px-3 lg:px-4">*/}
        {/*  <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2">*/}
        {/*    <Filters*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*    <SearchTags*/}
        {/*      location={location}*/}
        {/*      history={history}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="px-2 py-4x sm:px-3 lg:px-4 mb-4 mt-8">
          <div className="bg-gray-50 rounded-xl px-2 py-4 sm:px-3 lg:px-4 space-y-2x">
            <div className="flex justify-end">
              <div className="w-1/4">
                <Columns
                  columns={columns}
                  setColumns={setColumns}
                />
              </div>
            </div>
          </div>
        </div>
        <Table
          pageSize={PAGE_SIZE}
          columns={selectedColumns}
          select={function (getState) {
            return useQuery({
              location,
              PAGE_SIZE,
              connect
            });
          }}
          row={function (event) {
            return (
              <tr key={event.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onClick(event)}>
                {selectedColumns.map(function (column) {
                  if (!column.row) {
                    return null;
                  }

                  return (
                    <column.row
                      key={column.id}
                      event={event}
                    />
                  );
                })}
              </tr>
            );
          }}
        />
      </main>
    </ParentLayout>
  );
}
