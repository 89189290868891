import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';
import useStewardshipFilter from '../useStewardshipFilter';
import useRouter from '../../../hooks/useRouter';

export default function SubjectFilter(props) {
  const { location, history } = useRouter();

  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      0,
      1,
      2,
      3
    ].map(function(value) {
      return {
        id: value + 1,
        data: {
          name: value === 3 ? `${value}+` :`${value}`
        }
      }
    })
  };

  const users = function(model) {
    return connect('user.find', {
      where: {
        eager: {
          $aggregations: [
            {
              type: 'count',
              alias: 'numberOfEducatorInteractions',
              relation: 'educatorInteractions'
            }
          ],
          $where: {
            numberOfEducatorInteractions: (model.id - 1) === 3 ? {
              $gte: model.id - 1
            } : (model.id - 1),
            isEducator: true,
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Interactions created"
      dropdownLabel1="They should have participated in"
      dropdownLabel2="of the following numbers of interactions:"
      queryUtils={getQueryUtils}
      filterName="educatorInteraction"
      queryParamName="educatorInteractions"
      tags={models}
      related={users}
    />
  );
};
