import React from 'react';
import Sidebar from '../_components/Sidebar';
import SearchHeader from '../_components/SearchHeader';
import getNavigationLinks from './navigationLinks';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const navigationLinks = getNavigationLinks();

  return (
    <>
      {props.children}
    </>
  );

  return (
    <>
      <Sidebar
        title="Explore"
        navigation={navigationLinks}
        grouped={true}
        sidebarOpen={sidebarOpen}
      />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <SearchHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        {props.children}
      </div>
    </>
  )
}
