import React  from 'react';
import { useConnect } from '@lore/connect';

export default function Educators(props) {
  const { offer } = props;

  const invited = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: 'Pending'
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    },
    include: function(model) {
      return (
        model.data.offerId === offer.id &&
        model.data.response === 'Pending'
      )
    },
    exclude: function(model) {
      return (
        model.data.offerId !== offer.id ||
        model.data.response !== 'Pending'
      );
    }
  });

  const declined = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: 'Decline'
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    },
    include: function(model) {
      return (
        model.data.offerId === offer.id &&
        model.data.response === 'Decline'
      )
    },
    exclude: function(model) {
      return (
        model.data.offerId !== offer.id ||
        model.data.response !== 'Decline'
      );
    }
  });

  const connected = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: 'Connect'
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    },
    include: function(model) {
      return (
        model.data.offerId === offer.id &&
        model.data.response === 'Connect'
      )
    },
    exclude: function(model) {
      return (
        model.data.offerId !== offer.id ||
        model.data.response !== 'Connect'
      );
    }
  });

  return (
    <td className="px-6 py-4 whitespace-nowrap space-x-2">
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        {declined.data.length}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
        {invited.data.length}
      </span>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {connected.data.length}
      </span>
    </td>
  );
}
