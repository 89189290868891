import React from 'react';
import Sidebar from '../_components/Sidebar';
import SearchHeader from '../_components/SearchHeader';
// import navigationLinks from './navigationLinks';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  return (
    <>
      <Sidebar
        title="Coordinate"
        // navigation={navigationLinks}
        sidebarOpen={sidebarOpen}
      />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <SearchHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <h3 className="font-bold text-xl text-gray-900 sm:text-2xl px-5 py-4">
          Placeholder: Coordinate
        </h3>
      </div>
    </>
  )
}
