import React  from 'react';
import PropTypes from 'prop-types';
import { useConfig } from '@lore/config';
import { getCommunity } from '../../utils/community';

export default function Network(props) {
  const { minimal = false, horizontal = false, searchHeader = false } = props;

  const { tenant } = useConfig();

  const community = getCommunity();
  // const account = getCommunity();

  const src = tenant.networkLogo[community];
  const name = tenant.networkName[community];

  if (searchHeader) {
    return (
      <div className="flex flex-row mx-6x items-center">
        <div className="flex items-center h-10 w-10 border border-gray-300 shadow-lgx rounded overflow-hidden bg-white">
          <img className="h-12 w-12 object-contain" src={src} alt="logo" />
        </div>
        <div className="ml-2 flex-1">
          <div className="text-xs font-normal text-white leading-4">
            My network
          </div>
          <div className="text-sm text-white font-semibold leading-4 text-wrap w-full line-clamp-1">
            {name}
          </div>
        </div>
      </div>
    );
  }

  if (horizontal) {
    return (
      <>
        {/*<div className="h-12 ml-4 mr-8 border-r border-gray-300"/>*/}
        <div className="flex flex-row mx-6x items-center mt-4 mx-4">
          <div className="flex items-center h-12 w-12 border border-gray-300 shadow-lgx rounded overflow-hidden bg-white">
            {/*<div className="bg-gray-300 w-1"/>*/}
            <img className="h-12 w-12 object-contain" src={src} alt="logo" />
          </div>
          <div className={`ml-2 ${minimal ? 'hidden md:blockx': ''}`}>
            <div className="text-xs text-orange-100 leading-4">
              My network
            </div>
            <div className="text-sm text-orange-100 font-semibold leading-4 text-wrap w-full">
              {name}
            </div>
          </div>
        </div>
      </>
    );
  }

  if (src) {
    return (
      <>
        {/*<div className="w-16 mt-4 mb-4 border-t border-orange-200"/>*/}
        <div className="w-16 mt-4 mb-4 border-tx border-orange-200x"/>
        <div className="flex flex-col mx-6x items-center">
          <div className="flex items-center h-16 w-16 border border-gray-300 shadow-lgx rounded overflow-hidden bg-white">
            {/*<div className="bg-gray-300 w-1"/>*/}
            <img className="h-16 w-16 object-contain" src={src} alt="logo" />
          </div>
          <div className={`mt-2 mx-4 ${minimal ? 'hidden md:blockx': ''}`}>
            <div className="text-xs text-orange-100 leading-4">
              My network
            </div>
            <div className="text-sm text-orange-100 font-semibold leading-4 text-wrap w-full">
              {name}
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
};
