import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import PageHeading from '../_components/PageHeading';
import ParentLayout from '../dashboard/Layout';
import useAccount from '../../hooks/useAccount';
import Loader from '../_components/Loader';
import Project from './Project';
import Event from './Event';
import Offer from './Offer';
import Question from './Question';
import Invitation from './Invitation';
import Claim from './Claim';
import EvaluationProjectEducator from './EvaluationProjectEducator';
import EvaluationProjectPartner from './EvaluationProjectPartner';
import EvaluationEventEducator from './EvaluationEventEducator';
import EvaluationEventPartner from './EvaluationEventPartner';
import Message from './Message';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  const [timestamp] = useState(moment().toISOString());

  const account = useAccount();

  const params = {
    createdAt: {
      $gte: moment(timestamp).subtract(4, 'weeks').toISOString()
    }
  };

  const pagination = {
    pagination: {
      pageSize: 1000,
      order: 'createdAt desc'
    }
  }

  const projects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          ...params
        }
      }
    },
    ...pagination
  });

  const events = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          ...params
        }
      }
    },
    ...pagination
  });

  const offers = useConnect('offer.find', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          ...params
        }
      }
    },
    ...pagination
  });

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          'project.accountId': account.id,
          ...params
        }
      }
    },
    ...pagination
  });

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          'event.accountId': account.id,
          ...params
        }
      }
    },
    ...pagination
  });

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          'offer.accountId': account.id,
          ...params
        }
      }
    },
    ...pagination
  });

  const evaluationProjectEducators = useConnect('evaluationProjectEducator.find', {
    where: {
      eager: {
        $where: {
          'project.accountId': account.id,
          ...params
        }
      }
    },
    ...pagination
  });

  const evaluationProjectPartners = useConnect('evaluationProjectPartner.find', {
    where: {
      eager: {
        $where: {
          'project.accountId': account.id,
          ...params
        }
      }
    },
    ...pagination
  });

  const evaluationEventEducators = useConnect('evaluationEventEducator.find', {
    where: {
      eager: {
        $where: {
          'event.accountId': account.id,
          ...params
        }
      }
    },
    ...pagination
  });

  const evaluationEventPartners = useConnect('evaluationEventPartner.find', {
    where: {
      eager: {
        $where: {
          'event.accountId': account.id,
          ...params
        }
      }
    },
    ...pagination
  });

  const messages = useConnect('message.find', {
    where: {
      eager: {
        $where: {
          $or: {
            'sender.accountId': account.id,
            'recipient.accountId': account.id
          },
          ...params
        }
      }
    },
    ...pagination
  });

  if (
    projects.state === PayloadStates.FETCHING ||
    events.state === PayloadStates.FETCHING ||
    offers.state === PayloadStates.FETCHING ||
    questions.state === PayloadStates.FETCHING ||
    invitations.state === PayloadStates.FETCHING ||
    claims.state === PayloadStates.FETCHING ||
    evaluationProjectEducators.state === PayloadStates.FETCHING ||
    evaluationProjectPartners.state === PayloadStates.FETCHING ||
    evaluationEventEducators.state === PayloadStates.FETCHING ||
    evaluationEventPartners.state === PayloadStates.FETCHING ||
    messages.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <PageHeading
            breadcrumbs={['Dashboard']}
            title="Feed (superuser only)"
            description="List of projects, events, offers, invitations, evaluations, and messages created over the last 4 weeks."
          >
          </PageHeading>
          <div className="px-2 py-4 sm:px-3 lg:px-4 max-w-3xl">
            <Loader />
          </div>
        </main>
      </ParentLayout>
    );
  }

  let items = [];

  const typeMap = {
    'project': Project,
    'event': Event,
    'offer': Offer,
    'question': Question,
    'invitation': Invitation,
    'claim': Claim,
    'evaluation-project-educator': EvaluationProjectEducator,
    'evaluation-project-partner': EvaluationProjectPartner,
    'evaluation-event-educator': EvaluationEventEducator,
    'evaluation-event-partner': EvaluationEventPartner,
    'message': Message
  };

  const fieldMap = {
    'project': 'project',
    'event': 'event',
    'offer': 'offer',
    'question': 'question',
    'invitation': 'invitation',
    'claim': 'claim',
    'evaluation-project-educator': 'evaluation',
    'evaluation-project-partner': 'evaluation',
    'evaluation-event-educator': 'evaluation',
    'evaluation-event-partner': 'evaluation',
    'message': 'message'
  }

  items = items.concat(
    projects.data.map((value, index) => ({
      type: 'project',
      value: (
        <Project
          key={`project-${index}`}
          project={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    events.data.map((value, index) => ({
      type: 'event',
      value: (
        <Event
          key={`event-${index}`}
          event={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    offers.data.map((value, index) => ({
      type: 'offer',
      value: (
        <Offer
          key={`offer-${index}`}
          offer={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    questions.data.map((value, index) => ({
      type: 'question',
      value: (
        <Question
          key={`question-${index}`}
          question={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    invitations.data.map((value, index) => ({
      type: 'invitation',
      value: (
        <Invitation
          key={`invitation-${index}`}
          invitation={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    claims.data.map((value, index) => ({
      type: 'claim',
      value: (
        <Claim
          key={`claim-${index}`}
          claim={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    evaluationProjectEducators.data.map((value, index) => ({
      type: 'evaluation-project-educator',
      value: (
        <EvaluationProjectEducator
          key={`evaluation-project-educator-${index}`}
          evaluation={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    evaluationProjectPartners.data.map((value, index) => ({
      type: 'evaluation-project-partner',
      value: (
        <EvaluationProjectPartner
          key={`evaluation-project-partner-${index}`}
          evaluation={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    evaluationEventEducators.data.map((value, index) => ({
      type: 'evaluation-event-educator',
      value: (
        <EvaluationEventEducator
          key={`evaluation-event-educator-${index}`}
          evaluation={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    evaluationEventPartners.data.map((value, index) => ({
      type: 'evaluation-event-partner',
      value: (
        <EvaluationEventPartner
          key={`evaluation-event-partner-${index}`}
          evaluation={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  ).concat(
    messages.data.map((value, index) => ({
      type: 'message',
      value: (
        <Message
          key={`message-${index}`}
          message={value}
        />
      ),
      data: value,
      timestamp: value.data.createdAt
    }))
  );

  // items = _.sortBy(items, 'timestamp');

  items = _.sortBy(items, function(item) {
    return -moment(item.timestamp).unix();
  });

  return (
    <ParentLayout sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageHeading
          breadcrumbs={['Dashboard']}
          title="Feed (superuser only)"
          description="List of projects, events, offers, invitations, evaluations, and messages created over the last 4 weeks."
        >
        </PageHeading>
        <div className="px-2 py-4 sm:px-3 lg:px-4 max-w-3xl">
          <ul role="list" className="space-y-6">
            {items.map(function(item, index) {
              const Component = typeMap[item.type];

              return (
                <Component
                  key={index}
                  last={index === items.length - 1}
                  {...{
                    [fieldMap[item.type]]: item.data
                  }}
                />
              );
            })}
          </ul>
        </div>
      </main>
    </ParentLayout>
  );
}
