import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import CloseProjectReminderDialog from '../../../../dialogs/project/CloseProjectReminder';

export default function ReminderClose(props) {
  const {
    project
  } = props;

  const show = useDialog();

  function onClick() {
    show(
      <CloseProjectReminderDialog
        project={project}
      />, { template: 'dialog' }
    );
  }

  return (
    <SecondaryAction onClick={onClick} secret={true}>
      Send reminder to close project
    </SecondaryAction>
  );
}
