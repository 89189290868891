import React  from 'react';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';
import useUserTags from '../../../../../hooks/useUserTags';
import TagTypes from '../../../../../constants/TagTypes';

export default function Experience(props) {
  const { user } = props;

  const userExperiences = useUserTags(user, TagTypes.Experience);

  if (userExperiences.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  return (
    <TextColumn>
      {userExperiences.data.map(function(userExperience) {
        return userExperience._tag.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
