import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import useAccount from '../../hooks/useAccount';
import useRouter from '../../hooks/useRouter';
import REQUIRED_FIELDS from './REQUIRED_FIELDS';

export default function useStewardshipFilter(props) {
  const { location } = useRouter();
  const query = parse(location.search);

  const account = useAccount();

  if (query.accounts === '0') {
    return [
      { accountId: account.id },
      // {
      //   published: true,
      //   $and: REQUIRED_FIELDS.$and
      // }
    ];
  }

  return [
    { accountId: account.id }
  ];
};
