import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import useStewardshipFilter from '../explore-events/useStewardshipFilter';
import REQUIRED_FIELDS_PUBLIC from '../explore-events/REQUIRED_FIELDS_PUBLIC';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  return connect('event.find', {
    where: {
      eager: {
        $where: {
          // $and: REQUIRED_FIELDS_PUBLIC.$and,
          completed: true,
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    },
    exclude: function(model) {
      return model.state === PayloadStates.DELETED;
    }
  });
}
