import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Reflections(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Reflections"
      // subtitle="Please rate any changes you observed with your learner(s) as a result of the interaction."
    >
      <TextField label="What led you to this learning journey?">
        {evaluation.data.journey || '-'}
      </TextField>
      <TextField label="What adventures did you go on?">
        {evaluation.data.adventures || '-'}
      </TextField>
      <TextField label="What challenges did you face along the way and how did you work to overcome them?">
        {evaluation.data.challenges || '-'}
      </TextField>
      <TextField label="How were you, your students and/or your partners changed by this journey?">
        {evaluation.data.changes || '-'}
      </TextField>
      <TextField label="Looking back, what lessons did you learn that might help other teachers about to begin their first community-engaged learning journey?">
        {evaluation.data.lessons || '-'}
      </TextField>
    </Section>
  );
}
