import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import PayloadStates from '../../../constants/PayloadStates';
import moment from 'moment-timezone';
import useStewardshipFilter from '../useStewardshipFilter';

JoinedFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function JoinedFilter(props) {
  const { location, history, timestamp } = props;

  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const models = {
    state: PayloadStates.RESOLVED,
    data: [
      // ['lte_1_weeks', 'Within 1 week'],
      // ['lte_2_weeks', 'Within 2 weeks'],
      // ['lte_4_weeks', 'Within 1 month'],
      // ['lte_8_weeks', 'Within 2 months'],
      // ['lte_13_week', 'Within 3 months'],
      // ['lte_26_week', 'Within 6 months'],
      // ['lte_52_week', 'Within the year']
      [1, 'Within 1 week'],
      [2, 'Within 2 weeks'],
      [4, 'Within 1 month'],
      [8, 'Within 2 months'],
      [13, 'Within 3 months'],
      [26, 'Within 6 months'],
      [52, 'Within the year']
    ].map(function(value) {
      // const weeks = Number(value[0].split('_')[1]);
      return {
        // id: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
        id: value[0],
        data: {
          name: value[1]
        }
      }
    })
  };

  const related = function(model) {
    // const weeks = Number(model.id.split('_')[1]);
    const weeks = model.id;

    return connect('project.find', {
      where: {
        eager: {
          $where: {
            createdAt: {
              $gte: moment(timestamp).subtract(weeks, 'weeks').toISOString(),
            },
            activityId: {
              $not: null
            },
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Created"
      // dropdownLabel1="They should teach"
      // dropdownLabel2="of the following subjects:"
      queryUtils={getQueryUtils}
      filterName="createdDate"
      queryParamName="createdDates"
      tags={models}
      related={related}
      timestamp={timestamp}
    />
  );
};
