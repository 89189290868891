import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import { SearchIcon } from '@heroicons/react/outline';
import getQueryUtils, { attributeKeys } from '../../queryUtils';
import StringField from '../../../../forms/_fields/StringField';
import useRouter from '../../../../hooks/useRouter';

export default function SearchFilter(props) {
  const { label, placeholder, param, tooltip, description, timestamp } = props;

  const { location, history } = useRouter();
  const searchParams = getQueryUtils(parse(location.search), attributeKeys, timestamp).params;

  function onChange(value) {
    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, parse(location.search), {
        [param]: value,
        page: 1
      }))
    });
  }

  return (
    <StringField
      label={label}
      icon={SearchIcon}
      placeholder={placeholder}
      value={searchParams[param]}
      tooltip={tooltip}
      description={description}
      onChange={onChange}
    />
  );
};
