import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from '../../utils/classNames';
import useRouter from '../../hooks/useRouter';
import useAccount from '../../hooks/useAccount';

export default function Header(props) {
  const { location  } = useRouter();
  const account = useAccount();

  const root = `/c/${account.data.subdomain}`;

  const tabs = [
    { name: 'Projects', href: `${root}/explore/projects`, href2: '/explore/projects/new' },
    { name: 'Events', href: `${root}/explore/events`, href2: '/explore/events/new' },
    { name: 'Offers', href: `${root}/explore/offers`, href2: '/explore/offers/new' },
    { name: 'Partners', href: `${root}/explore/partners`, href2: '/explore/partners/new' },
    { name: 'Educators', href: `${root}/explore/educators`, href2: '/explore/educators/new' }
  ];

  return (
    <div className="px-4 pt-6 pb-4 sm:px-6 shadow z-10 relative space-y-4">
      <div className="md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Explore
          </h2>
          {/*<div className="mt-2 max-w-4xl text-sm text-gray-500">*/}
          {/*  These are projects that you starred. CommunityShare recommends using this page to help*/}
          {/*  track projects you want to pay special attention to.*/}
          {/*</div>*/}
        </div>
      </div>
      <div>
        <div
          // className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          className="flex space-x-2"
        >
          {tabs.map(function(tab) {
            const current = location.pathname.indexOf(tab.href) >= 0;

            return (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  // current ? 'bg-gray-200 text-gray-900x text-accent font-medium' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50 font-normal',
                  current ? 'bg-orange-100 text-gray-900x text-accent font-semibold' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50 font-normal',
                  // 'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                  'group flex items-center px-3 py-2 text-sm rounded-md'
                )}
                aria-current={current ? 'page' : undefined}
              >
                {tab.name}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
