import React from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import TagTypes from '../../../constants/TagTypes';
import useOfferTags from '../../../hooks/useOfferTags';

export default function Logistics(props) {
  const { offer } = props;

  const currentUser = useUser();

  const offerTimes = useOfferTags(offer, TagTypes.Time);
  const offerInteractionTypes = useOfferTags(offer, TagTypes.InteractionType);

  return (
    <Section
      title="Project Logistics"
      // subtitle="Date, time, cost, and supplies."
    >
      {offer.data.specificDate === 'Yes' ? (
        <TextField label="Date">
          {moment(offer.data.date).format('MMM D, YYYY')}
        </TextField>
      ) : (
        <TextField label="Date Window">
          {moment(offer.data.startDate).format('MMM Do, YYYY')} - {moment(offer.data.endDate).format('MMM Do, YYYY')}
        </TextField>
      )}
      {offer.data.specificTime === 'Yes' ? (
        <TextField label="Time">
          {`${moment(offer.data.startTime).tz(currentUser.data.timezone).format('h:mm a')} - ${moment(offer.data.endTime).tz(currentUser.data.timezone).format('h:mm a')}`}
        </TextField>
      ) : (
        <TextField label="Possible Times of Day">
          {offerTimes.data.map(function(offerTime) {
            return (
              <div key={offerTime.id || offerTime.cid}>
                {offerTime._tag.data.name}
              </div>
            );
          })}
          {offerTimes.data.length === 0 && '-'}
        </TextField>
      )}
      <TextField label="Location Type">
        {offerInteractionTypes.data.map(function(offerInteractionType) {
          return (
            <div key={offerInteractionType.id || offerInteractionType.cid}>
              {offerInteractionType._tag.data.name}
            </div>
          );
        })}
        {offerInteractionTypes.data.length === 0 && '-'}
      </TextField>
    </Section>
  )
}
