import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import { insertOrRemoveNumberFromArray } from '../../../../utils/query';
import { PayloadStates } from '@lore/utils';
import TagChip from '../../../../components/_chips/TagChip';
// import queryUtils from '../../../utils/user/query';
import getQueryUtils, { attributeKeys } from '../../queryUtils';
import useRouter from '../../../../hooks/useRouter';
import useTags from '../../../../hooks/useTags';

export default function TagSet(props) {
  const { label, type, param, timestamp } = props;

  const { location, history } = useRouter();
  const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);
  const searchParams = queryUtils.params;
  // const searchParams = queryUtils.getSearchParamsFromQuery(parse(location.search));

  function onChange(tag) {
    const newQuery = insertOrRemoveNumberFromArray(parse(location.search), param, tag.id);

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, newQuery, {
        page: 1
      }))
    });
  }

  const tags = useTags(type);

  if (tags.state === PayloadStates.FETCHING) {
    return null;
  }

  return tags.data.filter(function(tag) {
    return searchParams[param] && searchParams[param].indexOf(tag.id) >= 0;
  }).map(function(tag) {
    return (
      <TagChip
        key={tag.id}
        tag={tag}
        type={label}
        showType={true}
        onClick={() => onChange(tag)}
        onCancel={() => onChange(tag)}
      />
    );
  });
};
