import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import { PayloadStates } from '@lore/utils';
import EducatorSidePanel from '../../explore-educators/SidePanel';
import PartnerSidePanel from '../../explore-partners/SidePanel';
import ProjectSidePanel from '../../explore-projects/SidePanel';
import moment from 'moment-timezone';
import marked from 'marked';

function Loader() {
  return (
    <Section>
      <TextField label="Partner">
        ...
      </TextField>
      <TextField label="Project">
        ...
      </TextField>
      <TextField label="Recommended By">
        ...
      </TextField>
      <TextField label="Date">
        ...
      </TextField>
      <TextField label="Message">
        ...
      </TextField>
    </Section>
  );
}

export default function People(props) {
  const { recommendation } = props;

  const connect = useConnect();
  const show = useDialog();

  const project = connect('project.byId', {
    id: recommendation.data.projectId
  });

  const partner = connect('user.byId', {
    id: recommendation.data.partnerId
  });

  const creator = connect('user.byId', {
    id: recommendation.data.creatorId
  });

  if (
    project.state === PayloadStates.FETCHING ||
    partner.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const activity = connect('tag.byId', {
    id: project.data.activityId
  });

  function onClickPartner() {
    show(
      <PartnerSidePanel user={partner} />
    );
  }

  function onClickCreator() {
    if (creator.data.isEducator) {
      show(
        <EducatorSidePanel user={creator} />
      );
    } else {
      show(
        <PartnerSidePanel user={creator} />
      );
    }
  }

  function onClickProject() {
    show(
      <ProjectSidePanel project={project} />
    );
  }

  return (
    <Section
      // title="Educator information"
      // subtitle="Name and contact info."
    >
      <TextField label="Partner">
        <span onClick={onClickPartner} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {partner.data.firstName} {partner.data.lastName}
        </span>
      </TextField>
      <TextField label="Project">
        <span onClick={onClickProject} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {project.data.title || activity.data.name}
        </span>
      </TextField>
      <TextField label="Recommended By">
        <span onClick={onClickCreator} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {creator.data.firstName} {creator.data.lastName}
        </span>
      </TextField>
      <TextField label="Date">
        {moment(recommendation.data.dateCreated).format('MMM Do, YYYY')}
      </TextField>
      <TextField label="Message">
        <p
          className="space-y-2"
          dangerouslySetInnerHTML={{__html: marked(recommendation.data.message || '')}}
        />
      </TextField>
    </Section>
  );
}
