import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import InviteEducatorsReminderDialog from '../../../../dialogs/offer/InviteEducatorsReminder';
import { useUser } from '@lore/auth';

export default function ReminderInvite(props) {
  const {
    offer
  } = props;

  const show = useDialog();

  function onClick() {
    show(
      <InviteEducatorsReminderDialog
        offer={offer}
      />, { template: 'dialog' }
    );
  }

  return (
    <SecondaryAction onClick={onClick} secret={true}>
      Send reminder to invite more educators
    </SecondaryAction>
  );
}
