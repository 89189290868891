import React from 'react';
import { useConnect } from '@lore/connect';
import useRouter from '../../hooks/useRouter';

// Projects
import starredProjects from '../explore-projects-starred/useQuery';
import expiringProjects from '../explore-projects-expiring/useQuery';
import expiredProjects from '../explore-projects-expired/useQuery';
import draftProjects from '../explore-projects-draft/useQuery';
import newProjects from '../explore-projects-new/useQuery';
import activeProjects from '../explore-projects-active/useQuery';
import partnersInvitedProjects from '../explore-projects-partners-invited/useQuery';
import partnersConnectedProjects from '../explore-projects-partners-connected/useQuery';
import wantsContactProjects from '../explore-projects-wants-contact/useQuery';
import resourcesNeededProjects from '../explore-projects-resources-needed/useQuery';
import fundingRequestedProjects from '../explore-projects-funding-requested/useQuery';
import expiredNotClosedProjects from '../explore-projects-expired-not-closed/useQuery';
import missingEducatorEvaluationProjects from '../explore-projects-missing-educator-evaluation/useQuery';
import completedProjects from '../explore-projects-completed/useQuery';
import schoolYearProjects from '../explore-projects-school-year/useQuery';

// Partners
import questionsPartners from '../explore-partners-questions/useQuery';
import useAccount from '../../hooks/useAccount';

export default function getNavigationLinks() {
  const { location } = useRouter();
  const connect = useConnect();
  const account = useAccount();

  function getCount(query) {
    const result = query({ location, connect });
    return result.meta ? result.meta.totalCount : '...';
  }

  const root = `/c/${account.data.subdomain}`;

  return [
      { name: 'Starred', href: `${root}/explore/projects/starred`, count: getCount(starredProjects) },
      { name: 'New', href: `${root}/explore/projects/new`, count: getCount(newProjects), highlight: true },
      { name: 'Incomplete', href: `${root}/explore/projects/draft`, count: getCount(draftProjects) },
      { name: 'Wants Contact', href: `${root}/explore/projects/wants-contact`, count: getCount(wantsContactProjects), highlight: true },
      { name: 'No Partners Invited', href: `${root}/explore/projects/partners-invited`, count: getCount(partnersInvitedProjects), highlight: true },
      { name: 'No Partners Connected', href: `${root}/explore/projects/partners-connected`, count: getCount(partnersConnectedProjects) },
      { name: 'Pending Invitations', href: `${root}/explore/projects/questions`, count: getCount(questionsPartners) },
      { name: 'Active Projects', href: `${root}/explore/projects/active`, count: getCount(activeProjects) },
      { name: 'Needs Resources', href: `${root}/explore/projects/resources-needed`, count: getCount(resourcesNeededProjects) },
      { name: 'Requested Funding', href: `${root}/explore/projects/funding-requested`, count: getCount(fundingRequestedProjects), highlight: true },
      { name: 'Expiring', href: `${root}/explore/projects/expiring`, count: getCount(expiringProjects), highlight: true },
      // { name: 'Recently Expired', href: `${root}/explore/projects/expired`, count: getCount(expiredProjects) },
      { name: 'Expired but not Closed', href: `${root}/explore/projects/expired-not-closed`, count: getCount(expiredNotClosedProjects), highlight: true },
      { name: 'Missing Educator Evaluation', href: `${root}/explore/projects/missing-educator-evaluation`, count: getCount(missingEducatorEvaluationProjects) },
      { name: 'Completed', href: `${root}/explore/projects/completed`, count: getCount(completedProjects) },
      { name: 'All Projects this School Year', href: `${root}/explore/projects/school-year`, count: getCount(schoolYearProjects) },
      { name: 'All', href: `${root}/explore/projects` }
  ];
}
