import React from 'react';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';

export default function Partner(props) {
  const { event } = props;

  const eventExpertiseTypes = useEventTags(event, TagTypes.ExpertiseType);

  return (
    <Section
      title="Ideal Partner"
      // subtitle="The ideal partner has experience with some or all of these topics."
    >
      <TextField label="Partners Needed">
        {event.data.slots || '-'}
      </TextField>
      <TextField label="Experience">
        {eventExpertiseTypes.data.map(function(eventExpertiseType) {
          return (
            <div key={eventExpertiseType.id || eventExpertiseType.cid}>
              {eventExpertiseType._tag.data.name}
            </div>
          );
        })}
        {eventExpertiseTypes.data.length === 0 && '-'}
      </TextField>
    </Section>
  )
}
