import React  from 'react';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../../_components/table/columns/TextColumn';
import useMissingFields from '../useMissingFields';

export default function MissingFields(props) {
  const { project } = props;

  const { missingFields } = useMissingFields(project);

  if (missingFields.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  return (
    <TextColumn>
      {missingFields.data.map(function(field) {
        return field.label;
      }).join(', ')}
    </TextColumn>
  );
}
