import React from 'react';
import Metrics from '../../dashboard-year/Metrics';
import ActivityByMonth from './ActivityByMonth';
import GrowthByMonth from './GrowthByMonth';
import ProjectActivity from './ProjectActivity';
import ProjectActivityTreemap from './ProjectActivity.treemap';
import ProjectResponse from './ProjectResponse';
import ProjectStatus from './ProjectStatus';
import Total from './Total';
import Students from './Students';

export default function Projects(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Projects">
        {/*<ProjectActivity {...params} subtitle="For all school years" />*/}
        <ProjectActivityTreemap {...params} subtitle="For all school years" />
        <ProjectResponse {...params} subtitle="For all school years" />
        <ProjectStatus {...params} subtitle="For all school years" />
      </Metrics>
      <Metrics>
        <Total {...params} />
        <Students {...params} />
      </Metrics>
    </>
  );
}
