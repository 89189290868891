import React from 'react';
import Metrics from '../../dashboard-year/Metrics';
import SharedProject from './SharedProject';
import SharedEvent from './SharedEvent';
import Total from './Total';
import TotalComplete from './TotalComplete';
import InvitedToOffer from './InvitedToOffer';
import InvitedToOfferAndResponded from './InvitedToOfferAndResponded';
import CreatedInviteToOffer from './CreatedInviteToOffer';

export default function Educators(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Educators">
        <Total {...params} />
        <TotalComplete {...params} />
        <SharedProject {...params} />
        <SharedEvent {...params} />
        <InvitedToOffer {...params} />
        <InvitedToOfferAndResponded {...params} />
        <CreatedInviteToOffer {...params} />
      </Metrics>
    </>
  );
}
