import React from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import useTags from './useTags';
import useTagTrees from './useTagTrees';

export default function useUserTags(user, type, timestamp, _connect) {
  const tags = useTags();
  const tagTrees = useTagTrees();

  const connect = _connect || useConnect();

  const extraArgs = timestamp ? {
    createdAt: {
      $lte: timestamp
    }
  } : {};

  const tagNodes = connect('tagNode.find', {
    where: {
      eager: {
        $where: {
          'tag.type': type,
          userId: user.id,
          ...extraArgs,
        }
      }
    },
    pagination: {
      pageSize: 1000
    },
    include: function(tagNode) {
      const tag = _.find(tags.data, tag => tag.id === tagNode.data.tagId);

      return (
        tagNode.data.userId === user.id &&
        tag.data.type === type
      );
    },
    exclude: function(tagNode) {
      return tagNode.state === PayloadStates.DELETED;
    }
  });

  return {
    state: tagNodes.state,
    data: tagNodes.data.map(function(tagNode) {
      const tag = _.find(tags.data, tag => tag.id === tagNode.data.tagId);
      const tagTree = _.find(tagTrees.data, tagTree => tagTree.data.childId === tagNode.data.tagId);
      let parentTag = null;

      if (tagTree) {
        parentTag = _.find(tags.data, tag => tag.id === tagTree.data.parentId);
      }

      return {
        id: tagNode.id,
        cid: tagNode.cid,
        data: tag.data,
        _tag: tag,
        _tagNode: tagNode,
        _tagTree: tagTree,
        _parentTag: parentTag,
        _meta: 'generated in useUserTags',
      }
    })
  }
}
