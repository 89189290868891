import React from 'react';
import { useConnect } from '@lore/connect';
import Section from '../../_components/sidePanel/Section';
import { PayloadStates } from '@lore/utils';
import QuestionSidePanel from '../../explore-questions/SidePanel';
import { useDialog } from '@lore/dialogs';
import moment from 'moment-timezone';

export default function Questions(props) {
  const { user } = props;

  const connect = useConnect();

  const questions = connect('question.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          projectId: {
            $not: null
          }
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  const show = useDialog();

  function onClick(question) {
    show(
      <QuestionSidePanel question={question} />
    );
  }

  // if (
  //   questions.state === PayloadStates.FETCHING ||
  //   questions.data.length === 0
  // ) {
  //   return null;
  // }

  return (
    <Section
      title="Projects"
      // subtitle="Past projects I've been involved with."
      className="bg-gray-50"
    >
      <div className="space-y-3">
        {questions.data.map(function(question) {
          const project = connect('project.byId', {
            id: question.data.projectId
          });

          if (project.state === PayloadStates.FETCHING) {
            return (
              <div
                key={question.id}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                {/*<div className="flex-shrink-0">*/}
                {/*  <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />*/}
                {/*</div>*/}
                <div className="flex-1 min-w-0">
                  <a href="#" className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">
                      ...
                    </p>
                    <p className="text-sm text-gray-500 truncate">
                      ...
                    </p>
                  </a>
                </div>
              </div>
            );
          }

          const activity = connect('tag.byId', {
            id: project.data.activityId
          });

          const colorMap = {
            'Pending': 'bg-blue-400',
            'Connect': 'bg-green-400',
            'Decline': 'bg-red-400',
          };

          const responseMap = {
            'Pending': 'Pending',
            'Connect': 'Connected',
            'Decline': 'Declined',
          };

          const responseColor = colorMap[question.data.response] || 'bg-gray-400';

          return (
            <div
              key={question.id}
              className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              onClick={() => onClick(question)}
            >
              {/*<div className="flex-shrink-0">*/}
              {/*  <img className="h-10 w-10 rounded-full" src={person.imageUrl} alt="" />*/}
              {/*</div>*/}
              <div className="flex-1 min-w-0">
                <a href="#" className="focus:outline-none">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900">
                    {project.data.title || activity.data.name || '...'}
                  </p>
                  <p className="text-sm text-gray-500 truncate mb-2">
                    {moment(project.data.startDate).format('MMM D, YYYY')} - {moment(project.data.endDate).format('MMM D, YYYY')}
                  </p>
                  <p className="text-sm text-gray-500 truncatex flex space-x-2.5">
                    <span className={`mr-2.5x mt-1.5 ${responseColor} flex-shrink-0 inline-block h-2 w-2 rounded-full`}>
                      <span className="sr-only">{question.data.response}</span>
                    </span>
                    <span>
                      {responseMap[question.data.response] || question.data.response}
                      {question.data.response === 'Decline' && (
                        <>
                          <span>{` · `}</span>
                          <span className="italic">{question.data.responseMessage}</span>
                        </>
                      )}
                    </span>
                  </p>
                </a>
              </div>
            </div>
          );
        })}
        {questions.data.length === 0 && '-'}
      </div>
    </Section>
  )
}
