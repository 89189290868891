import React from 'react';
import { ChatAltIcon } from '@heroicons/react/solid';
import moment from 'moment-timezone';
import { useUser } from '@lore/auth';
import marked from 'marked';
import avatar from '../../../assets/images/avatar.svg';

export default function Comment(props) {
  const { model, title, imageSrc, system = false, tag } = props;

  const currentUser = useUser();

  // const timestamp = moment(note.data.createdAt).tz(currentUser.data.timezone).format('h:mm A, MMM D YYYY');
  const timestamp = moment(model.data.createdAt).tz(currentUser.data.timezone);
  const day = timestamp.format('MMM D, YYYY');
  const time = timestamp.format('h:mma');

  return (
    <div className="relative flex items-start space-x-3">
      <div className="relative">
        <img
          className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white object-cover"
          src={imageSrc || avatar}
          alt="Avatar"
        />
        <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
          <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </div>
      <div className="min-w-0 flex-1">
        <div>
          <div className="text-sm">
            <p className="font-medium text-gray-900">
              {title}
            </p>
          </div>
          <p className="mt-0.5 text-sm text-gray-500">
            {/*Commented {moment().diff(creator.data.createdAt, 'days')}d ago*/}
            {day} at {time} {tag}
          </p>
        </div>
        <div className="mt-2 text-sm text-gray-700">
          <span className="hidden" data-type="id">{model.id}</span>
          <span className="hidden" data-type="stage">{model.data.stage}</span>
          <span className="hidden" data-type="type">{model.data.type}</span>
          {/*<p>*/}
          {/*  {model.data.text}*/}
          {/*</p>*/}
          <p
            className={`space-y-2 ${system ? 'bg-yellow-50 border border-yellow-500 text-yellow-600 p-3 rounded-xl' : ''}`}
            dangerouslySetInnerHTML={{__html: marked(model.data.text)}}
          />
        </div>
      </div>
    </div>
  )
}
