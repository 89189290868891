import React from 'react';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Chart from '../Chart';
import REQUIRED_FIELDS from '../../explore-offers/REQUIRED_FIELDS';

export default function OfferResponseYear(props) {
  const { account, schoolStart, schoolEnd, subtitle } = props;

  const connect = useConnect();

  const responses = [
    'No invites',
    'Pending',
    'Connected',
    'Declined'
  ];

  const responseMap = {
    'No invites': {
      numberOfClaimsPending: 0,
      numberOfClaimsConnect: 0,
      numberOfClaimsDecline: 0
    },
    'Pending': {
      numberOfClaimsPending: {
        $gte: 1
      },
      numberOfClaimsConnect: 0,
      // numberOfClaimsDecline: 0
    },
    'Connected': {
      // numberOfClaimsPending: 0,
      numberOfClaimsConnect: {
        $gte: 1
      },
      // numberOfClaimsDecline: 0
    },
    'Declined': {
      numberOfClaimsPending: 0,
      numberOfClaimsConnect: 0,
      numberOfClaimsDecline: {
        $gte: 1
      }
    }
  };

  const requests = responses.map(function(response) {
    return {
      label: response,
      offers: connect('offer.find', {
        where: {
          eager: {
            $aggregations: [
              'Pending',
              'Connect',
              'Decline'
            ].map(function(response) {
              return {
                type: 'count',
                alias: `numberOfClaims${response}`,
                relation: 'claims',
                $where: {
                  response: response
                }
              };
            }),
            $where: {
              $and: REQUIRED_FIELDS.$and,
              activityId: {
                $not: null
              },
              ...responseMap[response],
              accountId: account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.offers.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <Chart name="Offers by Educator Status">
        <Loader />
      </Chart>
    );
  }

  const series = requests.map(r => r.offers.meta.totalCount);
  const labels = requests.map(r => r.label);

  const data = {
    series: series,
    options: {
      title: {
        text: 'Offers by Educator Status',
        align: 'center'
      },
      subtitle: {
        text: subtitle || 'For school year',
        align: 'center',
        offsetY: 20
      },
      labels: labels,
      chart: {
        type: 'donut',
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        offsetY: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true
              }
            }
          }
        }
      }
    }
  };

  return (
    <Chart
      // name="Offer activity types"
      className="col-span-1 pb-2"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="donut"
        height={350}
      />
    </Chart>
  );
}
