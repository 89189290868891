import React  from 'react';
import User from './User';

export default function Partner(props) {
  const { recommendation } = props;

  return (
    <User
      evaluation={{
        data: {
          userId: recommendation.data.partnerId
        }
      }}
    />
  );
}
