import React from 'react';
import { useConnect } from '@lore/connect';
import useRouter from '../../hooks/useRouter';
import useAccount from '../../hooks/useAccount';

// Projects
// import projectEducatorEvaluations from '../evaluations-educators/useQuery';
// import projectPartnerEvaluations from '../evaluations-partners/useQuery';

export default function getNavigationLinks() {
  const { location } = useRouter();
  const connect = useConnect();
  const account = useAccount();

  function getCount(query) {
    const result = query({ location, connect });
    return result.meta ? result.meta.totalCount : '...';
  }

  const root = `/c/${account.data.subdomain}`;

  return [
    {
      title: 'Projects',
      links: [
        { name: 'Educator Evaluations', href: `${root}/evaluations/projects/educators` },
        { name: 'Partner Evaluations', href: `${root}/evaluations/projects/partners` }
      ]
    },
    {
      title: 'Events',
      links: [
        { name: 'Educator Evaluations', href: `${root}/evaluations/events/educators` },
        { name: 'Partner Evaluations', href: `${root}/evaluations/events/partners` }
      ]
    }
  ];
}
