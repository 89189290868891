import React  from 'react';
import User from './User';
import { useConnect } from '@lore/connect';

export default function Educator(props) {
  const { claim } = props;

  const user = useConnect('user.byId', {
    id: claim.data.userId
  });

  return (
    <User user={user} />
  );
}
