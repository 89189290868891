import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import getQueryUtils, { attributeKeys } from '../queryUtils';
import StringField from '../../../forms/_fields/StringField';
import useRouter from '../../../hooks/useRouter';

export default function Title(props) {
  const { location, history } = useRouter();
  const { params: searchParams } = getQueryUtils(parse(location.search), attributeKeys, props.timestamp);

  function onChangeSearch(title) {
    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, parse(location.search), {
        title: title,
        page: 1
      }))
    });
  }

  return (
    <StringField
      label="Offer title"
      placeholder="Enter title..."
      value={searchParams.title}
      onChange={onChangeSearch}
    />
  );
};
