import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { PayloadStates } from '@lore/utils';
import { onChange, onChangeArray } from '../../../utils/searchTags';
import Tags from '../../../components/Tags';
import useRouter from '../../../hooks/useRouter';
import getQueryUtils, { attributeKeys } from '../queryUtils';

export default function Ethnicities(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();
  const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);
  const searchParams = queryUtils.params;

  return (
    <Tags
      type="Ethnicity"
      query={searchParams.ethnicities}
      models={{
        state: PayloadStates.RESOLVED,
        data: [
          'American Indian or Alaska Native',
          'Asian',
          'Black or African American',
          'Hispanic or Latino',
          'Native Hawaiian or Other Pacific Islander',
          'White',
          'Multiracial',
          'Other',
          'Prefer Not to Say'
        ].map(function(value, index) {
          return {
            id: index + 1,
            data: {
              name: value
            }
          }
        })
      }}
      onChange={onChangeArray('ethnicities', { location, history })}
    />
  );
};
