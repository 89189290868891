import React from 'react';
import moment from 'moment-timezone';

export default function Days(props) {
  const { claim } = props;

  // const [timestamp] = useState(moment());

  return (
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {moment().diff(claim.data.createdAt, 'days')} days ago
    </td>
  );
}
