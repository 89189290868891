import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import getQueryUtils from '../queryUtils';
import StringField from '../../../forms/_fields/StringField';
import useRouter from '../../../hooks/useRouter';

export default function Name(props) {
  const { location, history } = useRouter();

  const { params: searchParams } = getQueryUtils(parse(location.search), ['name']);

  function onChangeSearch(name) {
    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, parse(location.search), {
        name: name,
        page: 1
      }))
    });
  }

  return (
    <StringField
      label="Name"
      placeholder="Enter name..."
      value={searchParams.name}
      onChange={onChangeSearch}
    />
  );
};
