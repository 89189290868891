import React from 'react';
import classNames from '../../utils/classNames';
import useRouter from '../../hooks/useRouter';
import { useUser } from '@lore/auth';
import { Menu, Transition } from '@headlessui/react';
import { useConnect } from '@lore/connect';
import useAccount from '../../hooks/useAccount';
import { ActionTypes } from '@lore/utils';
import { useConfig } from '@lore/config';

function MenuItem(props) {
  const { href, label } = props;

  return (
    <Menu.Item>
      {({ active }) => (
        <a
          href={href}
          className={classNames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'block px-4 py-2 text-sm cursor-pointer'
          )}
        >
          {label}
        </a>
      )}
    </Menu.Item>
  );
}

function CommunityMenuItem(props) {
  const { label, onClick, isActive } = props;

  return (
    <Menu.Item>
      {({ active }) => (
        <div
          className={classNames(
            isActive ? 'bg-orange-50 text-accent font-semibold' :
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'block px-4 py-2 text-sm cursor-pointer'
          )}
          onClick={onClick}
        >
          {label}
        </div>
      )}
    </Menu.Item>
  );
}

export default function PrimaryMenuProfileItems(props) {
  const currentUser = useUser();
  const config = useConfig();

  const root = config.custom.platformUrl();

  const { history, location } = useRouter();

  const account = useAccount();
  const accounts = useConnect('account.find', {
    where: {
      eager: {
        $where: {
          'members.userId': currentUser.id,
          'members.isStaff': true
        }
      }
    },
    pagination: {
      order: 'name asc'
    }
  });

  function onSwitchCommunity(nextAccount) {
    const extension = location.pathname.split(`/c/${account.data.subdomain}/`)[1];
    const pathname = `/c/${nextAccount.data.subdomain}/${extension}`;
    history.push(pathname);

    lore.store.dispatch({
      type: ActionTypes.RESET_STORE,
      payload: {}
    });
  }

  return (
    <>
      {accounts.data.length > 1 && (
        <div className="py-1">
          <div className={'text-gray-500 block px-4 py-2 text-sm cursor-pointer'}>
            Switch steward view to
          </div>
          {accounts.data.map(function(nextAccount, index) {
            const isActive = account.id === nextAccount.id;
            return (
              <CommunityMenuItem
                key={index}
                isActive={isActive}
                label={nextAccount.data.name}
                onClick={!isActive ? () => onSwitchCommunity(nextAccount) : undefined}
              />
            );
          })}
        </div>
      )}
      <div className="py-1">
        <MenuItem
          label="Switch to platform"
          href={`${root}`}
        />
      </div>
      <div className="py-1">
        <MenuItem
          label="View my profile"
          href={`${root}/users/${currentUser.id}`}
        />
        <MenuItem
          label="Edit my profile"
          href={`${root}/profile`}
        />
      </div>
      <div className="py-1">
        <MenuItem
          label="Logout"
          href="/logout"
        />
      </div>
    </>
  );
}
