import React  from 'react';
import moment from 'moment-timezone';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';

export default function Date(props) {
  const { evaluation } = props;

  const event = useConnect('event.byId', {
    id: evaluation.data.eventId
  });

  const currentUser = useUser();

  if (event.state === PayloadStates.FETCHING) {
    return (
      <SupportingTextColumn loading={true} />
    );
  }

  const startDate = moment(event.data.startDate).format('MMM Do, YYYY');
  const startTime = moment(event.data.startTime).tz(currentUser.data.timezone).format('h:mm A ');
  const endTime = moment(event.data.endTime).tz(currentUser.data.timezone).format('h:mm A');

  return (
    <SupportingTextColumn
      title={startDate}
      subtitle={`${startTime} - ${endTime}`}
    />
  );
}
