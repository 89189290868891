import React  from 'react';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Id(props) {
  const { event } = props;

  return (
    <TextColumn>
      {event.id}
    </TextColumn>
  );
}
