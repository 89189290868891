import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import useStewardshipFilter from '../useStewardshipFilter';

AgeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function AgeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const ages = connect('subject.find', {
    pagination: {
      pageSize: 100,
      order: 'name asc'
    }
  });

  const users = function(subject) {
    return connect('offer.find', {
      where: {
        eager: {
          $where: {
            "id": {
              $includes_any: {
                "offerSubjects.subjectId": [subject.id]
              }
            },
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Subject areas"
      dropdownLabel1="It should be connected to"
      dropdownLabel2="of the following subject areas:"
      queryUtils={getQueryUtils}
      filterName="subject"
      queryParamName="subjects"
      tags={ages}
      related={users}
    />
  );
};
