import React from 'react';
import Metrics from '../../dashboard-year/Metrics';
import OfferActivity from './OfferActivity';
import OfferActivityTreemap from './OfferActivity.treemap';
import OfferResponse from './OfferResponse';
import OfferStatus from './OfferStatus';
import Total from './Total';

export default function Offers(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Offers">
        {/*<OfferActivity {...params} />*/}
        <OfferActivityTreemap {...params} />
        <OfferResponse {...params} />
        <OfferStatus {...params} />
      </Metrics>
      <Metrics>
        <Total {...params} />
      </Metrics>
    </>
  );
}
