import React from 'react';

export default function(account) {
  const root = `/c/${account.data.subdomain}`;

  return [
    { name: 'Screening', href: `${root}/settings/screening` },
    { name: 'Training', href: `${root}/settings/training` },
    { name: 'Banner', href: `${root}/settings/banner` },
    { name: 'Grants', href: `${root}/settings/grants` }
  ];
};
