import React from 'react';
import moment from 'moment-timezone';
import FeedItem from './FeedItem';
import { useConnect } from '@lore/connect';

export default function EvaluationEventPartner(props) {
  const { evaluation } = props;

  const event = useConnect('event.byId', {
    id: evaluation.data.eventId
  });

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  return (
    <FeedItem timestamp={moment(evaluation.data.createdAt).fromNow()} last={props.last}>
      Partner evaluation was created for <span className="font-medium text-gray-900">
        {user.data.firstName} {user.data.lastName}
      </span> regarding the event titled <span className="font-medium text-gray-900">
        {event.data.title || '[missing title]'}
      </span>
    </FeedItem>
  );
}
