import React  from 'react';
import { useConnect } from '@lore/connect';
import UserAvatar from '../../../components/UserAvatar';

export default function Educator(props) {
  const { project } = props;

  const educator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const organizations = useConnect('organization.find', {
    where: {
      eager: {
        $where: {
          'users.id': educator.id
        }
      }
    }
  });

  const organization = organizations.data[0];

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <UserAvatar user={educator} />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {educator.data.firstName} {educator.data.lastName}
          </div>
          {/*<div className="text-sm text-gray-500">*/}
          {/*  {educator.data.email}*/}
          {/*</div>*/}
          {organization && (
            <div className="text-sm text-gray-500">
              {organization.data.name}
            </div>
          )}
        </div>
      </div>
    </td>
  );
}
