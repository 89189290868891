import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import { useDialog } from '@lore/dialogs';
import Loader from '../../../components/Loader';
import EducatorSidePanel from '../../evaluations-event-educators/SidePanel';
import PartnerSidePanel from '../../evaluations-event-partners/SidePanel';
import UserAvatar from '../../../components/UserAvatar';

export default function Evaluations(props) {
  const { event } = props;

  const connect = useConnect();
  const [timestamp] = useState(moment().toISOString());

  const show = useDialog();

  function onClickEducator(evaluation) {
    show(
      <EducatorSidePanel
        evaluation={evaluation}
      />
    );
  }

  function onClickPartner(evaluation) {
    show(
      <PartnerSidePanel
        evaluation={evaluation}
      />
    );
  }

  const educatorEvaluations = connect('evaluationEventEducator.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  const partnerEvaluations = connect('evaluationEventPartner.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  if (
    educatorEvaluations.state === PayloadStates.FETCHING ||
    partnerEvaluations.state === PayloadStates.FETCHING
  ) {
    return (
      <div className="px-6">
        <Loader />
      </div>
    );
  }

  function renderEvaluation(evaluation, onClick) {
    const user = connect('user.byId', {
      id: evaluation.data.userId
    });

    const colorMap = {
      'Pending': 'bg-yellow-400',
      'Complete': 'bg-green-400'
    };

    const response = evaluation.data.completed ? 'Complete' : 'Pending';
    const responseColor = colorMap[response];

    return (
      <div
        key={evaluation.id}
        className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
        onClick={() => onClick(evaluation)}
      >
        <div className="flex-shrink-0">
          <UserAvatar user={user} />
        </div>
        <div className="flex-1 min-w-0">
          <a href="#" className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-900">
              {user.data.firstName} {user.data.lastName}
            </p>
            <p className="text-sm text-gray-500 truncate">
              {response}
              <span className={`ml-2.5 ${responseColor} flex-shrink-0 inline-block h-2 w-2 rounded-full`}>
                <span className="sr-only">{response}</span>
              </span>
            </p>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="px-6 pb-6 space-y-6">
      <div>
        <div className="mb-2">
          Educator
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
          {educatorEvaluations.data.map(evaluation => renderEvaluation(evaluation, onClickEducator))}
          {educatorEvaluations.data.length === 0 && (
            <div className="text-gray-500">
              There are no educator evaluations.
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="mb-2">
          Partner
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
          {partnerEvaluations.data.map(evaluation => renderEvaluation(evaluation, onClickPartner))}
          {partnerEvaluations.data.length === 0 && (
            <div className="text-gray-500">
              There are no partner evaluations.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
