import React from 'react';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Chart from '../../dashboard-year/Chart';

export default function ProjectEvalResponse(props) {
  const { account, schoolStart, schoolEnd, subtitle, field = '', title = [] } = props;

  const connect = useConnect();

  const responses = [
    'Significantly',
    'Somewhat',
    'Not at all',
    'Not a goal',
    'No answer'
  ];

  const responseMap = {
    'Not at all': 'Not at all',
    'Somewhat': 'Somewhat',
    'Significantly': 'Significantly',
    'Not a goal': 'Not a goal',
    'No answer': {
      $exists: false
    }
  };

  const requests = responses.map(function(response) {
    return {
      label: response,
      projects: connect('evaluationProjectEducator.find', {
        where: {
          eager: {
            $where: {
              [field]: responseMap[response],
              completed: true,
              accountId: account.id,
              // createdAt: {
              //   $lte: schoolEnd,
              //   $gte: schoolStart
              // }
            }
          }
        },
        pagination: {
          pageSize: 1
        }
      })
    };
  });

  const loading = _.reduce(requests, function(result, request) {
    return result || request.projects.state === PayloadStates.FETCHING;
  }, false)

  if (loading) {
    return (
      <Chart name={title.join(' ')}>
        <Loader />
      </Chart>
    );
  }

  const series = requests.map(r => r.projects.meta.totalCount);
  const labels = requests.map(r => r.label);

  const data = {
    series: series,
    options: {
      title: {
        text: title,
        // text: ['Educator Eval:'].concat(title),
        align: 'center'
      },
      subtitle: {
        text: subtitle || 'For school year',
        align: 'center',
        offsetY: 20 * title.length
      },
      labels: labels,
      chart: {
        type: 'donut',
      },
      colors: [
        '#22c55e',
        '#facc15',
        '#ef4444',
        '#38bdf8',
        '#8b5cf6'
      ],
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        offsetY: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true
              }
            }
          }
        }
      }
    }
  };

  return (
    <Chart
      // name="Project activity types"
      className="col-span-1 pb-2"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="donut"
        height={350}
      />
    </Chart>
  );
}
