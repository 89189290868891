import React from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useConnect } from '@lore/connect';
import { onChange, onChangeArray, mapArray } from '../../../utils/searchTags';
import useRouter from '../../../hooks/useRouter';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import getQueryUtils from '../queryUtils';
import PayloadStates from '../../../constants/PayloadStates';

export default function SearchTags(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  const connect = useConnect();

  const { params: searchParams } = getQueryUtils(parse(location.search), [
    // 'accounts',
    'contributions',
    'educator',
    'partner',
    'recommendEducators',
    'recommendPlatforms',
    'school',
    'statuses',
    'title',
    'trainings'
  ], timestamp);

  const params = {
    pagination: {
      page_size: 100,
      ordering: 'name'
    }
  };

  return (
    <div className="space-y-3">
      {/*<Tags*/}
      {/*  type="Contribution"*/}
      {/*  query={searchParams.contributions}*/}
      {/*  models={mapArray([*/}
      {/*    'Yes',*/}
      {/*    'No'*/}
      {/*  ])}*/}
      {/*  onChange={onChangeArray('contributions', { location, history })}*/}
      {/*/>*/}
      {/*<Tags*/}
      {/*  type="Community"*/}
      {/*  query={searchParams.accounts}*/}
      {/*  models={connect('account.find', params)}*/}
      {/*  onChange={onChangeArray('accounts', { location, history })}*/}
      {/*  label={tag => tag.data.subdomain}*/}
      {/*/>*/}
      <Tags
        type="In-kind contribution"
        query={searchParams.contributions}
        models={{
          state: PayloadStates.RESOLVED,
          data: [
            [1, '$0'],
            [2, '$1+'],
            [5, '$5+'],
            [10, '$10+'],
            [25, '$25+'],
            [50, '$50+'],
            [100, '$100+'],
            [250, '$250+'],
            [500, '$500+']
          ].map(function(value) {
            return {
              id: value[0],
              data: {
                name: value[1]
              }
            }
          })
        }}
        onChange={onChangeArray('contributions', { location, history })}
      />
      <Tag
        type="Educator"
        query={searchParams.educator}
        onChange={onChange('educator', { location, history })}
      />
      <Tag
        type="Partner"
        query={searchParams.partner}
        onChange={onChange('partner', { location, history })}
      />
      <Tags
        type="Recommend educator"
        query={searchParams.recommendEducators}
        models={mapArray([
          'Yes',
          'No'
        ])}
        onChange={onChangeArray('recommendEducators', { location, history })}
      />
      <Tags
        type="Recommend platform"
        query={searchParams.recommendPlatforms}
        models={mapArray([
          'Yes',
          'No'
        ])}
        onChange={onChangeArray('recommendPlatforms', { location, history })}
      />
      <Tag
        type="School"
        query={searchParams.school}
        onChange={onChange('school', { location, history })}
      />
      <Tags
        type="Status"
        query={searchParams.statuses}
        models={mapArray([
          'Complete',
          'Pending'
        ])}
        onChange={onChangeArray('statuses', { location, history })}
      />
      <Tag
        type="Title"
        query={searchParams.title}
        onChange={onChange('title', { location, history })}
      />
      <Tags
        type="Training"
        query={searchParams.trainings}
        models={mapArray([
          'Yes',
          'No'
        ])}
        onChange={onChangeArray('trainings', { location, history })}
      />
    </div>
  );
};
