import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import InvitePartnerDialog from '../../../../dialogs/event/InvitePartner';

export default function Invite(props) {
  const {
    event
  } = props;

  const show = useDialog();

  function onClick() {
    show(
      <InvitePartnerDialog
        event={event}
      />, { template: 'dialog' }
    );
  }

  return (
    <PrimaryAction onClick={onClick}>
      Invite Partner
    </PrimaryAction>
  );
}
