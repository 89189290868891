import React from 'react';
import { parse, stringify } from 'query-string';
import { MISSING_PARTNER_FIELDS } from '../../utils/user/MISSING_FIELDS';
import useStewardshipFilter from '../explore-partners/useStewardshipFilter';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect, returnRawQuery = false } = props;

  const stewardshipFilter = useStewardshipFilter();

  const query = {
    where: {
      eager: {
        $where: {
          isEducator: false,
          isHidden: false,
          ...MISSING_PARTNER_FIELDS,
          $and: {
            $or: stewardshipFilter
          }
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  };

  if (returnRawQuery) {
    return query;
  }

  return connect('user.find', query);
}
