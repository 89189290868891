import React from 'react';
import { parse, stringify } from 'query-string';
import useStewardshipFilter from '../explore-invitations/useStewardshipFilter';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  const stewardshipFilter = useStewardshipFilter();

  return connect('invitation.find', {
    where: {
      eager: {
        $where: {
          response: 'Pending',
          'event.cancelled': false,
          'event.completed': false,
          eventId: {
            $not: null
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  });
}
