import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { onChangeLocationCondition } from '../../../utils/searchTags';
import getQueryUtils, { attributeKeys } from '../queryUtils';
import Tag from '../../../components/Tag';
import useRouter from '../../../hooks/useRouter';

export default function Location(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();
  const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);
  const searchParams = queryUtils.params;

  if (searchParams.locationCondition.address) {
    return (
      <Tag
        type="Location"
        query={`${searchParams.locationCondition.radius} miles of ${searchParams.locationCondition.address}`}
        onChange={() => onChangeLocationCondition({}, { location, history })}
      />
    );
  }

  return null;
};
