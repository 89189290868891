/**
 * This component is intended to reflect the high level structure of your application,
 * and render any components that are common across all views, such as the header or
 * top-level navigation. All other components should be rendered by route handlers.
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PrimaryNavigation from './_components/PrimaryNavigation';
import ScrollToTop from '../components/ScrollToTop';
import moment from 'moment';
import { useUser } from '@lore/auth';
import { useConfig } from '@lore/config';
import Transitions from './Transitions';

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default function Layout(props) {
  const { location, history, children } = props;

  const user = useUser();
  const config = useConfig();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const { enabled, appId, companyId, companyName } = config.intercom;
    const createdAt = moment(user.data.createdAt).unix();

    if (enabled) {
      window.Intercom("boot", {
        app_id: appId,
        name: `${user.data.firstName} ${user.data.lastName}`,
        email: user.data.email,
        created_at: createdAt,
        company: {
          id: companyId,
          name: companyName
        }
      });
    }

    history.listen(function () {
      window.Intercom("update");
    });
  }, []);

  useEffect(() => {
    const { enabled } = config.fullstory;

    if (enabled) {
      window.FS.setUserVars({
        uid: user.id,
        displayName: `${user.data.firstName} ${user.data.lastName}`,
        email: user.data.email,
      });
    }
  }, []);

  // useEffect(() => {
  //   if (!user.data.isStaff) {
  //     history.push('/unauthorized');
  //   }
  // }, []);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <ScrollToTop key={`scroll-to-top-${location.pathname}`}/>
      {/*<Transitions*/}
      {/*  sidebarOpen={sidebarOpen}*/}
      {/*  setSidebarOpen={setSidebarOpen}*/}
      {/*/>*/}
      <PrimaryNavigation sidebarOpen={sidebarOpen} />
      {React.cloneElement(children, {
        sidebarOpen,
        setSidebarOpen
      })}
    </div>
  );
}
