import React  from 'react';
import { useConnect } from '@lore/connect';
import UserAvatar from '../../../components/UserAvatar';

export default function Partner(props) {
  const { offer } = props;

  const partner = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <UserAvatar user={partner} />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {partner.data.firstName} {partner.data.lastName}
          </div>
          <div className="text-sm text-gray-500">
            {partner.data.email}
          </div>
        </div>
      </div>
    </td>
  );
}
