import React from 'react';
import Wrapper from '../../../_components/actions/Actions';
import useAccount from '../../../../hooks/useAccount';
import Invite from './Invite';
import Destroy from './Destroy';
import Visibility from './Visibility';
import Assign from './Assign';
import ReminderInvite from './ReminderInvite';
import ReminderComplete from './ReminderComplete';
import ReminderClose from './ReminderClose';
import View from './View';

export default function Actions(props) {
  const {
    event,
    onClose
  } = props;

  const account = useAccount();

  if (event.data.accountId !== account.id) {
    return (
      <Wrapper>
        <div className="bg-accent w-full py-3 px-4 rounded-md font-medium text-white">
          Event is outside your stewardship. Actions are disabled.
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Invite event={event} onClose={onClose} />
      <View event={event} />
      <Visibility event={event} onClose={onClose} />
      <Assign event={event} onClose={onClose} />
      <Destroy event={event} onClose={onClose} />
      <ReminderInvite event={event} onClose={onClose} />
      <ReminderComplete event={event} onClose={onClose} />
      <ReminderClose event={event} onClose={onClose} />
    </Wrapper>
  );
}
