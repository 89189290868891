import React from 'react';
import PropTypes from 'prop-types';
import Status from './Status';
import RecommendEducator from './RecommendEducator';
import RecommendPlatform from './RecommendPlatform';
import Training from './Training';
import Contribution from './Contribution';
import Event from './Event';
import Partner from './Partner';
import Educator from './Educator';
import School from './School';

Filters.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function Filters(props) {
  const { location, history } = props;

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <Status location={location} history={history}/>
          {/*<RecommendEducator location={location} history={history}/>*/}
          <RecommendPlatform location={location} history={history}/>
          {/*<Training location={location} history={history}/>*/}
          {/*<Contribution location={location} history={history}/>*/}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <Event location={location} history={history} />
          <Partner location={location} history={history} />
          <Educator location={location} history={history} />
          <School location={location} history={history} />
        </div>
      </div>
    </div>
  );
};
