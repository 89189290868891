import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import People from './People';
import Contribution from './Contribution';
import Outcome from './Outcome';
import Recommend from './Recommend';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import { PayloadStates } from '@lore/utils';

export default function SidePanel(props) {
  const { evaluation } = props;

  const connect = useConnect();

  const [tabs, setTabs] = useState([
    { id: 'details', name: 'Details', selected: true },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  const project = connect('project.byId', {
    id: evaluation.data.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Panel title="Evaluation: Partner" onClose={props.onClose}>
        Loading...
      </Panel>
    );
  }

  const activity = connect('tag.byId', {
    id: project.data.activityId
  });

  return (
    <Panel title="Evaluation: Partner" onClose={props.onClose}>
      <div>
        <Header
          src={activity.data.imageUrl}
          title={project.data.title || activity.data.name}
          subtitle={activity.data.name}
        >
          <Actions
            evaluation={evaluation}
          />
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'details' && (
          <div className="divide-y divide-gray-200">
            <People evaluation={evaluation} />
            <Contribution evaluation={evaluation} />
            <Outcome evaluation={evaluation} />
            <Recommend evaluation={evaluation} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <Notes evaluation={evaluation} />
          </div>
        )}
      </div>
    </Panel>
  )
}
