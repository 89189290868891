import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import moment from 'moment-timezone';
import Feed from '../../_components/Feed';
import Comment from '../../_components/Comment';
import Section from '../../_components/sidePanel/Section';
// import ResponseTag from '../../../explore-conversations/Table/ResponseTag';

export default function Invitation(props) {
  const { event, invitation } = props;

  const connect = useConnect();
  const [timestamp] = useState(moment().toISOString());

  const messages = connect('message.find', {
    where: {
      eager: {
        $where: {
          $and: [
            {
              $or: [
                { senderId: event.data.creatorId },
                { senderId: invitation.data.userId }
              ]
            },
            {
              $or: [
                { recipientId: event.data.creatorId },
                { recipientId: invitation.data.userId }
              ]
            }
          ],
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      order: 'createdAt asc',
      pageSize: 100
    }
  });

  return (
    <Section
      title="Conversation"
      subtitle="Messages between educator and partner."
    >
      <Feed models={messages}>
        {function(message) {
          const sender = connect('user.byId', {
            id: message.data.senderId
          });

          return (
            <Comment
              model={{
                ...message,
                data: {
                  ...message.data,
                  text: message.data.content
                }
              }}
              system={!(
                message.data.type === 'Message'
                // message.data.type === 'Message' ||
                // message.data.type === 'PartnerInterested' ||
                // message.data.type === 'EducatorInterested'
              )}
              // tag={(
              //   <ResponseTag model={message}/>
              // )}
              title={`${sender.data.firstName} ${sender.data.lastName}`}
              imageSrc={sender.data.avatarUrl}
            />
          );
        }}
      </Feed>
    </Section>
  );
}
