import React  from 'react';
import moment from 'moment-timezone';
import SupportingTextColumn from '../../_components/table/columns/SupportingTextColumn';

export default function DateCreated(props) {
  const { event } = props;

  return (
    <SupportingTextColumn
      title={moment(event.data.createdAt).format('MMM D, YYYY')}
      subtitle={`${moment().diff(event.data.createdAt, 'days')} days ago`}
    />
  );
}
