import React from 'react';
import moment from 'moment-timezone';
import FeedItem from './FeedItem';
import { useConnect } from '@lore/connect';

export default function Message(props) {
  const { message } = props;

  const sender = useConnect('user.byId', {
    id: message.data.senderId
  });

  const recipient = useConnect('user.byId', {
    id: message.data.recipientId
  });

  return (
    <FeedItem timestamp={moment(message.data.createdAt).fromNow()} last={props.last}>
      <span className="font-medium text-gray-900">
        {sender.data.firstName} {sender.data.lastName}
      </span> sent a message to <span className="font-medium text-gray-900">
        {recipient.data.firstName} {recipient.data.lastName}
      </span> with the content <span className="font-medium text-gray-900">
        {message.data.content}
      </span>
    </FeedItem>
  );
}
