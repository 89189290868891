import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import DateField from '../../../forms/_fields/DateField';
import TimeField from '../../../forms/_fields/TimeField';
import FormTemplate from '../../_common/FormTemplate';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import StringField from '../../../forms/_fields/StringField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      title="Schedule/Record Interaction"
      subtitle="Would you like to schedule or record an interaction related to this conversation?"
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-4">
          {/*<FormField name="text" data={data} errors={showErrors && errors} onChange={onChange}>*/}
          {/*  {(field) => (*/}
          {/*    <TextAreaField*/}
          {/*      className="sm:col-span-6"*/}
          {/*      label="Note"*/}
          {/*      placeholder="Write something..."*/}
          {/*      // description="Some text about a thing I like"*/}
          {/*      rows={3}*/}
          {/*      value={field.value}*/}
          {/*      error={field.error}*/}
          {/*      onChange={field.onChange}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</FormField>*/}
          {/*<FormField name="text2" data={data} errors={showErrors && errors} onChange={onChange}>*/}
          {/*  {(field) => (*/}
          {/*    <StringField*/}
          {/*      className="sm:col-span-6"*/}
          {/*      label="Note"*/}
          {/*      placeholder="Write something..."*/}
          {/*      // description="Some text about a thing I like"*/}
          {/*      value={field.value}*/}
          {/*      error={field.error}*/}
          {/*      onChange={field.onChange}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</FormField>*/}
          {data.type === 'One-Time' ? (
              <FormField name="date" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => (
                  <DateField
                    key={`${data.date}`}
                    label="Date*"
                    date={data.date}
                    error={showErrors && errors.date}
                    onStartDateChange={(value) => {
                      return onChange('date', value);
                    }}
                    onChange={(value) => {
                      return onChange('date', value);
                    }}
                  />
                )}
              </FormField>
            ) : (
              <>
                <FormField name="startDate" data={data} errors={showErrors && errors} onChange={onChange}>
                  {(field) => (
                    <DateField
                      key={`${data.startDate}`}
                      label="Start Date*"
                      date={data.startDate}
                      error={showErrors && errors.startDate}
                      onStartDateChange={(value) => {
                        return onChange('startDate', value);
                      }}
                      onChange={(value) => {
                        return onChange('startDate', value);
                      }}
                    />
                  )}
                </FormField>
                <FormField name="endDate" data={data} errors={showErrors && errors} onChange={onChange}>
                  {(field) => (
                    <DateField
                      key={`${data.endDate}`}
                      label="End Date*"
                      date={data.endDate}
                      error={showErrors && errors.endDate}
                      onStartDateChange={(value) => {
                        return onChange('endDate', value);
                      }}
                      onChange={(value) => {
                        return onChange('endDate', value);
                      }}
                    />
                  )}
                </FormField>
              </>
          )}
          <FormField name="checkInTime" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <TimeField
                // key={`${data.checkInTime}`}
                label="Check in at office time*"
                time={data.checkInTime}
                error={showErrors && errors.checkInTime}
                onChange={(value) => {
                  return onChange('checkInTime', value);
                }}
              />
            )}
          </FormField>
          <FormField name="startTime" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <TimeField
                // key={`${data.startTime}`}
                label="Start time*"
                time={data.startTime}
                error={showErrors && errors.startTime}
                onChange={(value) => {
                  return onChange('startTime', value);
                }}
              />
            )}
          </FormField>
          <FormField name="endTime" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <TimeField
                // key={`${data.endTime}`}
                label="End time*"
                time={data.endTime}
                error={showErrors && errors.endTime}
                onChange={(value) => {
                  return onChange('endTime', value);
                }}
              />
            )}
          </FormField>
        </div>
      )}
      footer={(
        <>
          <PrimaryButton onClick={onSubmit}>
            Schedule/Record interaction
          </PrimaryButton>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
        </>
      )}
    />
  );
}
