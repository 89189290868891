import React from 'react';
import Wrapper from '../../../_components/actions/Actions';
import useAccount from '../../../../hooks/useAccount';
import Contact from './Contact';
import Sleep from './Sleep';
import InviteProject from './InviteProject';
import InviteEvent from './InviteEvent';
import CompleteProfile from './CompleteProfile';
import Invite from './Invite';
import View from './View';
import Inactive from '../../../explore-educators/SidePanel/Actions/Inactive';

export default function Actions(props) {
  const { user } = props;

  const account = useAccount();

  if (user.data.accountId !== account.id) {
    return (
      <Wrapper>
        <div className="bg-accent w-full py-3 px-4 rounded-md font-medium text-white">
          <div className="mb-4">
            Partner is outside your stewardship. Actions are disabled.
          </div>
          {/*<Invite user={user} />*/}
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {/*<Contact user={user} />*/}
      <Invite user={user} />
      {/*<InviteProject user={user} />*/}
      {/*<InviteEvent user={user} />*/}
      {/*<Sleep user={user} />*/}
      <Inactive user={user} />
      <View user={user} />
      <CompleteProfile user={user} />
    </Wrapper>
  );
}
