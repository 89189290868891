import React from 'react';
import Id from './Table/Id';
import Project from './Table/Project';
import Response from './Table/Response';
import Days from './Table/Days';
import Educator from './Table/Educator';
import Partner from './Table/Partner';
import Messages from './Table/Messages';
import PartnerEmail from './Table/PartnerEmail';
import PartnerPhone from './Table/PartnerPhone';
import EducatorEmail from './Table/EducatorEmail';
import EducatorPhone from './Table/EducatorPhone';

export default [
  { id: 'id',             name: 'ID',             row: Id,            selected: false },
  { id: 'partner',        name: 'Partner',        row: Partner,       selected: true },
  { id: 'educator',       name: 'Educator',       row: Educator,      selected: true },
  { id: 'project',        name: 'Project',        row: Project,       selected: true },
  { id: 'response',       name: 'Response',       row: Response,      selected: true },
  { id: 'days',           name: 'Invite sent',    row: Days,          selected: true },
  { id: 'messages',       name: 'Message count',  row: Messages,      selected: true },
  { id: 'partner_email',  name: 'Partner Email',  row: PartnerEmail,  selected: false },
  { id: 'partner_phone',  name: 'Partner Phone',  row: PartnerPhone,  selected: false },
  { id: 'educator_email', name: 'Educator Email', row: EducatorEmail, selected: false },
  { id: 'educator_phone', name: 'Educator Phone', row: EducatorPhone, selected: false }
];
