import React, { useState } from 'react';
import _ from 'lodash';
import avatar from '../../../../assets/images/avatar.svg';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import Contact from './Contact';
import Demographics from './Demographics';
import About from './About';
import Organizations from './Organizations';
import Social from './Social';
import Teaching from './Teaching';
import Stories from './Stories';
import Projects from './Projects';
import Events from './Events';
import Claims from './Claims';
import Actions from './Actions';
import Notes from '../../explore-partners/SidePanel/Notes';
import Tabs from '../../_components/Tabs';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import Bookmark from '../../_components/Bookmark';

export default function SidePanel(props) {
  const { user } = props;

  const [tabs, setTabs] = useState([
    { id: 'profile', name: 'Profile', selected: true },
    { id: 'participation', name: 'Participation', selected: false },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  return (
    <Panel title="Educator" onClose={props.onClose}>
      <div>
        <Header
          bookmark={(
            <Bookmark
              type="user"
              resource={user}
            />
          )}
          src={user.data.avatarUrl || avatar}
          title={`${user.data.firstName} ${user.data.lastName}`}
          subtitle={user.data.email}
        >
          <Actions
            user={user}
          />
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'profile' && (
          <div className="divide-y divide-gray-200">
            <Contact user={user} />
            <Organizations user={user} />
            <About user={user} />
            <Teaching user={user} />
            <Demographics user={user} />
            <Social user={user} />
          </div>
        )}
        {selectedTab.id === 'participation' && (
          <div className="divide-y divide-gray-200">
            <Section>
              <TextField label="">
                Here are projects, events, and impact stories associated with this educator.
              </TextField>
            </Section>
            <Projects user={user} />
            {/*<Questions user={user} />*/}
            <Events user={user} />
            {/*<Invitations user={user} />*/}
            {/*<Offers user={user} />*/}
            <Claims user={user} />
            <Stories user={user} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <div className="px-6">
              <Notes user={user} />
            </div>
          </div>
        )}
      </div>
    </Panel>
  )
}
