import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import InvitePartnersReminderDialog from '../../../../dialogs/project/InvitePartnersReminder';
import { useUser } from '@lore/auth';

export default function ReminderInvite(props) {
  const {
    project
  } = props;

  const show = useDialog();

  function onClick() {
    show(
      <InvitePartnersReminderDialog
        project={project}
      />, { template: 'dialog' }
    );
  }

  return (
    <SecondaryAction onClick={onClick} secret={true}>
      Send reminder to invite more partners
    </SecondaryAction>
  );
}
