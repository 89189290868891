import React  from 'react';
import { useConnect } from '@lore/connect';
import UserAvatar from '../../../../../components/UserAvatar';
import SleepingTag from '../../../SleepingTag';
import InactiveTag from '../../../InactiveTag';

export default function Name(props) {
  const { user } = props;

  const accounts = useConnect('account.find', {
    where: {
      eager: {
        $where: {
          'users.id': user.id
        }
      }
    }
  });

  const tags = false && accounts.data.map(function(account, index) {
    return (
      <span key={index} className="px-2 mx-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
        {account.data.subdomain}
      </span>
    );
  });

  return (
    <td className="px-6 py-4 whitespace-nowrapx">
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <UserAvatar user={user} />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">
            {user.data.firstName} {user.data.lastName} {tags}
          </div>
          <div className="text-sm text-gray-500">
            {user.data.email} <SleepingTag user={user}/> <InactiveTag user={user}/>
          </div>
        </div>
      </div>
    </td>
  );
}
