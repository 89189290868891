import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { PayloadStates } from '@lore/utils';
import useUserTags from '../../../hooks/useUserTags';
import TagTypes from '../../../constants/TagTypes';

export default function Guidances(props) {
  const { user } = props;

  const userGuidances = useUserTags(user, TagTypes.Guidance);

  // if (
  //   userGuidances.state === PayloadStates.FETCHING ||
  //   userGuidances.data.length === 0
  // ) {
  //   return null;
  // }

  return (
    <Section
      title="Professional Competencies"
    >
      {userGuidances.data.map(function(userGuidance) {
        return (
          <TextField
            invert={true}
            showEmpty={true}
            key={userGuidance.id}
            label={userGuidance._tag.data.name || '...'}
          >
            {userGuidance._tagNode.data.description}
          </TextField>
        );
      })}
      {userGuidances.data.length === 0 && '-'}
    </Section>
  );
}
