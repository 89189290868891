import React from 'react';
import { useConnect } from '@lore/connect';
import useRouter from '../../hooks/useRouter';

// Events
import starredEvents from '../explore-events-starred/useQuery';
import newEvents from '../explore-events-new/useQuery';
import draftEvents from '../explore-events-draft/useQuery';
import partnersEvents from '../explore-events-partners/useQuery';
import expiringEvents from '../explore-events-expiring/useQuery';
import happeningSoonEvents from '../explore-events-soon/useQuery';
import recentlyCompletedEvents from '../explore-events-recently-completed/useQuery';
import expiredNotClosedEvents from '../explore-events-expired-not-closed/useQuery';
import missingEducatorEvaluationEvents from '../explore-events-missing-educator-evaluation/useQuery';
import completedEvents from '../explore-events-completed/useQuery';
import schoolYearEvents from '../explore-events-school-year/useQuery';

// Partners
import invitationsPartners from '../explore-partners-invitations/useQuery';
import useAccount from '../../hooks/useAccount';

export default function getNavigationLinks() {
  const { location } = useRouter();
  const connect = useConnect();
  const account = useAccount();

  function getCount(query) {
    const result = query({ location, connect });
    return result.meta ? result.meta.totalCount : '...';
  }

  const root = `/c/${account.data.subdomain}`;

  return [
    { name: 'Starred', href: `${root}/explore/events/starred`, count: getCount(starredEvents) },
    { name: 'New', href: `${root}/explore/events/new`, count: getCount(newEvents), highlight: true },
    { name: 'Incomplete', href: `${root}/explore/events/draft`, count: getCount(draftEvents) },
    { name: 'Needs partners', href: `${root}/explore/events/partners`, count: getCount(partnersEvents), highlight: true },
    { name: 'Pending Invitations', href: `${root}/explore/events/invitations`, count: getCount(invitationsPartners) },
    // { name: 'Expiring', href: `${root}/explore/events/expiring`, count: getCount(expiringEvents) },
    { name: 'Happening Soon', href: `${root}/explore/events/soon`, count: getCount(happeningSoonEvents), highlight: true },
    // { name: 'Recently Completed', href: `${root}/explore/events/recently-completed`, count: getCount(recentlyCompletedEvents) },
    { name: 'Expired but not Closed', href: `${root}/explore/events/expired-not-closed`, count: getCount(expiredNotClosedEvents), highlight: true },
    { name: 'Missing Educator Evaluation', href: `${root}/explore/events/missing-educator-evaluation`, count: getCount(missingEducatorEvaluationEvents) },
    { name: 'Completed', href: `${root}/explore/events/completed`, count: getCount(completedEvents) },
    { name: 'All Events this School Year', href: `${root}/explore/events/school-year`, count: getCount(schoolYearEvents) },
    { name: 'All', href: `${root}/explore/events` }
  ];
}
