import React from 'react';
import Metrics from '../../dashboard-year/Metrics';
import Total from './Total';
import AssociatedWithProject from './AssociatedWithProject';
import AssociatedWithEvent from './AssociatedWithEvent';
import AssociatedWithProjectOrEvent from './AssociatedWithProjectOrEvent';

export default function Schools(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Schools">
        <Total {...params} />
        <AssociatedWithProject {...params} />
        <AssociatedWithEvent {...params} />
        <AssociatedWithProjectOrEvent {...params} />
      </Metrics>
    </>
  );
}
