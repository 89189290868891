import React from 'react';
import PropTypes from 'prop-types';
import InteractionFilter from './Interaction';
import LocationFilter from './Location';
import NameFilter from './Name';
import OrganizationFilter from './Organization';
import JoinedFilter from './Joined';
import GenderFilter from './Gender';
import EthnicityFilter from './Ethnicity';
// import SearchFilter from './Search';
import HoursFilter from './Hours';
import ContributionsFilter from './Contributions';
import StudentsFilter from './Students';
import TagTypes from '../../../constants/TagTypes';
import ParentTagFilter from './_common/ParentTagFilter';
import TagFilter from './_common/TagFilter';
import SearchFilter from './_common/SearchFilter';

export default function Filters(props) {
  const { timestamp } = props;

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <ParentTagFilter
            type={TagTypes.ExpertiseType}
            label="Skills and experience"
            filterName="expertiseType"
            queryParamName="expertiseTypes"
          />
          <TagFilter
            type={TagTypes.Age}
            label="Age groups"
            filterName="age"
            queryParamName="ages"
          />
          <TagFilter
            type={TagTypes.Activity}
            label="Activities"
            filterName="activity"
            queryParamName="activities"
          />
          <TagFilter
            type={TagTypes.Experience}
            label="Life experience"
            filterName="experience"
            queryParamName="experiences"
          />
          <TagFilter
            type={TagTypes.Guidance}
            label="Professional competencies"
            filterName="guidance"
            queryParamName="guidances"
          />
          <TagFilter
            type={TagTypes.Group}
            label="Youth types"
            filterName="group"
            queryParamName="groups"
          />
          {/*<InteractionFilter location={location} history={history} />*/}
          <GenderFilter timestamp={timestamp}/>
          <EthnicityFilter timestamp={timestamp}/>
          <JoinedFilter timestamp={timestamp}/>
          <HoursFilter timestamp={timestamp}/>
          <ContributionsFilter timestamp={timestamp}/>
          {/*<StudentsFilter location={location} history={history} />*/}
          {/*<LocationFilter />*/}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <SearchFilter
            label="Name"
            param="name"
            placeholder="Enter name..."
            timestamp={timestamp}
          />
          <SearchFilter
            label="Organization"
            placeholder="Enter organization name..."
            param="organization"
            timestamp={timestamp}
          />
          <SearchFilter
            label="Search"
            tooltip="Searches bio, skills, and experience."
            description="Searches bio, skills, and experience."
            placeholder="Enter word or phrase..."
            param="searchPartner"
            timestamp={timestamp}
          />
          {/*<NameFilter />*/}
          {/*<OrganizationFilter />*/}
          {/*<SearchFilter />*/}
        </div>
      </div>
    </div>
  );
};
