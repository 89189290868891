import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../../_components/table/columns/TextColumn';

export default function Messages(props) {
  const { invitation } = props;

  const connect = useConnect();

  const event = connect('event.byId', {
    id: invitation.data.eventId
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  const messages = connect('message.find', {
    where: {
      eager: {
        $where: {
          $and: [
            {
              $or: [
                { senderId: event.data.creatorId },
                { senderId: invitation.data.userId }
              ]
            },
            {
              $or: [
                { recipientId: event.data.creatorId },
                { recipientId: invitation.data.userId }
              ]
            }
          ]
        }
      }
    }
  });

  if (messages.state === PayloadStates.FETCHING) {
    return (
      <TextColumn>
        ...
      </TextColumn>
    );
  }

  return (
    <TextColumn>
      {messages.meta.totalCount}
    </TextColumn>
  );
}
