import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';
import useStewardshipFilter from '../explore-educators/useStewardshipFilter';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect, returnRawQuery = false } = props;

  const stewardshipFilter = useStewardshipFilter();

  const query = {
    where: {
      eager: {
        $where: {
          isEducator: true,
          $and: [
            {
              projects: {
                $count_eq: 0
              },
              events: {
                $count_eq: 0
              }
            }
          ],
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  };

  if (returnRawQuery) {
    return query;
  }

  return connect('user.find', query);
}
