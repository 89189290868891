/**
 * Configuration file for Auth0
 *
 * This file is where you define overrides for the default auth0 parameters.
 */

const tenant = window.location.host.split('.')[0];

export default {
  clientID: 'B6r4Hyjjx9moMLYdeyn48MVfo1hhgYvQ',
  domain: 'auth.communityshare.org',
  // redirectUri: 'http://localhost:3000/auth/callback',
  // redirectUri: 'http://localhost.communityshareapp.test/auth/callback',
  redirectUri: `http://${tenant}.communityshareapp.test/auth/callback`,
  // redirectUri: 'http://localhost.communityshareapp.us/auth/callback',
  audience: 'https://communityshare.auth0.com/userinfo',
  responseType: 'token id_token',
  scope: 'openid profile email'
};
