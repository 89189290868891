import React, { useState } from 'react';
import _ from 'lodash';
import ReactApexChart  from 'react-apexcharts';
import { useConnect } from '@lore/connect';
import Chart from '../Chart';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import moment from 'moment-timezone';
import useStewardshipFilter from '../../explore-events/useStewardshipFilter';
import REQUIRED_FIELDS from '../../explore-events/REQUIRED_FIELDS';
import MISSING_FIELDS from '../../explore-events/MISSING_FIELDS';

export default function ActivityByMonth(props) {
  const { timestamps, empty, schoolStart } = props;

  const stewardshipFilter = useStewardshipFilter();

  const [currentTimestamp] = useState(moment().toISOString());

  const connect = useConnect();

  const partners = timestamps.map(function(timestamp) {
    if (moment(currentTimestamp).diff(timestamp.start) < 0) {
      return {
        ...timestamp,
        results: {
          // all: empty(),
          // draft: empty(),
          // published: empty(),
          new: empty(),
          active: empty(),
          completed: empty(),
          cancelled: empty()
        }
      }
    }

    return {
      ...timestamp,
      results: {
        // all: connect('event.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         activityId: {
        //           $not: null
        //         },
        //         createdAt: {
        //           $lte: timestamp.end,
        //           $gte: timestamp.schoolStart
        //         },
        //         $or: stewardshipFilter
        //         // ...accountQuery
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        // draft: connect('event.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         published: false,
        //         activityId: {
        //           $not: null
        //         },
        //         $and: {
        //           $or: {
        //             ...MISSING_FIELDS
        //           }
        //         },
        //         createdAt: {
        //           $lte: timestamp.end,
        //           $gte: schoolStart
        //         },
        //         $or: stewardshipFilter
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        // published: connect('event.find', {
        //   where: {
        //     eager: {
        //       $where: {
        //         published: true,
        //         activityId: {
        //           $not: null
        //         },
        //         $and: REQUIRED_FIELDS.$and,
        //         createdAt: {
        //           $lte: timestamp.end,
        //           $gte: schoolStart
        //         },
        //         $or: stewardshipFilter
        //       }
        //     }
        //   },
        //   pagination: {
        //     pageSize: 1
        //   }
        // }),
        new: connect('event.find', {
          where: {
            eager: {
              $where: {
                activityId: {
                  $not: null
                },
                createdAt: {
                  $gte: timestamp.start,
                  $lte: timestamp.end
                },
                $or: stewardshipFilter
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        active: connect('event.find', {
          where: {
            eager: {
              $where: {
                $and: [
                  ...REQUIRED_FIELDS.$and,
                  {
                    $or: [
                      // starts during month
                      {
                        startDate: {
                          $gte: timestamp.start,
                          $lte: timestamp.end
                        }
                      },
                      // starts before month, ends after month
                      {
                        startDate: {
                          $lte: timestamp.start
                        },
                        endDate: {
                          $gte: timestamp.end
                        },
                      },
                      // ends during month
                      {
                        endDate: {
                          $gte: timestamp.start,
                          $lte: timestamp.end
                        }
                      }
                    ]
                  },
                  {
                    $or: stewardshipFilter,
                  }
                ]
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        completed: connect('event.find', {
          where: {
            eager: {
              $where: {
                $and: REQUIRED_FIELDS.$and,
                completed: true,
                endDate: {
                  $gte: timestamp.start,
                  $lte: timestamp.end
                },
                $or: stewardshipFilter
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        }),
        cancelled: connect('event.find', {
          where: {
            eager: {
              $where: {
                $and: REQUIRED_FIELDS.$and,
                cancelled: true,
                endDate: {
                  $gte: timestamp.start,
                  $lte: timestamp.end
                },
                $or: stewardshipFilter
              }
            }
          },
          pagination: {
            pageSize: 1
          }
        })
      }
    }
  });

  const loading = _.reduce(partners, function(result, partner) {
    return (
      result ||
      // partner.results.all.state === PayloadStates.FETCHING ||
      // partner.results.draft.state === PayloadStates.FETCHING ||
      // partner.results.published.state === PayloadStates.FETCHING ||
      partner.results.new.state === PayloadStates.FETCHING ||
      partner.results.active.state === PayloadStates.FETCHING ||
      partner.results.completed.state === PayloadStates.FETCHING ||
      partner.results.cancelled.state === PayloadStates.FETCHING
    );
  }, false)

  if (loading) {
    return (
      <Chart name="Event Activity" className="col-span-3">
        <Loader />
      </Chart>
    );
  }

  const data = {
    series: [
      // {
      //   name: 'Total',
      //   data: partners.map(e => e.results.all.meta.totalCount)
      // },
      // {
      //   name: 'Draft',
      //   data: partners.map(e => e.results.draft.meta.totalCount)
      // },
      // {
      //   name: 'Published',
      //   data: partners.map(e => e.results.published.meta.totalCount)
      // },
      {
        name: 'New',
        data: partners.map(e => e.results.new.meta.totalCount)
      },
      {
        name: 'Active',
        data: partners.map(e => e.results.active.meta.totalCount)
      },
      {
        name: 'Completed',
        data: partners.map(e => e.results.completed.meta.totalCount)
      },
      {
        name: 'Cancelled',
        data: partners.map(e => e.results.cancelled.meta.totalCount)
      }
    ],
    options: {
      title: {
        text: 'Event Activity',
        align: 'center'
      },
      subtitle: {
        text: 'Over school year',
        align: 'center'
      },
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: partners.map(e => e.label)
      },
      yaxis: {
        title: {
          text: 'Count'
        }
      },
      fill: {
        opacity: 1
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands"
      //     }
      //   }
      // }
    }
  };

  return (
    <Chart
      // name="Event growth"
      className="col-span-3"
    >
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={350}
      />
    </Chart>
  );
}
