import React from 'react';
import Metrics from '../Metrics';
import TotalYear from './TotalYear';

export default function Schools(props) {
  const { params } = props;

  return (
    <>
      <Metrics title="Organizations associated/affiliated with Partners">
        <TotalYear {...params} />
      </Metrics>
    </>
  );
}
