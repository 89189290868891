import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import SidePanel from '../../explore-educators/SidePanel';

export default function Educator(props) {
  const { project } = props;

  const connect = useConnect();

  const user = connect('user.byId', {
    id: project.data.creatorId
  });

  const organizations = connect('organization.find', {
    where: {
      eager: {
        $where: {
          'users.id': project.data.creatorId
        }
      }
    }
  });

  const show = useDialog();

  function onClick() {
    show(
      <SidePanel user={user} />
    );
  }

  return (
    <Section
      title="Educator Contact Info"
      // subtitle="Name and contact info."
    >
      <TextField label="Name">
        <span onClick={onClick} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {user.data.firstName} {user.data.lastName}
        </span>
      </TextField>
      <TextField label="Email">
        {user.data.email || '-'}
      </TextField>
      <TextField label="Phone">
        {user.data.phoneNumber || '-'}
      </TextField>
      <TextField label="School/Org Affiliations">
        {organizations.data.map(function(organization, index) {
          return (
            <div key={index}>
              {organization.data.name}
            </div>
          );
        })}
        {organizations.data.length === 0 && '-'}
      </TextField>
    </Section>
  );
}
