import React from 'react';
import PropTypes from 'prop-types';
import ActivityFilter from './Activity';
import GuidanceFilter from './Guidance';
import GroupFilter from './Group';
import SubjectFilter from './Subject';
import AgeFilter from './Age';
import DayFilter from './Day';
import InteractionTypeFilter from './InteractionType';
import ParticipantFilter from './Participant';
import TimeFilter from './Time';
import TitleFilter from './Title';
import OrganizationFilter from './Organization';
import NameFilter from './Name';
import SupplyFilter from './Supply';
import CostFilter from './Cost';
import InstructionFilter from './Instruction';
import SearchFilter from './Search';
import CreatedFilter from './Created';
import PublishedFilter from './Published';
import TagFilter from './_common/TagFilter';
import TagTypes from '../../../constants/TagTypes';
import getQueryUtils, { attributeKeys } from '../queryUtils';
import useRouter from '../../../hooks/useRouter';

Filters.propTypes = {
  timestamp: PropTypes.string.isRequired
};

export default function Filters(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();

  return (
    <div className="w-full mb-12x">
      <div className="relative">
        {/*<h2 className="pb-8 md:pb-0 text-2xl font-light">*/}
        {/*  Filter your results*/}
        {/*</h2>*/}
        {/*<Divider className="mb-4" />*/}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          <ActivityFilter />
          <TagFilter
            type={TagTypes.Guidance}
            label="Professional competencies"
            filterName="guidance"
            queryParamName="guidances"
          />
          <TagFilter
            type={TagTypes.Group}
            label="Youth types"
            filterName="group"
            queryParamName="groups"
          />
          <TagFilter
            type={TagTypes.Subject}
            label="Subject areas"
            filterName="subject"
            queryParamName="subjects"
          />
          {/*<TagFilter*/}
          {/*  type={TagTypes.Age}*/}
          {/*  label="Student ages"*/}
          {/*  filterName="age"*/}
          {/*  queryParamName="ages"*/}
          {/*/>*/}
          {/*<TagFilter*/}
          {/*  type={TagTypes.Day}*/}
          {/*  label="Day"*/}
          {/*  filterName="day"*/}
          {/*  queryParamName="days"*/}
          {/*/>*/}
          <TagFilter
            type={TagTypes.InteractionType}
            label="Interaction types"
            filterName="interactionType"
            queryParamName="interactionTypes"
          />
          <TagFilter
            type={TagTypes.Participant}
            label="Participants"
            filterName="participant"
            queryParamName="participants"
          />
          <TagFilter
            type={TagTypes.Time}
            label="Time"
            filterName="time"
            queryParamName="times"
          />
          <TagFilter
            type={TagTypes.Supply}
            label="Supplies"
            filterName="supply"
            queryParamName="supplies"
          />
          <CostFilter timestamp={timestamp} />
          {/*<InstructionFilter timestamp={timestamp} />*/}
          <CreatedFilter timestamp={timestamp} />
          {/*<PublishedFilter timestamp={timestamp} />*/}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <TitleFilter timestamp={timestamp} />
          <NameFilter timestamp={timestamp} />
          <OrganizationFilter timestamp={timestamp} />
          <SearchFilter timestamp={timestamp} />
        </div>
      </div>
    </div>
  );
};
