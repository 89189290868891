import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import useStewardshipFilter from '../explore-partners/useStewardshipFilter';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  const currentUser = useUser();

  const [timestamp] = useState(moment().toISOString());
  const stewardshipFilter = useStewardshipFilter();

  return connect('user.find', {
    where: {
      eager: {
        $where: {
          isEducator: false,
          'stars.creatorId': currentUser.id,
          createdAt: {
            $lte: timestamp
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    },
    // include: function(model) {
    //   return (
    //     model.id &&
    //     model.data.userId &&
    //     model.data.creatorId === currentUser.id
    //   );
    // },
    exclude: function(model) {
      return model.state === PayloadStates.DELETED;
    }
  });
}
