import React, { useState } from 'react';
import { useConnect } from '@lore/connect';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import Questions from './Questions';
import Summary from './Summary';
import Educator from './Educator';
import Partner from './Partner';
import Logistics from './Logistics';
import Audience from './Audience';
import Support from './Support';
import Resources from './Resources';
import Bookmark from '../../_components/Bookmark';
import Evaluations from './Evaluations';

export default function SidePanel(props) {
  const { project } = props;

  const [tabs, setTabs] = useState([
    { id: 'details', name: 'Details', selected: true },
    { id: 'questions', name: 'Invitations', selected: false },
    { id: 'evaluations', name: 'Evaluations', selected: false },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  return (
    <Panel title="Project" onClose={props.onClose}>
      <div>
        <Header
          bookmark={(
            <Bookmark
              type="project"
              resource={project}
            />
          )}
          src={activity.data.imageUrl}
          title={project.data.title || activity.data.name}
          subtitle={activity.data.name}
        >
          <Actions
            project={project}
            onClose={props.onClose}
          />
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'details' && (
          <div className="divide-y divide-gray-200">
            <Summary project={project} />
            <Educator project={project} />
            <Partner project={project} />
            <Logistics project={project} />
            <Audience project={project} />
            <Support project={project} />
            <Resources project={project} />
          </div>
        )}
        {selectedTab.id === 'questions' && (
          <div className="mb-6x">
            <Questions project={project} />
          </div>
        )}
        {selectedTab.id === 'evaluations' && (
          <div className="mb-6x">
            <Evaluations project={project} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <Notes project={project} />
          </div>
        )}
      </div>
    </Panel>
  )
}
