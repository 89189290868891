import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../queryUtils';
import TagsFilter from '../../people-educators/Filters/TagsFilter';
import useStewardshipFilter from '../useStewardshipFilter';

AgeFilter.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default function AgeFilter(props) {
  const { location, history } = props;

  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const ages = connect('group.find', {
    pagination: {
      pageSize: 100,
      order: 'name asc'
    }
  });

  const users = function(group) {
    return connect('offer.find', {
      where: {
        eager: {
          $where: {
            "id": {
              $includes_any: {
                "offerGroups.groupId": [group.id]
              }
            },
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    })
  }

  return (
    <TagsFilter
      location={location}
      history={history}
      label="Youth types"
      dropdownLabel1="It should be focused on serving learners in"
      dropdownLabel2="of the following groups:"
      queryUtils={getQueryUtils}
      filterName="group"
      queryParamName="groups"
      tags={ages}
      related={users}
    />
  );
};
