import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/connect';
import getQueryUtils from '../../queryUtils';
import TagsFilter from '../../../people-educators/Filters/TagsFilter';
import useStewardshipFilter from '../../useStewardshipFilter';
import useTags from '../../../../hooks/useTags';

export default function TagFilter(props) {
  const { type, label, filterName, queryParamName, timestamp } = props;

  const connect = useConnect();
  const stewardshipFilter = useStewardshipFilter();

  const tags = useTags(type);

  const nodes = function(tag) {
    return connect('offer.find', {
      where: {
        eager: {
          $where: {
            'tagNodes.tagId': tag.id,
            $or: stewardshipFilter
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    });
  }

  return (
    <TagsFilter
      label={label}
      queryUtils={getQueryUtils}
      filterName={filterName}
      queryParamName={queryParamName}
      tags={tags}
      related={nodes}
      timestamp={timestamp}
    />
  );
};
