import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import getQueryUtils from './queryUtils';
import useRouter from '../../hooks/useRouter';
import CheckboxField from '../../forms/_fields/CheckboxField';
import { useUser } from '@lore/auth';

export default function StewardshipCheckbox(props) {
  const { location, history } = useRouter();
  const currentUser = useUser();

  // const queryUtils = getQueryUtils(parse(location.search));
  // const searchParams = queryUtils.getSearchParamsFromQuery(parse(location.search));
  const searchParams = parse(location.search);

  function onChangeStewardship(event) {
    // if (!condition.address) {
    //   delete condition.address;
    //   delete condition.latitude;
    //   delete condition.longitude;
    // }
    const search = parse(location.search) || {};

    if (event.target.checked) {
      search.accounts = '0';
      delete search.page;
    } else {
      delete search.accounts;
      delete search.page;
    }

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, search))
    });
  }

  // const { locationCondition, community } = searchParams;

  return (
    <CheckboxField
      className="mb-0"
      label="Include offers outside my stewardship"
      // description="(> 50 miles away)"
      checked={searchParams.accounts && searchParams.accounts === '0'}
      // error="error"
      onChange={onChangeStewardship}
    />
  );
};
