import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Contribution(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Contribution"
      // subtitle="Additional information provided by educator."
    >
      <TextField label="How many times have you interacted?">
        {evaluation.data.occurrences || '-'}
      </TextField>
      <TextField label="How many hours did you spend planning and interacting with the students and/or educator?">
        {evaluation.data.hours || '-'}
      </TextField>
      <TextField label="Did you contribute any supplies, materials, or items to the educator or students to complete the project?">
        {evaluation.data.contributed || '-'}
      </TextField>
      <TextField label="What supplies or materials did you contribute?">
        {evaluation.data.contribution || '-'}
      </TextField>
      <TextField label="What is the approximate monetary value of your contribution (in dollars)?">
        {evaluation.data.contributionValue || '-'}
      </TextField>
    </Section>
  );
}
