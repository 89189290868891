import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { PayloadStates } from '@lore/utils';
import useUserTags from '../../../hooks/useUserTags';
import TagTypes from '../../../constants/TagTypes';

export default function Experiences(props) {
  const { user } = props;

  const userExperiences = useUserTags(user, TagTypes.Experience);

  return (
    <Section
      title="Life Experiences"
    >
      {userExperiences.data.map(function(userExperience) {
        return (
          <TextField
            invert={true}
            key={userExperience.id}
            label={userExperience._tag.data.name || '...'}
          >
            {userExperience._tagNode.data.description}
          </TextField>
        );
      })}
      {userExperiences.data.length === 0 && '-'}
    </Section>
  );
}
