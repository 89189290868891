import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Outcome(props) {
  const { evaluation } = props;

  return (
    <Section
      title="Outcome"
      subtitle="Please rate any changes you observed with your learner(s) as a result of the interaction."
    >
      <TextField label="Learner(s) were more engaged.">
        {evaluation.data.engagedRating || '-'}
      </TextField>
      <TextField label="Learner(s) built social-emotional skills.">
        {evaluation.data.skillsRating || '-'}
      </TextField>
      <TextField label="Learner(s) increased awareness of future academic and career opportunities.">
        {evaluation.data.awarenessRating || '-'}
      </TextField>
      <TextField label="Learner(s) increased understanding of the real-world application of topic/content.">
        {evaluation.data.understandingRating || '-'}
      </TextField>
      <TextField label="Learner(s) practiced critical thinking, problem-solving, and/or working as a team.">
        {evaluation.data.practicedRating || '-'}
      </TextField>
    </Section>
  );
}
