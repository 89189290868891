import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import Metric from '../../dashboard-year/Metric';
import REQUIRED_FIELDS from '../../explore-events/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';

export default function ImpactEvents(props) {
  const { account, schoolStart, schoolEnd } = props;

  const allEvents = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          accountId: account.id,
          completed: true,
          students: {
            $gte: 0
          }
        }
      },
    },
    pagination: {
      pageSize: 1000
    }
  });

  const totalCount = _.reduce(allEvents.data, function(result, event) {
    return result + event.data.students;
  }, 0);

  if (
    allEvents.state === PayloadStates.FETCHING
  ) {
    return (
      <Metric
        name="Total students impacted through Events"
        stat={`...`}
        icon={UsersIcon}
      />
    );
  }

  return (
    <Metric
      name="Total students impacted through Events"
      stat={`${totalCount}`}
      icon={UsersIcon}
    />
  );
}
