import React from 'react';
import { parse, stringify } from 'query-string';
import useStewardshipFilter from '../explore-partners/useStewardshipFilter';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect, returnRawQuery = false } = props;

  const stewardshipFilter = useStewardshipFilter();

  const query = {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'sum',
            alias: 'numberOfStudentsServedProjects',
            relation: 'partnerProjectEvaluations.project.educatorEvaluation',
            field: 'students'
          }
        ],
        $where: {
          isEducator: false,
          numberOfStudentsServedProjects: {
            $gte: 50
          },
          $or: stewardshipFilter
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  };

  if (returnRawQuery) {
    return query;
  }

  return connect('user.find', query);
}
