import React from 'react';
import Metrics from '../Metrics';
import ActivityByMonth from './ActivityByMonth';
import GrowthByMonth from './GrowthByMonth';
import ProjectActivityYear from './ProjectActivityYear';
import ProjectActivityYearTreemap from './ProjectActivityYear.treemap';
import ProjectResponseYear from './ProjectResponseYear';
import ProjectStatusYear from './ProjectStatusYear';
import TotalYear from './TotalYear';
import StudentsYear from './StudentsYear';

export default function Projects(props) {
  const { params } = props;

  // return (
  //   <>
  //     <Metrics title="Projects">
  //       <ProjectStatusYear {...params} />
  //     </Metrics>
  //     {/*<Metrics>*/}
  //     {/*  <TotalYear {...params} />*/}
  //     {/*</Metrics>*/}
  //   </>
  // );

  return (
    <>
      <Metrics title="Projects">
        <ActivityByMonth {...params} />
        {/*<GrowthByMonth {...params} />*/}
        {/*<ProjectActivityYear {...params} />*/}
        <ProjectActivityYearTreemap {...params} />
        <ProjectResponseYear {...params} />
        <ProjectStatusYear {...params} />
      </Metrics>
      <Metrics>
        <TotalYear {...params} />
        <StudentsYear {...params} />
      </Metrics>
    </>
  );
}
