import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';
import useUserTags from '../../../../../hooks/useUserTags';
import TagTypes from '../../../../../constants/TagTypes';

export default function Expertise(props) {
  const { user } = props;

  const userExpertises = useUserTags(user, TagTypes.Expertise);

  if (userExpertises.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  return (
    <TextColumn>
      {userExpertises.data.map(function(userExpertise) {
        return userExpertise._tag.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
