import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { PayloadStates } from '@lore/utils';
import { onChange, onChangeArray } from '../../../utils/searchTags';
import Tags from '../../../components/Tags';
import useRouter from '../../../hooks/useRouter';
import getQueryUtils, { attributeKeys } from '../queryUtils';

export default function Contribution(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();
  const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);
  const searchParams = queryUtils.params;

  return (
    <Tags
      type="In-kind contribution amount"
      query={searchParams.contributions}
      models={{
        state: PayloadStates.RESOLVED,
        data: [
          [1, '0'],
          [2, '$1+'],
          [5, '$5+'],
          [10, '$10+'],
          [25, '$25+'],
          [50, '$50+'],
          [100, '$100+'],
          [250, '$250+'],
          [500, '$500+'],
          [100, '$1000+'],
        ].map(function(value) {
          return {
            id: value[0],
            data: {
              name: value[1]
            }
          }
        })
      }}
      onChange={onChangeArray('contributions', { location, history })}
    />
  );
};
