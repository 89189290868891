import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';

export default function Demographics(props) {
  const { user } = props;

  return (
    <Section
      title="Demographics"
      // subtitle="Contact info and demographics."
    >
      <TextField label="Gender">
        {user.data.gender || '-'}
      </TextField>
      <TextField label="Ethnicity">
        {user.data.ethnicity || '-'}
      </TextField>
      <TextField label="Birth Year">
        {user.data.yearOfBirth || '-'}
      </TextField>
      <TextField label="ZIP Code">
        {user.data.zipCode || '-'}
      </TextField>
      <TextField label="Timezone">
        {user.data.timezone || '-'}
      </TextField>
    </Section>
  );
}
