import React  from 'react';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';
import useUserTags from '../../../../../hooks/useUserTags';
import TagTypes from '../../../../../constants/TagTypes';

export default function Grade(props) {
  const { user } = props;

  const userGrades = useUserTags(user, TagTypes.Grade);

  if (userGrades.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  return (
    <TextColumn>
      {userGrades.data.map(function(userGrade) {
        return userGrade._tag.data.name;
      }).join(', ')}
    </TextColumn>
  );
}
