import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import InviteEducatorDialog from '../../../../dialogs/offer/InviteEducator';

export default function Invite(props) {
  const {
    offer
  } = props;

  const show = useDialog();

  function onClick() {
    show(
      <InviteEducatorDialog
        offer={offer}
      />, { template: 'dialog' }
    );
  }

  return (
    <PrimaryAction onClick={onClick}>
      Invite Educator
    </PrimaryAction>
  );
}
