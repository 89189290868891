import React from 'react';
import { PayloadStates } from '@lore/utils';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import useEventTags from '../../../hooks/useEventTags';

export default function Audience(props) {
  const { project } = props;

  const projectSubjects = useProjectTags(project, TagTypes.Subject);
  const projectGroups = useProjectTags(project, TagTypes.Group);
  const projectParticipants = useProjectTags(project, TagTypes.Participant);
  const projectAges = useProjectTags(project, TagTypes.Age);

  if (
    projectSubjects.state === PayloadStates.FETCHING ||
    projectGroups.state === PayloadStates.FETCHING ||
    projectParticipants.state === PayloadStates.FETCHING ||
    projectAges.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  const workingWithStudents = _.find(projectParticipants.data, model => model._tag.data.name === 'Students');

  return (
    <Section
      title="Project Audience"
      // subtitle="Learners the activity is designed for."
    >
      <TextField label="Educator/Students">
        {projectParticipants.data.map(function(projectParticipant) {
          return (
            <div key={projectParticipant.id || projectParticipant.cid}>
              {projectParticipant._tag.data.name}
            </div>
          );
        })}
        {projectParticipants.data.length === 0 && '-'}
      </TextField>
      {workingWithStudents && (
        <>
          <TextField label="Students Impacted">
            {project.data.students || '-'}
          </TextField>
          <TextField label="Student Ages">
            {projectAges.data.map(function(projectAge) {
              return (
                <div key={projectAge.id || projectAge.cid}>
                  {projectAge._tag.data.name}
                </div>
              );
            })}
            {projectAges.data.length === 0 && '-'}
          </TextField>
          <TextField label="Group Size">
            {project.data.groupSize || '-'}
          </TextField>
        </>
      )}
      <TextField label="Subject Areas">
        {projectSubjects.data.map(function(projectSubject, index) {
          return (
            <div key={index}>
              {projectSubject._tag.data.name}
            </div>
          );
        })}
        {projectSubjects.data.length === 0 && '-'}
      </TextField>
    </Section>
  )
}
