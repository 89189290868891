import React from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';

export default function useTags(type, _connect) {
  const connect = _connect || useConnect();

  const tags = connect('tag.find', {
    pagination: {
      pageSize: 1000,
      order: 'name asc'
    }
  });

  return {
    state: tags.state,
    data: tags.data.filter(function(tag) {
      if (type) {
        return tag.data.type === type;
      }

      return true;
    })
  };
}
