import React from 'react';
import Id from './Columns/Id';
import Event from './Columns/Event';
import Date from './Columns/Date';
import Status from './Columns/Status';
import Served from './Columns/Served';
import Statements from './Columns/Statements';
import Recommend from './Columns/Recommend';
import Contribution from './Columns/Contribution';
import Occurrences from './Columns/Occurrences';
import Hours from './Columns/Hours';
import Educator from './Columns/Educator';
import Partner from './Columns/Partner';
import PartnerPhone from './Columns/PartnerPhone';
import EducatorPhone from './Columns/EducatorPhone';

export default [
  { id: 'id',                  name: 'ID',                   row: Id,                 selected: false },
  { id: 'user',                name: 'Partner',              row: Partner,            selected: true },
  { id: 'partner_phone',       name: 'Partner phone',        row: PartnerPhone,       selected: false },
  // { id: 'educator',            name: 'Educator',             row: Educator,           selected: false },
  // { id: 'educator_phone',      name: 'Educator phone',       row: EducatorPhone,      selected: false },
  { id: 'event',               name: 'Event',                row: Event,              selected: true },
  { id: 'date',                name: 'Date',                 row: Date,               selected: true },
  { id: 'status',              name: 'Status',               row: Status,             selected: true },
  // { id: 'served',              name: 'Students served',        row: Served,             selected: false },
  { id: 'statements',          name: 'Statements',           row: Statements,         selected: false },
  // { id: 'contribution',        name: 'In-kind contribution', row: Contribution,       selected: true },
  // { id: 'occurrences',         name: 'Number occurrences',   row: Occurrences,        selected: false },
  // { id: 'hours',               name: 'Total hours',          row: Hours,              selected: false },
  { id: 'recommend',           name: 'Recommend',            row: Recommend,          selected: true }
];
