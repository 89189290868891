import React from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { PayloadStates } from '@lore/utils';
import { onChange, onChangeArray } from '../../../utils/searchTags';
import Tags from '../../../components/Tags';
import useRouter from '../../../hooks/useRouter';
import getQueryUtils, { attributeKeys } from '../queryUtils';

export default function Genders(props) {
  const { timestamp } = props;

  const { location, history } = useRouter();
  const queryUtils = getQueryUtils(parse(location.search), attributeKeys, timestamp);
  const searchParams = queryUtils.params;

  return (
    <Tags
      type="Gender"
      query={searchParams.genders}
      models={{
        state: PayloadStates.RESOLVED,
        data: [
          'Male',
          'Female',
          'Agender',
          'Gender Fluid',
          'Gender Non-conforming',
          'Genderqueer',
          'Non-binary',
          'Questioning',
          'Self Identify Another Option',
          'Prefer Not to Say'
        ].map(function(value, index) {
          return {
            id: index + 1,
            data: {
              name: value
            }
          }
        })
      }}
      onChange={onChangeArray('genders', { location, history })}
    />
  );
};
