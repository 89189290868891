import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import Metric from '../../dashboard-year/Metric';
import { useConnect } from '@lore/connect';

export default function Total(props) {
  const { account, schoolStart, schoolEnd } = props;

  const users = useConnect('user.find', {
    where: {
      eager: {
        $where: {
          accountId: account.id,
          isEducator: false,
          // createdAt: {
          //   $lte: schoolEnd,
          //   $gte: schoolStart
          // }
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <Metric
      name="Total"
      stat={users.meta ? users.meta.totalCount : '...'}
      icon={UsersIcon}
    />
  );
}
