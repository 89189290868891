import React from 'react';
import PropTypes from 'prop-types';
import TagTypes from '../../../constants/TagTypes';
import TagSet from './_common/TagSet';
import Joined from './Joined';
import Location from './Location';
import DataSet from './_common/DataSet';
import TextSearch from './_common/TextSearch';
import Genders from './Genders';
import Ethnicities from './Ethnicities';
import Contribution from './Contribution';
import Hours from './Hours';

export default function SearchTags(props) {
  const { timestamp } = props;

  return (
    <div className="space-y-3">
      <TagSet type={TagTypes.Age} param="ages" timestamp={timestamp}/>
      <TagSet type={TagTypes.Activity} param="activities" timestamp={timestamp}/>
      <TagSet type={TagTypes.ExpertiseType} param="expertiseTypes" timestamp={timestamp}/>
      <TagSet type={TagTypes.Experience} param="experiences" timestamp={timestamp}/>
      <TagSet type={TagTypes.Group} param="groups" timestamp={timestamp} label="Youth" />
      <TagSet type={TagTypes.Guidance} param="guidances" timestamp={timestamp} label="Professional competency" />
      <Joined timestamp={timestamp} />
      <Genders timestamp={timestamp} />
      <Ethnicities timestamp={timestamp} />

      <Contribution timestamp={timestamp} />
      <Hours timestamp={timestamp} />

      <TextSearch param="name" type="Name" timestamp={timestamp} />
      <TextSearch param="organization" type="Organization" timestamp={timestamp} />
      <TextSearch param="searchPartner" type="Search" timestamp={timestamp} />

      {/*<DataSet*/}
      {/*  param="genders"*/}
      {/*  label="Gender"*/}
      {/*  data={[*/}
      {/*    'Male',*/}
      {/*    'Female',*/}
      {/*    'Agender',*/}
      {/*    'Gender Fluid',*/}
      {/*    'Gender Non-conforming',*/}
      {/*    'Genderqueer',*/}
      {/*    'Non-binary',*/}
      {/*    'Questioning',*/}
      {/*    'Self Identify Another Option',*/}
      {/*    'Prefer Not to Say'*/}
      {/*  ].map(function(value, index) {*/}
      {/*    return {*/}
      {/*      id: index + 1,*/}
      {/*      data: {*/}
      {/*        name: value,*/}
      {/*        type: 'Gender'*/}
      {/*      }*/}
      {/*    };*/}
      {/*  })}*/}
      {/*  timestamp={timestamp}*/}
      {/*/>*/}
      {/*<DataSet*/}
      {/*  param="ethnicities"*/}
      {/*  label="Ethnicity"*/}
      {/*  data={[*/}
      {/*    'American Indian or Alaska Native',*/}
      {/*    'Asian',*/}
      {/*    'Black or African American',*/}
      {/*    'Hispanic or Latino',*/}
      {/*    'Native Hawaiian or Other Pacific Islander',*/}
      {/*    'White',*/}
      {/*    'Multiracial',*/}
      {/*    'Other',*/}
      {/*    'Prefer Not to Say'*/}
      {/*  ].map(function(value, index) {*/}
      {/*    return {*/}
      {/*      id: index + 1,*/}
      {/*      data: {*/}
      {/*        name: value,*/}
      {/*        type: 'Ethnicity'*/}
      {/*      }*/}
      {/*    };*/}
      {/*  })}*/}
      {/*  timestamp={timestamp}*/}
      {/*/>*/}

      {/*<Location timestamp={timestamp} />*/}
      {/*<DataSet*/}
      {/*  param="contributions"*/}
      {/*  label="In-kind contribution amount"*/}
      {/*  data={[*/}
      {/*    {*/}
      {/*      id: 1,*/}
      {/*      data: {*/}
      {/*        name: 'Yes',*/}
      {/*        type: 'Cost'*/}
      {/*      }*/}
      {/*    },*/}
      {/*    {*/}
      {/*      id: 2,*/}
      {/*      data: {*/}
      {/*        name: 'Free',*/}
      {/*        type: 'Cost'*/}
      {/*      }*/}
      {/*    }*/}
      {/*  ]}*/}
      {/*/>*/}
    </div>
  );
};
