import React, { useState } from 'react';
import moment from 'moment-timezone';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import { useDialog } from '@lore/dialogs';
import Loader from '../../../components/Loader';
import SidePanel from '../../explore-invitations/SidePanel';
import UserAvatar from '../../../components/UserAvatar';

export default function Invitations(props) {
  const { event } = props;

  const connect = useConnect();
  const [timestamp] = useState(moment().toISOString());

  const show = useDialog();

  function onClick(invitation) {
    show(
      <SidePanel
        invitation={invitation}
      />
    );
  }

  const invitations = connect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  if (invitations.state === PayloadStates.FETCHING) {
    return (
      <div className="px-6">
        <Loader />
      </div>
    );
  }

  const groups = _.groupBy(invitations.data, invitation => invitation.data.response);
  const reduced = _.reduce(groups, function(result, value, key) {
    result.push({
      label: key,
      invitations: value
    });
    return result;
  }, []);
  const sortedGroups = _.sortBy(reduced, function(value) {
    return [
      'Yes',
      'Maybe',
      'Pending',
      'No'
    ].indexOf(value.label);
  });

  if (sortedGroups.length === 0) {
    return (
      <div className="px-6">
        There are no invitations.
      </div>
    );
  }

  return (
    <div className="px-6 pb-6 space-y-6">
      {sortedGroups.map(function(group, index) {
        return (
          <div key={index}>
            <div className="mb-2">
              {group.label}
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
              {group.invitations.map(function(invitation) {
                const partner = connect('user.byId', {
                  id: invitation.data.userId
                });

                const colorMap = {
                  'Pending': 'bg-yellow-400',
                  'Maybe': 'bg-blue-400',
                  'Yes': 'bg-green-400'
                };

                const responseColor = colorMap[invitation.data.response] || 'bg-red-400';

                return (
                  <div
                    key={invitation.id}
                    className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    onClick={() => onClick(invitation)}
                  >
                    <div className="flex-shrink-0">
                      <UserAvatar user={partner} />
                    </div>
                    <div className="flex-1 min-w-0">
                      <a href="#" className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900">
                          {`${partner.data.firstName} ${partner.data.lastName}`.trim() || '[Missing Name]'}
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                          {invitation.data.response}
                          <span className={`ml-2.5 ${responseColor} flex-shrink-0 inline-block h-2 w-2 rounded-full`}>
                            <span className="sr-only">{invitation.data.response}</span>
                          </span>
                        </p>
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  );
}
