import React from 'react';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import moment from 'moment-timezone';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import useTags from '../../../hooks/useTags';

export default function Logistics(props) {
  const { project } = props;

  const currentUser = useUser();

  const projectDays = useProjectTags(project, TagTypes.Day);
  const projectTimes = useProjectTags(project, TagTypes.Time);
  const projectCommitments = useProjectTags(project, TagTypes.Commitment);

  const interactionTypes = useTags(TagTypes.InteractionType);
  const projectInteractionTypes = useProjectTags(project, TagTypes.InteractionType);

  const locations = useTags(TagTypes.Location);
  const projectLocations = useProjectTags(project, TagTypes.Location);

  if (
    projectDays.state === PayloadStates.FETCHING ||
    projectTimes.state === PayloadStates.FETCHING ||
    projectCommitments.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  if (
    interactionTypes.state === PayloadStates.FETCHING ||
    projectInteractionTypes.state === PayloadStates.FETCHING ||
    locations.state === PayloadStates.FETCHING ||
    projectLocations.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  const isInPerson = _.find(projectInteractionTypes.data, model => model._tag.data.name === 'In-person');

  return (
    <Section
      title="Project Logistics"
      // subtitle="Date, time, and frequency."
    >
      <TextField label="Date Window">
        {moment(project.data.startDate).format('MMM Do, YYYY')} - {moment(project.data.endDate).format('MMM Do, YYYY')}
      </TextField>
      <TextField label="Possible Days">
        {projectDays.data.map(function(projectDay) {
          return (
            <div key={projectDay.id || projectDay.cid}>
              {projectDay._tag.data.name}
            </div>
          )
        })}
        {projectDays.data.length === 0 && '-'}
      </TextField>
      <TextField label="Possible Times of Day">
        {projectTimes.data.map(function(projectTime) {
          return (
            <div key={projectTime.id || projectTime.cid}>
              {projectTime._tag.data.name}
            </div>
          );
        })}
        {projectTimes.data.length === 0 && '-'}
      </TextField>
      <TextField label="Frequency">
        {projectCommitments.data.map(function(projectCommitment) {
          return (
            <div key={projectCommitment.id || projectCommitment.cid}>
              {projectCommitment._tag.data.name}
            </div>
          )
        })}
        {projectCommitments.data.length === 0 && '-'}
      </TextField>
      <TextField label="Location Type">
        {projectInteractionTypes.data.map(function(projectInteractionType) {
          return (
            <div key={projectInteractionType.id || projectInteractionType.cid}>
              {projectInteractionType._tag.data.name}
            </div>
          );
        })}
        {projectInteractionTypes.data.length === 0 && '-'}
      </TextField>
      {/*{isInPerson && (*/}
      {/*  <TextField label="Possible Locations">*/}
      {/*    {projectLocations.data.map(function(projectLocation) {*/}
      {/*      return (*/}
      {/*        <div key={projectLocation.id || projectLocation.cid}>*/}
      {/*          {projectLocation._tag.data.name}*/}
      {/*        </div>*/}
      {/*      )*/}
      {/*    })}*/}
      {/*  </TextField>*/}
      {/*)}*/}
    </Section>
  )
}
