import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import moment from 'moment-timezone';

export default function useQuery(props) {
  const { location, PAGE_SIZE = 10, connect } = props;

  const [timestamp] = useState(moment().toISOString());

  return connect('evaluation.find', {
    where: {
      eager: {
        $where: {
          type: 'EducatorEval',
          completed: false,
          createdAt: {
            $gte: moment(timestamp).subtract(2, 'months').toISOString()
          },
          'interaction.startDate': {
            $lte: timestamp
          },
          'interaction.accepted': true
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: PAGE_SIZE,
      order: 'createdAt desc'
    }
  });
}
