import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import Metric from '../../dashboard-year/Metric';
import REQUIRED_FIELDS from '../../explore-offers/REQUIRED_FIELDS';

export default function Total(props) {
  const { account, schoolStart, schoolEnd } = props;

  const allOffers = useConnect('offer.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          accountId: account.id,
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const totalString = allOffers.meta ? allOffers.meta.totalCount : '...';

  return (
    <Metric
      name="Total"
      stat={totalString}
      icon={UsersIcon}
    />
  );
}
