import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import TextColumn from '../TextColumn';
import useUserTags from '../../../../../hooks/useUserTags';
import TagTypes from '../../../../../constants/TagTypes';

export default function Subject(props) {
  const { user } = props;

  const connect = useConnect();

  const userSubjects = useUserTags(user, TagTypes.Subject);

  if (userSubjects.state === PayloadStates.FETCHING) {
    return (
      <td className="px-6 py-4 whitespace-nowrapx">
        <div className="text-sm text-gray-900">
          ...
        </div>
        <div className="text-sm text-gray-500">
          ...
        </div>
      </td>
    );
  }

  return (
    <TextColumn>
      {userSubjects.data.map(function(userSubject) {
        return userSubject._tag.data.name;
      }).join(', ')}
    </TextColumn>
  );

  // const userSubject = userSubjects.data[0];
  //
  // if (!userSubject) {
  //   return (
  //     <td className="px-6 py-4 whitespace-nowrapx">
  //       <div className="text-sm text-gray-900">
  //       </div>
  //       <div className="text-sm text-gray-500">
  //       </div>
  //     </td>
  //   );
  // }

  // const subject = userSubject._tag;
  //
  // return (
  //   <td className="px-6 py-4 whitespace-nowrapx">
  //     <div className="text-sm text-gray-900 line-clamp-1">
  //       {subject.data.name} {userSubjects.data.length > 1 && (
  //         <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
  //           + {userSubjects.data.length - 1}
  //         </span>
  //       )}
  //     </div>
  //     <div className="text-sm text-gray-500 line-clamp-1">
  //       {userSubject.data.role}
  //     </div>
  //   </td>
  // );
}
