import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import PayloadStates from '../../../constants/PayloadStates';
import classNames from '../../../utils/classNames';
import { insertOrRemoveNumberFromArray, changeFilter } from '../../../utils/query';
import useRouter from '../../../hooks/useRouter';

TagsFilter.propTypes = {
  display: PropTypes.func
};

export default function TagsFilter(props) {
  const {
    label,
    dropdownLabel,
    dropdownLabel1,
    dropdownLabel2,
    filterName,
    queryParamName,
    queryUtils,
    tags,
    related,
    display,
    timestamp,
    backgroundColor = 'bg-white'
  } = props;

  const { location, history } = useRouter();

  const { params: searchParams } = queryUtils(parse(location.search), [queryParamName], timestamp);
  const numberSelected = searchParams[queryParamName].length;

  function onChange(tag) {
    const newQuery = insertOrRemoveNumberFromArray(parse(location.search), queryParamName, tag.id);

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, newQuery, {
        page: 1
      }))
    });
  }

  function onChangeFilter(filter) {
    const newQuery = changeFilter(parse(location.search), filterName, filter);

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, newQuery, {
        page: 1
      }))
    });
  }

  if (tags.state === PayloadStates.FETCHING){
    return (
      <Listbox value={null} onChange={onChange}>
        {({ open }) => (
          <>
            {/*<Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label>*/}
            <div className="mt-1-x relative">
              <Listbox.Button className={`${backgroundColor} relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}>
              <span className="block truncate">
                {numberSelected > 0 ?`${label} (${numberSelected})` : label} ...
              </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
              </Listbox.Button>
            </div>
          </>
        )}
      </Listbox>
    );
  }

  return (
    <Listbox value={null} onChange={onChange}>
      {({ open }) => (
        <>
          {/*<Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label>*/}
          <div className="mt-1-x relative">
            <Listbox.Button className={`${backgroundColor} relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}>
              <span className="block truncate">
                {numberSelected > 0 ?`${label} (${numberSelected})` : label}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            {open && (
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute z-10 mt-1 w-full w-max-x bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {tags.data.map((tag) => {
                    let count = '';
                    const _related = related ? related(tag) : undefined;
                    if (_related) {
                      count = ` (${_related.state === PayloadStates.FETCHING ? '...' : _related.meta.totalCount})`
                    }

                    return (
                      <Listbox.Option
                        key={tag.id}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9'
                          )
                        }
                        value={tag}
                      >
                        {({ active }) => {
                          const selected = searchParams[queryParamName].indexOf(tag.id) >= 0;

                          return (
                            <>
                              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate-x')}>
                                {display ? display(tag) : tag.data.name}{count}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                </span>
                              ) : null}
                            </>
                          );
                        }}
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            )}
          </div>
        </>
      )}
    </Listbox>
  );
};
