import React  from 'react';
import { useConnect } from '@lore/connect';
import { PayloadStates } from '@lore/utils';
import User, { UserLoader } from './User';

export default function Educator(props) {
  const { evaluation } = props;

  const connect = useConnect();

  const project = connect('project.byId', {
    id: evaluation.data.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <UserLoader />
    );
  }

  const user = connect('user.byId', {
    id: project.data.creatorId
  });

  if (user.state === PayloadStates.FETCHING) {
    return (
      <UserLoader />
    );
  }

  return (
    <User user={user} />
  );
}
