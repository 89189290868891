import React from 'react';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import { useConnect } from '@lore/connect';
import { useDialog } from '@lore/dialogs';
import SidePanel from '../../explore-partners/SidePanel';

export default function Partner(props) {
  const { offer } = props;

  const connect = useConnect();

  const user = connect('user.byId', {
    id: offer.data.creatorId
  });

  const roles = connect('role.find', {
    where: {
      eager: {
        $where: {
          userId: offer.data.creatorId
        }
      }
    }
  });

  const show = useDialog();

  function onClick() {
    show(
      <SidePanel user={user} />
    );
  }

  return (
    <Section
      title="Partner Contact Info"
      // subtitle="Name and contact info."
    >
      <TextField label="Name">
        <span onClick={onClick} className="text-orange-500 underline cursor-pointer hover:text-orange-300">
          {user.data.firstName} {user.data.lastName}
        </span>
      </TextField>
      <TextField label="Email">
        {user.data.email || '-'}
      </TextField>
      <TextField label="Phone">
        {user.data.phoneNumber || '-'}
      </TextField>
      <TextField label="Org Affiliation">
        {roles.data.map(function(role, index) {
          const organization = connect('organization.byId', {
            id: role.data.organizationId
          });

          return (
            <div key={index}>
              {organization.data.name || '-'}
            </div>
          );
        })}
        {roles.data.length === 0 && '-'}
      </TextField>
      <TextField label="Title">
        {roles.data.map(function(role, index) {
          return (
            <div key={index}>
              {role.data.title || '-'}
            </div>
          );
        })}
        {roles.data.length === 0 && '-'}
      </TextField>
    </Section>
  );
}
