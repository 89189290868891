import React from 'react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import Metric from '../../dashboard-year/Metric';
import { useConnect } from '@lore/connect';

export default function Total(props) {
  const { account, schoolStart, schoolEnd } = props;

  const schools = useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEducators',
            relation: 'users',
            $where: {
              isEducator: true,
              'accounts.id': account.id
            }
          }
        ],
        $where: {
          numberOfEducators: {
            $gt: 0
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const totalCount = schools.meta ? schools.meta.totalCount : '...';

  return (
    <Metric
      name="Total"
      stat={totalCount}
      icon={OfficeBuildingIcon}
    />
  );
}
