import React  from 'react';
import moment from 'moment-timezone';

export default function DateWindow(props) {
  const { offer } = props;

  function getDate(timestamp) {
    // return moment(timestamp).format('MMM D (ddd)');
    return moment(timestamp).format('MMM D, YYYY');
  }

  if (
    offer.data.specificDate === 'Yes' &&
    offer.data.date
  ) {
    return (
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {getDate(offer.data.date)}
      </td>
    );
  }

  if (
    offer.data.specificDate === 'No' &&
    offer.data.startDate &&
    offer.data.endDate
  ) {
    return (
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {getDate(offer.data.startDate)} - {getDate(offer.data.endDate)}
      </td>
    );
  }

  return (
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">

    </td>
  );
}
