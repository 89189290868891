import React from 'react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import Metric from '../../dashboard-year/Metric';
import { useConnect } from '@lore/connect';
import REQUIRED_FIELDS from '../../explore-events/REQUIRED_FIELDS';

export default function AssociatedWithEvent(props) {
  const { account, schoolStart, schoolEnd } = props;

  const allSchools = useConnect('organization.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfEvents',
            relation: 'users.events',
            $where: {
              $and: REQUIRED_FIELDS.$and,
              'creator.isEducator': true,
              accountId: account.id
            }
          }
        ],
        $where: {
          numberOfEvents: {
            $gte: 1
          }
        }
      }
    },
    pagination: {
      pageSize: 1
    }
  });

  const totalCount = allSchools.meta ? allSchools.meta.totalCount : '...';

  return (
    <Metric
      name="Associated with Event"
      stat={totalCount}
      icon={OfficeBuildingIcon}
    />
  );
}
