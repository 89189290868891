import React, { useState } from 'react';
import _ from 'lodash';
import avatar from '../../../../assets/images/avatar.svg';
import Header from '../../_components/sidePanel/Header';
import Panel from '../../_components/sidePanel/Panel';
import Contact from '../../explore-educators/SidePanel/Contact';
import Demographics from '../../explore-educators/SidePanel/Demographics';
import About from '../../explore-educators/SidePanel/About';
import Organizations from '../../explore-educators/SidePanel/Organizations';
import Social from '../../explore-educators/SidePanel/Social';
import Skills from './Skills';
import Interests from './Interests';
import Experiences from '../../explore-educators/SidePanel/Experiences';
import Guidances from './Guidance';
import Questions from './Questions';
import Stories from '../../explore-educators/SidePanel/Stories';
import Projects from '../../explore-educators/SidePanel/Projects';
import Events from '../../explore-educators/SidePanel/Events';
import Invitations from './Invitations';
import Offers from './Offers';
import Claims from '../../explore-educators/SidePanel/Claims';
import Tabs from '../../_components/Tabs';
import Actions from './Actions';
import Notes from './Notes';
import TextField from '../../_components/sidePanel/TextField';
import Section from '../../_components/sidePanel/Section';
import Bookmark from '../../_components/Bookmark';

export default function SidePanel(props) {
  const { user } = props;

  const [tabs, setTabs] = useState([
    { id: 'profile', name: 'Profile', selected: true },
    { id: 'participation', name: 'Participation', selected: false },
    { id: 'notes', name: 'Notes', selected: false }
  ]);

  const selectedTab = _.find(tabs, tab => tab.selected);

  return (
    <Panel title="Partner" onClose={props.onClose}>
      <div>
        <Header
          bookmark={(
            <Bookmark
              type="user"
              resource={user}
            />
          )}
          src={user.data.avatarUrl || avatar}
          title={`${user.data.firstName} ${user.data.lastName}`}
          subtitle={user.data.email}
        >
          <Actions
            user={user}
          />
        </Header>
        <Tabs
          tabs={tabs}
          setTabs={setTabs}
        />
        {selectedTab.id === 'profile' && (
          <div className="divide-y divide-gray-200">
            <Contact user={user} />
            <Organizations user={user} />
            <About user={user} />
            <Skills user={user} />
            <Experiences user={user} />
            <Guidances user={user} />
            <Interests user={user} />
            <Social user={user} />
            <Demographics user={user} />
          </div>
        )}
        {selectedTab.id === 'participation' && (
          <div className="divide-y divide-gray-200">
            <Section>
              <TextField label="">
                Here are projects, events, and impact stories associated with this partner.
              </TextField>
            </Section>
            {/*<Projects user={user} />*/}
            <Questions user={user} />
            {/*<Events user={user} />*/}
            <Invitations user={user} />
            <Offers user={user} />
            {/*<Claims user={user} />*/}
            <Stories user={user} />
          </div>
        )}
        {selectedTab.id === 'notes' && (
          <div className="mb-6x">
            <Notes user={user} />
          </div>
        )}
      </div>
    </Panel>
  )
}
