import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import { useConnect } from '@lore/connect';
import Metric from '../../dashboard-year/Metric';
import REQUIRED_FIELDS from '../../explore-projects/REQUIRED_FIELDS';
import { PayloadStates } from '@lore/utils';

export default function Students(props) {
  const { account, schoolStart, schoolEnd } = props;

  const allProjects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          $and: REQUIRED_FIELDS.$and,
          accountId: account.id,
          completed: true,
          students: {
            $gte: 0
          }
        }
      },
    },
    pagination: {
      pageSize: 1000
    }
  });

  const totalCount = _.reduce(allProjects.data, function(result, project) {
    return result + project.data.students;
  }, 0);

  if (allProjects.state === PayloadStates.FETCHING) {
    return (
      <Metric
        name="Total Students Impacted"
        stat={`...`}
        icon={UsersIcon}
      />
    );
  }

  return (
    <Metric
      name="Total Students Impacted"
      stat={totalCount}
      icon={UsersIcon}
    />
  );
}
