import React from 'react';
import { useDialog } from '@lore/dialogs';
import PrimaryAction from '../../../_components/actions/PrimaryAction';
import SecondaryAction from '../../../_components/actions/SecondaryAction';
import InviteOfferDialog from '../../../../dialogs/user/invite.offer';

export default function InviteOffer(props) {
  const { user } = props;

  const show = useDialog();

  function onClick() {
    show(
      <InviteOfferDialog
        user={user}
      />, { template: 'dialog' }
    );
  }

  return (
    <PrimaryAction onClick={onClick}>
      Invite to Offer
    </PrimaryAction>
  );
}
