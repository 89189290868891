import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../forms/_common/FormField';
import OutlineButton from '../../../components/buttons/OutlineButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import DateField from '../../../forms/_fields/DateField';
import TimeField from '../../../forms/_fields/TimeField';
import FormTemplate from '../../_common/FormTemplate';
import TextAreaField from '../../../forms/_fields/TextAreaField';
import StringField from '../../../forms/_fields/StringField';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    interaction
  } = props;

  if (data.accepted) {
    return (
      <FormTemplate
        title="Change Interaction Status"
        subtitle="Would you like to change the confirmation status?"
        onClose={onCancel}
        alert={alert}
        body={(
          <div className="my-8">
            <div className="text-sm text-gray-700">
              The status of this interaction is already set to <span className="font-bold">attending</span>. If
              you wish to change it to <span className="font-bold">pending</span>, or delete this interaction,
              please contact CommunityShare support.
            </div>
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onCancel}>
              Close
            </OutlineButton>
          </>
        )}
      />
    );
  }

  return (
    <FormTemplate
      title="Change Interaction Status"
      subtitle="Would you like to change the confirmation status?"
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="my-8">
          <div className="text-sm text-gray-700">
            <p>
              If you would like, you may change the confirmation status of this interaction
              to <span className="font-bold">Attending</span>. Doing so will imply that the partner will be attending, or already has (if the event took place in the past).
            </p>
          </div>
        </div>
      )}
      footer={(
        <>
          <PrimaryButton onClick={onSubmit}>
            Change status to Attending
          </PrimaryButton>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
        </>
      )}
    />
  );
}
