import React from 'react';
import Sidebar from '../_components/Sidebar';
import SearchHeader from '../_components/SearchHeader';
import navigationLinks from './navigationLinks';
import { useConnect } from '@lore/connect';
import useRouter from '../../hooks/useRouter';
import useAccount from '../../hooks/useAccount';
import { useUser } from '@lore/auth';

export default function Layout(props) {
  const { sidebarOpen, setSidebarOpen } = props;

  // const accounts = useConnect('account.find', {
  //   pagination: {
  //     order: 'name asc'
  //   }
  // });

  const account = useAccount();
  const currentUser = useUser();

  // const groupedNavigationLinks = [
  //   {
  //     title: '',
  //     links: navigationLinks(account)
  //   },
  //   {
  //     title: 'Communities',
  //     links: accounts.data.map(function(account) {
  //       return {
  //         name: account.data.subdomain,
  //         href: `/dashboard/overview/${account.id}/${account.data.subdomain}`
  //       }
  //     })
  //   }
  // ];

  return (
    <>
      {/*{true ? (*/}
      {/*  <Sidebar*/}
      {/*    title="Dashboard"*/}
      {/*    navigation={navigationLinks(account)}*/}
      {/*    sidebarOpen={sidebarOpen}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <Sidebar*/}
      {/*    title="Dashboard"*/}
      {/*    navigation={groupedNavigationLinks}*/}
      {/*    grouped={true}*/}
      {/*    sidebarOpen={sidebarOpen}*/}
      {/*  />*/}
      {/*)}*/}
      <Sidebar
        title="Dashboard"
        navigation={navigationLinks(account, currentUser)}
        sidebarOpen={sidebarOpen}
      />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <SearchHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        {props.children || (
          <h3 className="font-bold text-xl text-gray-900 sm:text-2xl px-5 py-4">
            Placeholder: Dashboard
          </h3>
        )}
      </div>
    </>
  )
}
