import React from 'react';
import { UsersIcon } from '@heroicons/react/outline';
import Metric from '../../dashboard-year/Metric';
import { useConnect } from '@lore/connect';
import useStewardshipFilter from '../../explore-partners/useStewardshipFilter';

export default function QuestionPartners(props) {
  const { account, schoolStart, schoolEnd } = props;

  const stewardshipFilter = useStewardshipFilter();

  const usersReceived = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfQuestions',
            relation: 'questions',
            $where: {
              projectId: {
                $not: null
              },
              'project.accountId': account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          },
          {
            type: 'count',
            alias: 'numberOfInvitations',
            relation: 'invitations',
            $where: {
              eventId: {
                $not: null
              },
              'event.accountId': account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        ],
        $where: {
          $and: {
            $or: [
              {
                numberOfQuestions: {
                  $gte: 1
                }
              },
              {
                numberOfInvitations: {
                  $gte: 1
                }
              }
            ]
          },
          isEducator: false,
          $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  const usersResponded = useConnect('user.find', {
    where: {
      eager: {
        $aggregations: [
          {
            type: 'count',
            alias: 'numberOfQuestions',
            relation: 'questions',
            $where: {
              projectId: {
                $not: null
              },
              response: {
                $not: 'Pending'
              },
              'project.accountId': account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          },
          {
            type: 'count',
            alias: 'numberOfInvitations',
            relation: 'invitations',
            $where: {
              eventId: {
                $not: null
              },
              response: {
                $not: 'Pending'
              },
              'event.accountId': account.id,
              createdAt: {
                $lte: schoolEnd,
                $gte: schoolStart
              }
            }
          }
        ],
        $where: {
          $and: {
            $or: [
              {
                numberOfQuestions: {
                  $gte: 1
                }
              },
              {
                numberOfInvitations: {
                  $gte: 1
                }
              }
            ]
          },
          isEducator: false,
          $or: stewardshipFilter
        }
      },
    },
    pagination: {
      pageSize: 1
    }
  });

  return (
    <Metric
      name="Received an invitation (responded/received)"
      stat={`${usersResponded.meta ? usersResponded.meta.totalCount : '...'}/${usersReceived.meta ? usersReceived.meta.totalCount : '...'}`}
      icon={UsersIcon}
    />
  );
}
